import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AssetApiService } from '../services/asset-api.service';

@Injectable({ providedIn: 'root' })
export class BreadCrumbResolver implements Resolve<any> {
  constructor(private api: AssetApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    const id = route.paramMap.get('id');
    const action = route.paramMap.get('action') || '';
    console.log(action);
    if (action === '' || action === undefined || action === null) {
      return null;

    }
    return this.api.getBreadCrumb({ id, action });
  }
}
