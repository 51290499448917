import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  ColumnReorderEvent,
  ColumnResizeArgs,
  ColumnVisibilityChangeEvent,
  GridComponent,
  GridDataResult,
  PagerSettings,
  RowArgs,
  SelectableSettings,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, debounceTime, Subject, Subscription } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ActionService } from 'src/app/services/action-service';
import { DynamicFormService } from '../../modals/forms/forms.service';
import { StatePersistingService } from 'src/app/services/grid-state.service';
import { TableGridSettings } from 'src/app/config/action-table';
import { GridSettings } from 'src/app/shared/table';
import {
  PaginationState,
  QueryParamService,
} from 'src/app/services/param.service';
import { StructuredForm } from 'src/app/models/dynamicForm';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private actionService: ActionService,
    public dialogService: DialogService,
    public persistingService: StatePersistingService,
    private modal: DynamicFormService,
    public qpService: QueryParamService
  ) {

    this.id = this.ar.snapshot.params.id;
    this.action = this.ar.snapshot.params.action;
    this.paginationState = this.qpService.InitPagination();

    this.selectableSettings = {
      checkboxOnly: this.multiselectButtons?.length <= 0 || true,
      mode: 'multiple',
      drag: false,
    };

    const gridSettings: GridSettings =
      this.persistingService.get('grid') || TableGridSettings;

    this.gridSettings = this.mapGridSettings(gridSettings);

    this.stateSubject$.subscribe((x) => {
      this.loading = true;
      const ff: Map<string, string> = new Map<string, string>();
      this.gridSettings.state?.filter?.filters.forEach((f: any) => {
        ff.set(f.field, `${f.operator}#${f.value}`);
      });

      if (this.searchField !== '') {
        ff.set('find', this.searchField);
        ff.set('time', new Date().toISOString());
        // also set currentPage page to 1.
        this.paginationState.currentPage = '1';
      }

      ff.set('sortArray', JSON.stringify(this.gridSettings.state?.sort));

      this.qpService.SetPagination(this.paginationState, ff);
      this.loading = false;
    });

    this.routerSub$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.id = this.ar.snapshot.params.id || '';
        this.action = this.ar.snapshot.params.action || '';
        // this.qpService.InitPagination()
        this.state$.next(true);
      }
    });

    this.actionService.refreshViewAction$.subscribe((val) => {
      // console.log("refresh the table")
      this.next();
    });
    this.sub$ = this.ar.parent.data.subscribe((d) => {
      // if (!this.ar.snapshot.paramMap.get('action').endsWith('-table')) {
      //   return;
      // }
      // console.log('SUBSCRIBED ', d);
      this.gridData = {
        data: d.data.action?.table || [],
        total: d.data.action['table-count'] || 0,
      };

      this.gridSettings.state.skip = this.qpService.ConvertPaginationState(
        this.paginationState
      ).skip;
      this.gridSettings.state.take = this.qpService.ConvertPaginationState(
        this.paginationState
      ).take;

      this.data = d.data;

      this.rowButtons = d.data.action.form.rowButtons || [];
      this.headerButtons = d.data.action.form.headerButtons || [];
      this.multiselectButtons = d.data.action.form.multiselectButtons || [];

      this.searchField = this.qpService.GetParam('find') || '';

      // check if pagination exceeds max pages.
      if (
        Number(this.paginationState.currentPage) >
        Math.ceil(this.gridData.total / this.gridSettings.state.take)
      ) {
        this.paginationState.currentPage = '1';
        // this.qpService.SetPagination(this.paginationState);
      }
      // console.log('state pagination', this.gridSettings);

      if (
        this.gridSettings.columnsConfig.length <= 0 ||
        this.gridSettings.columnsConfig.length !==
          d.data.action.form.columns?.length
      ) {
        // console.log('state set');

        this.gridSettings.columnsConfig = [];
        d.data.action.form.columns?.forEach((col) => {
          this.gridSettings.columnsConfig.push({
            field: col.attribute,
            title: col.caption,
            filterable: false,
            _width: 150,
            hidden: false,
            filter: 'text',
          });
        });
      } else {
        // console.log('state do nothing');
      }
    });

    this.searchChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.next();
    });
  }

  public get getPageSize(): number {
    return this.qpService.ConvertPaginationState(this.paginationState).take;
  }
  public get getSkip(): number {
    return this.qpService.ConvertPaginationState(this.paginationState).skip;
  }

  public get savedStateExists(): boolean {
    return !!this.persistingService.get('grid');
  }
  // @ViewChild('grid') tableView: GridComponent;
  public loading = false;
  public stateSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public state$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public config: PagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [20, 50, 100, 500],
    previousNext: true,
    position: 'bottom',
  };

  // detail view
  public detailData: any;
  public multiSelectFormRef: DialogRef;

  // state
  public gridSettings: GridSettings = {
    columnsConfig: [],
    state: {},
  };
  public filterUnsused: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  // gridData
  public gridData: GridDataResult = {
    data: [],
    total: 0,
  };
  public id = '';
  public action = '';
  public searchField = '';
  public data: any;

  public searchChanged = new Subject<string>();

  public paginationState: PaginationState;

  // router
  public routerSub$: Subscription;
  public sub$: Subscription;

  // buttons
  public rowButtons: any;
  public headerButtons: any;
  public multiselectButtons: StructuredForm[];

  public dataSubscription: Subscription;

  // selected
  public selectedIds: Array<string> = new Array<string>();
  public selectableSettings: SelectableSettings;

  // multiselect form

  public FormData: any;
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.uuid;
  }

  ngOnDestroy(): void {
    if (!!this.stateSubject$) {
      this.stateSubject$.unsubscribe();
    }
    if (!!this.sub$) {
      this.sub$.unsubscribe();
    }
    if (!!this.routerSub$) {
      this.routerSub$.unsubscribe();
    }
    if (!!this.state$) {
      this.state$.unsubscribe();
    }
    if (!!this.searchChanged) {
      this.searchChanged.unsubscribe();
    }
    if (!!this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    // Drop the current page and pageSize
    console.log('destroyed table component');

  }

  public isRowSelected(id: string): boolean {
    return this.selectedIds.indexOf(id) > 0;
  }
  public addSelect(id: string): void {
    if (this.selectedIds.indexOf(id) < 0) {
      this.selectedIds.push(id);
    }
    return;
  }

  public removeSelect(id: string): void {
    this.selectedIds = this.selectedIds.filter((i) => i !== id);
    return;
  }

  onMultiSelectSave(a: any): void {
    // console.log('DONE', a);
    this.multiSelectFormRef.close();
  }

  public changedSelection(e: any): void {
    e.deselectedRows.forEach((row) => {
      this.removeSelect(row.dataItem.uuid);
    });
    e.selectedRows.forEach((row) => {
      this.addSelect(row.dataItem.uuid);
    });
    // console.log(this.selectedIds);
  }

  ngOnInit(): void {
    this.paginationState = this.qpService.InitPagination();
    this.searchField = this.qpService.GetParam('find');
    this.qpService.DropParam('sortArray');
  }

  public next(): void {
    this.loading = true;
    this.stateSubject$.next(true);
    // DATE NOT RELOADED HERE
  }

  changedSearchField(e: any): void {
    if (this.searchField === '') {
      this.qpService.DropParam('find');
    }
    this.searchChanged.next('');
  }

  public openModel(t: TemplateRef<any>): void {
    this.dialogService.open({ title: 'Selected IDs', content: t });
  }

  public openWindowOrPage(item: any, a: any): void {
    if (a?.modal !== true) {
      return this.actionButton(a, item.uuid);

    }
    return this.modal.showDynamicFormModal(item.uuid || this.id, a.action);
  }

  public actionButton(a: any, id?: any): void {
    if (Array.isArray(a['url-parameters'])) {
      const m: Map<string, string> = new Map<string, string>();
      a['url-parameters'].forEach((param) => {
        // // console.log(param);
        m.set(param['parameter-name'], param.value);
      });
      this.router.navigate(
        [`/crm/${id || a.uuid || this.id}/action/${a.action}`],
        { queryParams: Object.fromEntries(m) }
      );
    } else {
      // // console.log('no params to set.', any);
      this.router.navigateByUrl(
        `/crm/${id || a.uuid || this.id}/action/${a.action}`
      );
    }
  }

  public dataStateChange(state: State): void {
    this.gridSettings.state = state;
    this.paginationState = this.qpService.ConvertGridPagination({
      skip: state.skip,
      take: state.take,
    });
    this.next();
  }

  public saveGridSettings(grid: GridComponent): void {
    const columns = grid.columns;
    const gridConfig = {
      state: this.gridSettings.state,
      columnsConfig: this.gridSettings.columnsConfig,
    };

    this.persistingService.set('grid', gridConfig);
  }

  public mapGridSettings(gridSettings: GridSettings): GridSettings {
    const state = gridSettings.state;
    this.mapDateFilter(state?.filter);

    return {
      state,
      columnsConfig: gridSettings.columnsConfig?.sort(
        (a: any, b: any) => a.orderIndex - b.orderIndex
      ),
    };
  }

  private mapDateFilter = (descriptor: any) => {
    const filters = descriptor.filters || [];

    filters.forEach((filter: any) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === 'FirstOrderedOn' && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  }

  public resetFilters(): void {
    this.gridSettings.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.next();
  }

  public saveStateResize(e: Array<ColumnResizeArgs>, grid: GridComponent): void {
    // // console.log(e, this.gridSettings.columnsConfig);

    for (const col of this.gridSettings.columnsConfig) {
      for (const ecol of e) {
        if (ecol.column.title == col.field) {
          col._width = ecol.newWidth;
          // // console.log('updated', col);
        }
      }
    }
    // // console.log(e, this.gridSettings.columnsConfig);

    this.saveGridSettings(grid);
  }

  public saveState(e: ColumnReorderEvent, grid: GridComponent): void {
    this.gridSettings.columnsConfig = this.moveInArray(
      this.gridSettings.columnsConfig,
      e.oldIndex,
      e.newIndex
    );
    this.saveGridSettings(grid);
  }
  public saveStateColumVisible(
    e: ColumnVisibilityChangeEvent,
    grid: GridComponent
  ): void {
    for (const col of this.gridSettings.columnsConfig) {
      for (const ecol of e.columns) {
        if (ecol.title == col.field) {
          col.hidden = ecol.hidden;
        }
      }
    }
    this.saveGridSettings(grid);
  }

  moveInArray(arr: any, from: number, to: number): any {
    // Make sure a valid array is provided
    if (Object.prototype.toString.call(arr) !== '[object Array]') {
      throw new Error('Please provide a valid array');
    }

    // Delete the item from it's current position
    const item = arr.splice(from, 1);

    // Make sure there's an item to move
    if (!item.length) {
      throw new Error('There is no item in the array at index ' + from);
    }

    // Move the item to its new position
    arr.splice(to, 0, item[0]);
    return arr;
  }

  openForm(e: StructuredForm, template: TemplateRef<any>): void {
    this.FormData = { action: e };
    this.multiSelectFormRef = this.dialogService.open({
      title: e.name,
      content: template,
      width: '60vw',
    });
  }
}
