import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BreadCrumbComponent } from './view/bread-crumb/bread-crumb.component';
import { EditComponent } from './pages/edit/edit.component';
import { ViewComponent } from './pages/view/view.component';
import { SubmenuComponent } from './view/submenu/submenu.component';
import { JsonFormComponent } from './templates/json-form/json-form.component';
import { JsonListFormComponent } from './templates/json-list-form/json-list-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from './base.component';
import { CrmRoutingModule } from './crm-routing.module';
import { TreeviewComponent } from './view/treeview/treeview.component';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { FormComponent } from './templates/form/form.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { FormWrapperComponent } from './pages/form-wrapper/form-wrapper.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FileDownloadComponent } from './ui/file-download/file-download.component';
import { DelayedDownloadComponent } from './ui/delayed-download/delayed-download.component';
import { UiTableComponent } from './ui/formComponents/table/table.component';
import { JsonViewComponent } from './ui/json-view/json-view.component';

import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { FormsComponent } from './modals/forms/forms.component';
import { CdrBrowserComponent } from './ui/cdr-browser/cdr.component';
import { Cdr2BrowserComponent } from './ui/cdr-browser2/cdr.component';
import { ExportBrowserComponent } from './ui/export-browser/export.component';
import { TimeagoModule } from 'ngx-timeago';
import { TicketBrowserComponent } from './ui/ticket-browser/ticket.component';
import { UiModule } from '../ui-helpdesk/ui.module';
import { UiControlsModule } from '../ui-controls/ui-controls.module';
import { CharonBrowserComponent } from './ui/charon-browser/charon.component';
import { CharonInstancesViewComponent } from './ui/charon-instance/view.component';
import { UiAssetSelectorComponent } from './ui/formComponents/assetselector/assetselector.component';
import { UiIdentitiesComponent } from './ui/formComponents/identities/identities.component';
import { TableComponent } from './templates/table/table.component';
import { UiOrderedMultiselectComponent } from './ui/formComponents/orderedmultiselect/multiselect.component';
import { SortableModule } from "@progress/kendo-angular-sortable";


@NgModule({
  declarations: [EditComponent, ViewComponent, SubmenuComponent, TreeviewComponent,
    JsonFormComponent, JsonListFormComponent, BaseComponent, BreadCrumbComponent,
    FormComponent, FormWrapperComponent, FileDownloadComponent, UiTableComponent,
     DelayedDownloadComponent,
     JsonViewComponent, CdrBrowserComponent,
     Cdr2BrowserComponent, ExportBrowserComponent,
     TicketBrowserComponent, CharonBrowserComponent, CharonInstancesViewComponent,
     FormsComponent,
     UiIdentitiesComponent,
     UiAssetSelectorComponent,
     TableComponent,
     UiOrderedMultiselectComponent,
  ],
  imports: [
    CommonModule,
    TreeViewModule,
    LayoutModule,
    IndicatorsModule,
    PopupModule,
    InputsModule,
    IconsModule,
    NavigationModule,
    ButtonsModule,
    NgxJsonViewerModule,
    FormsModule,
    CrmRoutingModule,
    GridModule,
    PDFModule,
    ExcelModule,
    ReactiveFormsModule,
    LabelModule,
    DateInputsModule,
    DropDownsModule,
    ProgressBarModule,
    DialogsModule,
    ContextMenuModule,
    TimeagoModule.forChild(),
    UiModule,
    UiControlsModule,
    SortableModule
  ]
})
export class CrmModule { }
