<kendo-formerror *ngIf="errorMessageOccurence != ''"> {{errorMessageOccurence}} </kendo-formerror>
<kendo-grid #identity [data]="identitieValue" (dataStateChange)="onStateChange($event)" [scrollable]="false"
  (cellClick)="editHandler($event)" (add)="addHandler($event)" [sortable]="false">
  <ng-template kendoGridToolbarTemplate *ngIf="!disabled">
    <button kendoGridAddCommand *ngIf="!isInEditingMode">Add new</button>
    <div *ngIf="isInEditingMode">
      <button kendoButton [disabled]="!formGroup.valid" (click)="saveCurrent()">
        Change
      </button>
      <button kendoButton themeColor="primary" (click)="cancelHandler()">
        Cancel
      </button>
      <button kendoButton themeColor="warning" (click)="deleteHandler()">
        Delete
      </button>
    </div>
  </ng-template>
  <kendo-grid-column field="identityName" title="Name" width="250">
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem.identityName }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem let-column="column">
      <form novalidate [formGroup]="formGroup">
        <kendo-dropdownlist kendoGridFocusable [data]="allowedToAdd" [textField]="'identityName'" [disabled]="disabled"
          [valueField]="'identityName'" [valuePrimitive]="true" formControlName="identityName">
        </kendo-dropdownlist>
      </form>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="identityValue" title="Value" width="200">
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{dataItem.identityValue}}
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoGridNoRecordsTemplate></ng-template>
</kendo-grid>
<p class="hidden">{{minOccurenceMessage}}</p>
<!-- <ngx-json-viewer [json]="allowedidentities"></ngx-json-viewer> -->
