import { Injectable } from '@angular/core';

enum Keys {
  'DASHBOARD_LAST',
  'CRM_LAST',
  'HELPDESK_LAST',
}

@Injectable({
  providedIn: 'root',
})
export class RoutestateService {
  constructor() {}
  public availableKeys = Keys;
  public storeLastRoute(key: Keys, path: string) {
    switch (key) {
      case Keys.DASHBOARD_LAST:
        localStorage.setItem('DASHBOARD_LAST', path);

        break;
      case Keys.CRM_LAST:
        localStorage.setItem('CRM_LAST', path);

        break;
      case Keys.HELPDESK_LAST:
        localStorage.setItem('HELPDESK_LAST', path);

        break;

      default:
        break;
    }
  }

  public getLastRoute(key: Keys): string {
    var x = ""
    switch (key) {
      case Keys.DASHBOARD_LAST:
       x = localStorage.getItem('DASHBOARD_LAST');
       break;
      case Keys.CRM_LAST:
       x = localStorage.getItem('CRM_LAST');
break;
      case Keys.HELPDESK_LAST:
        x = localStorage.getItem('HELPDESK_LAST');
        break
      default:

      return x
    }
    return x
  }
}
