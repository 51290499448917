<kendo-card style="width: 100%">
  <kendo-card-header>
    <h3>Ticket Browser</h3>
  </kendo-card-header>
  <kendo-card-actions>
    <kendo-switch [(ngModel)]="useRange" (ngModelChange)="next()"></kendo-switch>
    <p>From</p>
    <kendo-datetimepicker [format]="'MM/dd/yyyy HH:mm'" [(value)]="range.start" (valueChange)="next()"
      [disabled]="!useRange"></kendo-datetimepicker>

    <p>Until</p>
    <kendo-datetimepicker [format]="'MM/dd/yyyy HH:mm'" [(value)]="range.end" (valueChange)="next()"
      [disabled]="!useRange"></kendo-datetimepicker>
    <button kendoButton (click)="resetFilters()">Reset Filters</button>
    <button kendoButton [themeColor]="'success'" (click)="dialog.openDialog('Create new ticket', createTicket)">
      New ticket
    </button>
    <!-- <button kendoButton (click)="openDialogWindow($event, debugView)">
      Debug View
    </button> -->
  </kendo-card-actions>

  <kendo-grid #grid [data]="gridData" [pageSize]="pageSize" [skip]="skip" [sort]="gridSettings.state.sort || []"
    [filter]="gridSettings.state.filter || filterUnsused" [sortable]="false" [pageable]="config" [filterable]="true"
    [resizable]="true" [reorderable]="true" [columnMenu]="false" (dataStateChange)="dataStateChange($event)"
    (columnReorder)="saveState($event, grid)" (columnResize)="saveStateResize($event, grid)"
    (columnVisibilityChange)="saveStateColumVisible($event, grid)" (filterChange)="saveState($event, grid)"
    [scrollTop]="true" [loading]="loading">
    <!-- toolbar items -->
    <ng-template kendoGridToolbarTemplate>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-grid-column-chooser [scrollLeft]="true">
      </kendo-grid-column-chooser>
    </ng-template>
    <kendo-grid-column *ngFor="let col of gridSettings.columnsConfig" [field]="col.field" [title]="col.title || ''"
      [width]="col._width || 250" [filter]="col.filter || 'text'" [filterable]="col.filterable"
      [hidden]="col.hidden || false" [format]="col.format">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column" let-sort="sort">
        <kendo-grid-string-filter-cell *ngIf="col.filter == 'text'" [showOperators]="false" [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>

        <kendo-grid-boolean-filter-cell *ngIf="col.filter == 'boolean'" [column]="column" [filter]="filter">
        </kendo-grid-boolean-filter-cell>

        <kendo-grid-numeric-filter-cell *ngIf="col.filter == 'numeric'" [showOperators]="false" [column]="column"
          [filter]="filter">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Actions" [sticky]="true" [width]="150" [filter]="false" [resizable]="false"
      [reorderable]="false">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button kendoButton (click)="openDialogWindow(dataItem, detailView, detailViewActions)">
          View
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</kendo-card>

<ng-template #detailView>
  <ui-ticket-details *ngIf="detailData?.ticket" [ticket]="detailData.ticket" [disabled]="false"></ui-ticket-details>

  <kendo-expansionpanel *ngIf="detailData?.users" [title]="'Participants'" [expanded]="false" style="margin-top: 1rem;">
    <ui-contact *ngFor="let contact of detailData.users" [contact]="contact"></ui-contact>
  </kendo-expansionpanel>

  <kendo-expansionpanel *ngIf="detailData?.messages" [title]="'Messages'" [expanded]="false" style="margin-top: 1rem;">
    <ui-message *ngFor="let message of detailData.messages.records" [message]="message"></ui-message>
  </kendo-expansionpanel>

</ng-template>


<ng-template #detailViewActions>
  <button kendoButton (click)="closeDialog()">Close</button>

  <button kendoButton [themeColor]="'primary'"
    [routerLink]="['/helpdesk/tickets/overview', detailData?.ticket.id, 'view']"
    routerLinkActive="router-link-active">Detailed View</button>
</ng-template>
<!-- CREATE TICKET -->

<ng-template #createTicket>
  <ui-ticket-form [formData]="{belongs_to_asset_id: ar.snapshot.data.data.action.id,
  asset_name: ar.snapshot.data.data.action.name || 'Actual Asset'}" [createAction]="true"
    (submitted)="dialog.closeDialog(); next();"  [chooseAssetAllow]="false" [meta]="{meta: {company_name: ar.snapshot.data.data.action.name, phone_number: ar.snapshot.data.data.action?.phone_number || ''}}">
  </ui-ticket-form>
</ng-template>


<ng-template #createTicketActions>
  <button kendoButton>Click</button>
</ng-template>
