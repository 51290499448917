import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TicketApiService } from 'src/app/services/ticket-api.service';

@Component({
  selector: 'app-meta-view',
  templateUrl: './meta-view.component.html',
  styleUrls: ['./meta-view.component.scss']
})
export class MetaViewComponent implements OnInit {

  @Input() id: any;
  @Input() meta: any;
  @Input() disabled = false;
  @Input() noUpdate = false;

  @Output('canDeactivate') canDeactivate = new EventEmitter<boolean>();

  public form: FormGroup;
  public keys: Array<string> = new Array<string>();

  constructor(private api: TicketApiService, private router: Router, private ar: ActivatedRoute) { }

  ngOnInit(): void {
    console.log(this.meta);
    this.form = new FormGroup({
    });

    Object.entries(this.meta.meta).forEach((val) => {
      this.form.addControl(val[0], new FormControl(val[1]));
      this.keys.push(val[0]);
    });
    console.log(this.form);
    if (this.disabled) {
      this.form.disable();
    }

    // name: new FormControl(this.formData.name, [
    //   Validators.required,
    //   Validators.minLength(3),
    // ]),
    this.form.valueChanges.subscribe({next: (changed) => {
      this.meta.meta = this.form.getRawValue();
      this.canDeactivate.emit(this.form.dirty);
    }});

  }


  formatTitle(s: string): string {
    const splist = s.split('_');
    splist.forEach((val, index) => {
     splist[index] = val.replace(
      val, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));
    });
    return splist.join(' ');
  }

  update(): void {
    this.api.updateMetaPartial(this.meta.ticket_id, this.meta.id, this.form.value).subscribe({
      next: (val) => {
        this.meta.meta = val;
        this.form.markAsPristine();
        this.router.navigate([], {
          relativeTo: this.ar,
          queryParams: { updated: new Date().toISOString() },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

}
