import { Component } from '@angular/core';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { RoutestateService } from './services/routestate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'admin-portal';
  public toggleSwitch: any = document.getElementById('theme-switch');
  public currentTheme = localStorage.getItem('theme');


  constructor(private keycloakService: KeycloakService, private router: Router, private routeStateService: RoutestateService) {

    let count = localStorage.length
    for (let index = 0; index < count; index++) {
      // console.log("localstorage keys", localStorage.key(index));
    }

    keycloakService.keycloakEvents$.subscribe({
      next: e => {
        if (e.type === KeycloakEventType.OnTokenExpired) {
          keycloakService.updateToken();
        }
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // console.log('Route change detected');
          // console.log("url_old", this.router.url)
          // console.log("url_new", event.url)
      }

      if (event instanceof NavigationEnd) {
          // console.log(event);
          if (event.url.startsWith("/crm")){
            this.routeStateService.storeLastRoute(routeStateService.availableKeys.CRM_LAST, event.url)
          }
          if (event.url.startsWith("/dashboard")){
            this.routeStateService.storeLastRoute(routeStateService.availableKeys.DASHBOARD_LAST, event.url)
          }
          if (event.url.startsWith("/helpdesk")){
            this.routeStateService.storeLastRoute(routeStateService.availableKeys.HELPDESK_LAST, event.url)
          }
      }

      if (event instanceof NavigationError) {
          // console.log(event.error);
      }
  });

  }

  switchTheme(e: any) {
    // console.log(e)
    if (e.target.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    }
    else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
  }

}
