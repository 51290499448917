import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import {
  BreadCrumbCollapseMode,
  BreadCrumbItem,
} from '@progress/kendo-angular-navigation';
import { Subscription } from 'rxjs';
import { ActionService } from 'src/app/services/action-service';
import { AssetApiService } from 'src/app/services/asset-api.service';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.css'],
})
export class BreadCrumbComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private actionService: ActionService
  ) {
    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.data = this.ar.firstChild?.snapshot.data.data.breadCrumbMenu;
        this.fetch();
      }
    });
  }

  // TODO:
  public data: any;
  public routerSub: Subscription;

  @Input()
  public items: BreadCrumbItem[] = [];
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }
  ngOnInit(): void {
    this.fetch();
  }

  action(item: any, index: number) {
    if (index === this.data.trail.length - 1) {
      console.log('last');
      this.actionService.refreshViewAction$.next(true);
      this.actionService.refreshMainMenuAction$.next(true);
      this.actionService.refreshSubMenuAction$.next(true);
    }
    console.log(
      '[TRACE] breadcrumb clicked',
      item,
      index,
      this.data.trail.length
    );
    this.data.trail.forEach((trailItem: any) => {
      if (item.title === trailItem.id) {
        this.router.navigate([
          `/crm/${trailItem.id}/action/${trailItem.actionName}`,
        ]);
      }
    });
  }
  public isLast(index): boolean {
    return index === this.data.trail.length - 1;
  }

  fetch() {
    this.items = new Array<BreadCrumbItem>();
    if (this.data) {
      this.data.trail.forEach((crumb: any) => {
        this.items.push({ text: crumb.caption || crumb.actionName, title: crumb.id });
      });
    }
  }
}
