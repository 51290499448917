<form class="k-form" [formGroup]="form">
  <kendo-formfield *ngFor="let item of keys">
    <kendo-label [for]="item" [text]="formatTitle(item)"></kendo-label>
    <kendo-textbox
      [formControlName]="item"
      [clearButton]="true"
    ></kendo-textbox>
    <kendo-formerror
      >Error: {{ formatTitle(item) }} is required</kendo-formerror
    >
  </kendo-formfield>

  <button
    *ngIf="form.dirty && noUpdate && !isCreate"
    kendoButton
    [themeColor]="'success'"
    (click)="update()"
  >
    Update
  </button>
  <button
    *ngIf="form.dirty && isCreate"
    kendoButton
    [themeColor]="'success'"
    (click)="create()"
  >
    Create
  </button>
</form>
