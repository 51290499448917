import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ExportModel } from '../models/export';

@Injectable({
  providedIn: 'root',
})
export class VersionService {

  constructor(private http: HttpClient) {}

  getVersion(
  ): Observable<any> {
    return this.http.get(`assets/docker.txt`, {responseType: 'text'})
  }


}
