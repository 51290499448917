import { Injectable, TemplateRef } from '@angular/core';

import {
  DialogRef,
  DialogService,
  WindowRef,
  WindowService,
} from '@progress/kendo-angular-dialog';

@Injectable({
  providedIn: 'any',
})
export class ModalService {
  constructor(
    private dialogService: DialogService,
    private window: WindowService
  ) {}

  public ref: DialogRef;
  public windowRef: WindowRef;

  public openDialog(
    title: string,
    contentRef: TemplateRef<any>,
    actionsRef?: TemplateRef<any>, height?: string, width?: string
  ) {
    this.ref = this.dialogService.open({
      title,
      content: contentRef,
      actions: actionsRef,
      width: width || '800px',
      height: height || '80vh',
    });
  }

  public closeDialog(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  public openWindow(title: string, contentRef: TemplateRef<any>): void {
    this.windowRef = this.window.open({
      title,
      content: contentRef,
      width: 500,
      top: 100,
    });
  }
  public closeWindow(): void {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }
}
