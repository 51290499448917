import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AssetApiService } from 'src/app/services/asset-api.service';

@Component({
  selector: 'app-asset-browser',
  templateUrl: './asset-browser.component.html',
  styleUrls: ['./asset-browser.component.scss'],
})
export class AssetBrowserComponent implements OnInit {
  public data = [];
  public search = '';

  @Output('selected') selected = new EventEmitter<string>();

  constructor(private assetApi: AssetApiService) {
  }

  ngOnInit(): void {
    this.fetchRelations();
  }
  searchNext(any: any) {
    this.fetchRelations();
  }

  clicked(e: any) {
    console.log(e);
    this.selected.next(e.dataItem);
  }

  fetchRelations() {
    this.assetApi.getIotProviders(this.search).subscribe({
      next: (v) => {
        this.data = v || [];
      },
    });
  }
}
