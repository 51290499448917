<div *ngIf="data" [ngClass]="{'wrapp': wrapp, 'noWrapp': !wrap}">
  <kendo-card>
    <kendo-card-header>
      <h3>
        {{ data.action.name || "" }}
      </h3>
    </kendo-card-header>

    <kendo-card-actions>

      <!-- <button *ngIf="
          data.action?.crud?.type === '.DeleteAction' &&
          data.action?.crud?.type !== '.ReadAction'
        " kendoButton (click)="saveForm()" [disabled]="saveAllowed()">
        {{ data.action?.crud?.caption }}
      </button> -->
      <button kendoButton [disabled]="!saveAllowed()" (click)="saveForm()" *ngIf="
          data.action?.crud?.caption !== '' && data.action?.crud?.caption !== 'NONE'
        ">
        {{ data.action?.crud?.caption }}
      </button>
      <kendo-switch *ngIf="
          data.action?.crud?.readOnly == false &&
          data.action?.crud?.type == '.UpdateAction'
        " [(ngModel)]="edit" (click)="editForm()" [onLabel]="'EDIT'" [offLabel]="'EDIT'"></kendo-switch>
      <button kendoButton *ngIf="cancel.length > 0" (click)="handleOnCancell()">
        {{ data.action["cancel-caption"] || "cancel" }}
      </button>
      <button kendoButton *ngFor="let hbtn of data.action.headerButtons" (click)="handleHeaderButton(hbtn)">
        {{ hbtn.caption }}
      </button>
      <div class="space" style="width: 100%"></div>
      <button  kendoButton (click)="recalculate()" [themeColor]="'success'" *ngIf="data?.action?.recalculate" [disabled]="!edit" > Recalculate </button>

    </kendo-card-actions>
    <kendo-card-body *ngIf="data">
      <app-form *ngIf="data.action.form.layout" (allValid)="isValid($event)" [editMode]="editable" [readOnly]="readOnly"
        [(data)]="data.action.form.layout" [title]="data.action.form.title" (disableDeactivate)="onDirty()"
        [saveAction]="save" (outputData)="dataFormat($event)" (disableDeactivate)="deactivate($event)"></app-form>
    </kendo-card-body>
  </kendo-card>
</div>
