import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ExportModel } from '../models/export';

@Injectable({
  providedIn: 'root',
})
export class CharonApiService {
  public basePath = `${environment.charon.api}`;

  constructor(private http: HttpClient) {}

  listRecordss(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    var x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/sim/actions`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  getRecord(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/sim/status/${id}`, {
   });
  }

  getInstances(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/config/instances`, {
   });
  }

  public Convert(values: Map<string, string>): Object {
    var x = {};
    values.forEach((key, value) => {});

    return values.entries();
  }


}
