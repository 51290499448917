<div class="div" *ngIf="message">

  <div class="rounded" *ngIf="!message.internal_message">
    <kendo-expansionpanel title="{{message.name}}" [expanded]="true">
      <ng-template kendoExpansionPanelTitleDirective>
        <div class="header-content">
          <kendo-icon name="globe" *ngIf="message.origin == 'API'" [style.marginRight.px]="10"></kendo-icon>
          <kendo-icon name="email" *ngIf="message.origin == 'EMAIL'" [style.marginRight.px]="10"></kendo-icon>
          <span *ngIf="message?.body?.title">{{ message.name }} - {{ message.body.title}}</span>
          <span *ngIf="!message?.body?.title">{{ message.name }}</span>
          <p *ngIf="message.draft" class="k-text-primary"> Message in draft state </p>

        </div>
      </ng-template>

      <div class="flexbox">
        <div class="left">
          <p class="from">{{ message.name }}</p>
          <p class="email">{{ message.email }}</p>
        </div>
        <div class="right">
          <p class="label">Created At</p>
          <p class="email">{{ message.created_at | timeago: "live" }}</p>
        </div>
        <div class="right">
          <p class="label">Sended email to</p>
          <span *ngFor="let email of message?.emails"
            [ngClass]="email?.sended_at == null ? 'k-text-warning': 'k-text-success'">
            {{ email?.email_to }}
          </span>
          <!-- <p class="email" *ngFor="let email of message?.emails">

              <kendo-icon name="globe" class="k-text-sucess" *ngIf="email?.sended_at !== null"
                [style.marginRight.px]="10">

              </kendo-icon>
              <kendo-icon name="globe" class="k-text-danger" *ngIf="email?.sended_at === null"
                [style.marginRight.px]="10"></kendo-icon>
              {{ email?.email_to }}

          </p> -->
        </div>
      </div>

      <div class="row" *ngIf="!message.draft">
        <p class="message" *ngIf="message.body.payload['text/html']" [innerHtml]="message.body.payload['text/html']">
        </p>
        <p class="message" *ngIf="!message.body.payload['text/html']" [innerHtml]="message.body.payload['text/plain']">
        </p>

      </div>
      <div class="row" *ngIf="message.draft">
        <form class="k-form" [formGroup]="form" *ngIf="edit">
          <kendo-formfield>
            <kendo-editor formControlName="body"></kendo-editor>
          </kendo-formfield>
          <button *ngIf="edit" kendoButton [themeColor]="'warning'" (click)="edit = false;">
            Cancel
          </button>
          <button *ngIf="!isCreate" kendoButton [themeColor]="'success'" (click)="submit(); edit = false;">
            Update
          </button>
        </form>
        <p class="message" *ngIf="message.body.payload['text/html'] && !edit"
          [innerHtml]="message.body.payload['text/html']"></p>

        <button kendoButton (click)="edit = true" *ngIf="!edit"> Edit </button>
        <button kendoButton themeColor="success" *ngIf="!edit && !startedSubmitDelay" (click)="submitDelay()"> Send now
        </button>
        <button kendoButton themeColor="info" *ngIf="!edit && startedSubmitDelay" (click)="cancel = true;">
          {{ !cancel ? 'Cancel now....': 'Cancelled..'}}
        </button>
        <span style="margin-top: 1rem;">Notify all participants</span>
        <kendo-switch style="margin-top: 0.5rem;" [(ngModel)]="form.controls.notify_subscribers">
        </kendo-switch>

      </div>

      <div class="attachments border">
        <h4 style="margin-bottom: 0.5rem;">Attachments</h4>
        <div class="item"  *ngFor="let item of attachmentList">
          <ui-preview *ngIf="item.preview" [fileName]="item.name"
          [p]="item">
          </ui-preview>
        </div>
        <div class="upload-row" style="border-top: 1px solid rgba(0, 0, 0, 0.08); margin-top: 0.5rem; padding-top: 0.5rem;">
          <button kendoButton (click)="openFileUpload(uploadFormView)" style="margin-right: 0.5rem;">
          <i class="fa fa-paperclip-vertical" aria-hidden="true"></i>
                        Upload file </button>
          <button kendoButton (click)="deleteMessage()" themeColor="light">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </button>
        </div>

      </div>
    </kendo-expansionpanel>


  </div>

  <div class="wrap k-bg-light" *ngIf="message.internal_message">

    <div class="internal" style="width: 50px">
      <kendo-icon name="note" [style.marginRight.px]="10"></kendo-icon>

      <span>Internal </span>
      <button kendoButton (click)="deleteMessage()" themeColor="light">
        <i class="fa fa-trash-o" aria-hidden="true"></i>
      </button>
    </div>
    <div class="internal" *ngIf="!message.draft">
      <p class="from">
        {{ message.name }} {{ message.email }} /
        {{ message.created_at | timeago: "live" }}
      </p>
      <p class="message strong" *ngIf="message.body.payload['text/html']"
        [innerHtml]="message.body.payload['text/html']"></p>
      <p class="message strong" *ngIf="!message.body.payload['text/html']"
        [innerHtml]="message.body.payload['text/plain']">
      </p>
      <p class="message" *ngIf="message.body.payload['fields']">
        <app-meta-view [meta]="{meta: message.body.payload.fields}" [disabled]="true"></app-meta-view>
        <!-- {{message.body.payload['fields'] | json}} -->
      </p>
      <div class="right">
        <p class="label">Sended email to</p>
        <span *ngFor="let email of message?.emails"
          [ngClass]="email?.sended_at == null ? 'k-text-warning': 'k-text-success'">
          {{ email?.email_to }}
        </span>
        <!-- <p class="email" *ngFor="let email of message?.emails">

            <kendo-icon name="globe" class="k-text-sucess" *ngIf="email?.sended_at !== null"
              [style.marginRight.px]="10">

            </kendo-icon>
            <kendo-icon name="globe" class="k-text-danger" *ngIf="email?.sended_at === null"
              [style.marginRight.px]="10"></kendo-icon>
            {{ email?.email_to }}

        </p> -->
      </div>
    </div>
    <div class="internal k-bg-light" *ngIf="message.draft">
      <form class="k-form" [formGroup]="form" *ngIf="edit">
        <kendo-formfield>
          <kendo-editor formControlName="body"></kendo-editor>
        </kendo-formfield>
        <button *ngIf="edit" kendoButton [themeColor]="'warning'" (click)="edit = false;">
          Cancel
        </button>
        <button *ngIf="!isCreate" kendoButton [themeColor]="'success'" (click)="submit(); edit = false;">
          Update
        </button>
        <span style="margin-top: 1rem; align-self: middle;">Notify all participants</span>
        <kendo-switch style="margin-top: 0.5rem;" [(ngModel)]="form.controls.notify_subscribers">
        </kendo-switch>

      </form>
      <p class="message" *ngIf="message.body.payload['text/html'] && !edit"
        [innerHtml]="message.body.payload['text/html']"></p>

      <button kendoButton (click)="edit = true" *ngIf="!edit"> Edit </button>
      <button kendoButton themeColor="success" *ngIf="!edit && !startedSubmitDelay" (click)="submitDelay()"> Send now
      </button>
      <button kendoButton themeColor="info" *ngIf="!edit && startedSubmitDelay" (click)="cancel = true;">
        {{ !cancel ? 'Cancel now....': 'Cancelled..'}}
      </button>
    </div>

  </div>


</div>


<ng-template #uploadFormView>
  <form [formGroup]="uploadForm">
    <div>
      <input type="file" name="file" (change)="onFileSelect($event)" />
    </div>
    <div>
      <button kendoButton *ngIf="uploadForm.valid" (click)="submitUpload()">Upload</button>
    </div>
  </form>
</ng-template>
