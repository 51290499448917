import { TableColumnDefinition } from '../models/table';
import { GridSettings } from '../shared/table';

export const ExportBrowserGridSettings: GridSettings = {
  state: {
    skip: 0,
    take: 5,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: '',
        dir: 'asc',
      },
    ],
  },
  // gridData: undefined,
  columnsConfig: [
    {
      field: 'id',
      title: 'ID',
      filterable: false,
      _width: 60,
      hidden: false,
    },
    {
      field: 'owner',
      title: 'Owner UUID',
      filterable: false,
      _width: 300,
      hidden: true,
    },
    {
      field: 'name',
      title: 'Name',
      filterable: true,
      filter: 'text',
      _width: 300,
      hidden: true,
    },
    {
      field: 'interval',
      title: 'Interval',
      filter: 'numeric',
      filterable: false,
      _width: 140,
      hidden: true,
    },
    {
      field: 's3_bucket_name',
      title: 'Bucket Name',
      filter: 'text',
      filterable: true,
      _width: 240,
      hidden: false,
    },
    {
      field: 's3_bucket_prefix',
      title: 'Bucket Prefix',
      filter: 'text',
      _width: 240,
      filterable: true,
      hidden: false,
    },
    {
      field: 's3_object_file_template',
      title: 'Bucket Template',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'object_size',
      title: 'Object Size',
      filter: 'numeric',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'max_objects_per_run',
      title: 'Max Object Per run',
      filter: 'numeric',
      _width: 180,
      filterable: true,
      hidden: false,
    },
  ],
};
