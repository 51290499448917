import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ExportModel } from '../models/export';

@Injectable({
  providedIn: 'root',
})
export class CdrApiService {
  public basePath = `${environment.cdr.api}`;

  constructor(private http: HttpClient) {}

  listCdrs(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    var x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/cdr`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  getCdr(id: number, uuid: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/cdr/${id}`, {
      params: new HttpParams().appendAll({ owner_uuid: uuid }),
    });
  }

  public Convert(values: Map<string, string>): Object {
    var x = {};
    values.forEach((key, value) => {});

    return values.entries();
  }

  getExport(id: string, uuid: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/export/${id}`, {
      params: new HttpParams().appendAll({ owner_uuid: uuid }),
    });
  }

  getExportJobs(id: string, uuid: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/export/${id}/jobs`, {
      params: new HttpParams().appendAll({ owner_uuid: uuid }),
    });
  }


  listExports(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    var x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/export`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  createExport(body: ExportModel, owner: string) {
    return this.http.post<any>(`${this.basePath}/export`, {
      "export_name":body.exportName,
      "asset_uuid": owner,
      "s3_bucket_name": body.s3BucketName,
      "s3_bucket_prefix": body.s3BucketPrefix,
      "s3_object_file_template": body.s3ObjectFileTemplate,
      "object_size": body.objectSize,
      "max_object_per_run": body.maxObjectsPerRun,
      "interval": body.interval,
      "start_from": body.startFrom
    }, {
      params: new HttpParams().appendAll({ owner_uuid: owner }),
    });
  }
  updateExport(body: any, id: string, owner: string) {
    return this.http.post<any>(`${this.basePath}/export/${id}`, body, {
      params: new HttpParams().appendAll({ owner_uuid: owner }),
    });
  }

  suspendExport(id: string, owner: string) {
    return this.http.post<any>(`${this.basePath}/export/${id}/suspend`, null, {
      params: new HttpParams().appendAll({ owner_uuid: owner }),
    });
  }
  resumeExport(id: string, owner: string) {
    return this.http.post<any>(`${this.basePath}/export/${id}/resume`, null, {
      params: new HttpParams().appendAll({ owner_uuid: owner }),
    });
  }


}
