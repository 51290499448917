import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
} from '@angular/forms';
import { ViewComponent } from '@progress/kendo-angular-dateinputs';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DraggableDirective, SortableComponent } from '@progress/kendo-angular-sortable';
import { ActionService } from 'src/app/services/action-service';


@Component({
  selector: 'ui-ordered-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiOrderedMultiselectComponent
  implements OnInit, ControlValueAccessor

{

  @ViewChild('sortLeft') viewLeft: SortableComponent;
  @ViewChild('sortRight') viewRight: SortableComponent;


  @Input() value: any[] = [];

  @Input() disabled: boolean;
  @Input() isCreate: boolean;
  @Input() options: any;

  onChange: any = () => {};
  onTouched: any = () => {};


   // should contain uuids
   public available: string[] = [];
   public test: string[] = [];


  constructor(
    public actionService: ActionService,
    public modalService: DialogService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnDestroy(): void {

  }

  public get disabledIndexes() : any[] {
    if (!this.disabled) {
      return []
    }
    var x = []
    this.value.forEach((val, idx) => {
      x.push(idx)
    })
    return x
  }
  disabledIndex(): any[]{
    if (!this.disabled) {
      return null
    }
    var x = []
    this.value.forEach((val, idx) => {

      x.push(idx)
    })
    return null
  }


  ngOnInit(): void {
    this.ngControl.control.setValidators([this.validate.bind(this)]);
    this.ngControl.control.updateValueAndValidity({emitEvent: true});
    this.available = [];
    this.options?.available.forEach((val) => {
      console.log(val, this.value)
      var elementPos = this.value.map(function(x) {return x.id; }).indexOf(val.id);

      if (elementPos < 0) {
        this.available.push(val.id)
      }
    })
    console.log("AVAILABLE", this.available, this.options)
  }

  add(item: any) {
    console.log(this.viewLeft)
    console.log(item)
    this.value.push({id: item, value: ""})
    this.viewLeft.data = this.value
    this.available = this.available.filter((val) => { return val !== item })

  }
  drop(item: any) {
    this.value = this.value.filter((val) => { return val !== item })
    this.available.push(item.id)
    this.viewRight.data = this.available

  }

  // CrontrolValueAccessor

  writeValue(value: any): void {
    console.log('MS write value func', value);
    if (value !== undefined) {
      this.value = value;
    } else {
      this.value = new Array<string>();
    }
    // console.log('write value', this.ngControl.value);
  }

  friendlyName(item: string): string {
    return this.options.available.filter((val) => {return val.id === item })[0] || item
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    console.log('change', fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }



  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    console.log(this.disabled);
    // this.renderer.setProperty(
    //   this.elementRef.nativeElement,
    //   'disabled',
    //   isDisabled
    // );
  }

  valid (): boolean {
    return true
  }

  validate({ value }: FormControl) {
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }
  public onStateChange(state: any): void {
    console.log("MS STATE write value", state)
    // this.valid();
  }
}

