
<!-- Showing {{title}} -->
<!-- <div *ngIf="data">
  <ngx-json-viewer [json]="data.assets"></ngx-json-viewer>
</div> -->
<div style="padding: 2rem;"  *ngIf="data.action.assets.length === 0" >
  <a class="k-text-center">No assets here.. </a>
</div>

<div class="wrapper">
  <kendo-expansionpanel
    *ngFor="let item of data.action.assets; index as i"
    [title]="item.name"
    [expanded]="false"
  >
    <div class="content" *ngIf="item.data">
      <ngx-json-viewer [json]="item.data"></ngx-json-viewer>
    </div>
  </kendo-expansionpanel>
</div>
