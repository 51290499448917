import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TicketApiService } from 'src/app/services/ticket-api.service';
import { getChanges } from 'src/app/shared/changes';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-escalation-rule-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class EscalationRuleFormComponent implements OnInit {
  @Input() id: any;
  @Input() config: any;
  @Input() disabled = false;
  @Input() noUpdate = true;
  @Input() isCreate = false;
  @Input() excludeFields: Array<string> = new Array<string>();
  @Input() disabledField: Array<string> = new Array<string>();


  public form: FormGroup;
  public keys: Array<string> = new Array<string>();

  public statuses = [];
  public levels = [];

  @Output() submitted = new EventEmitter<boolean>();

  constructor(private api: TicketApiService, private router: Router) { }

  isMode(mode: string): boolean {
    return environment.portalMode === mode;
  }

  ngOnInit(): void {
    console.log(this.config);
    this.form = new FormGroup({});
    if (!this.config) {
      this.config = {
        name: '',
        at_status: '',
        at_priority: '',
        allowed_duration_min: 1440,
        next_priority: '',
        next_status: '',
        organisation_id: '',
        belongs_to_asset_id: '',
      };
    }
    this.form = new FormGroup({
      name: new FormControl(this.config.name, [Validators.required]),
      at_status: new FormControl(this.config.at_status, [Validators.required]),
      at_priority: new FormControl(this.config.at_priority, [Validators.required]),
      allowed_duration_min: new FormControl(this.config.allowed_duration_min, [Validators.required]),
      next_priority: new FormControl(this.config.next_priority, []),
      next_status: new FormControl(this.config.next_status, []),
    });

    if (this.isMode('HELPDESK_ONLY')) {
      this.form.addControl('organisation_id', new FormControl(this.config.organisation_id, []));
    } else {
      this.form.addControl('belongs_to_asset_id', new FormControl(this.config.belongs_to_asset_id, []));
    }

    this.keys = new Array<string>();

    this.composeStatusList();
    this.composeLevelList();

    Object.entries(this.config).forEach((val) => {
      this.form.addControl(val[0], new FormControl(val[1]));
      if (this.excludeFields.indexOf(val[0]) < 0) {
        this.keys.push(val[0]);
      }
      if (val[0] !== 'id') {
        // this.form.controls.val[0].disable();
      }

    });
    if (this.form.controls.id) {
      this.form.controls.id.disable();
    }
    console.log(this.form);
    if (this.disabled) {
      this.form.disable();
    } else {
      this.disabledField.forEach(key => {
        this.form.controls[key].disable();
      });
    }


    this.form.valueChanges.subscribe({
      next: (changed) => {
        this.config = this.form.getRawValue();
        this.form.markAsDirty();

        console.log('THIS FORM IS CHANGED', changed, this.form.dirty);
      },
    });
  }

  formatTitle(s: string): string {
    const splist = s.split('_');
    splist.forEach((val, index) => {
      splist[index] = val.replace(
        val,
        (txt) => txt[0].toUpperCase() + txt.substr(1).toLowerCase()
      );
    });
    return splist.join(' ');
  }

  update(): void {
    const changes = getChanges(this.form);
    console.log(changes);
    this.api.updateEscalationRulePartial(this.id, changes).subscribe({
      next: (val) => {
        this.config = val;
        this.form.markAsPristine();
        this.submitted.next(true);
      },
    });
  }

  create(): void {
    console.log(this.form.getRawValue());
    this.api.createEscalationRule(this.form.value).subscribe({
      next: (val) => {
        this.config = val;
        this.form.markAsPristine();
        this.submitted.next(true);
      },
    });
  }


  composeStatusList(): void {
    this.statuses = [];
    const s = this.api.listStatuses().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.statuses.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }

  composeLevelList(): void {
    this.levels = [];
    const s = this.api.listLevels().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.levels.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }
}
