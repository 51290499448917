import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActionService } from 'src/app/services/action-service';
import { CrudService } from 'src/app/services/crud-service';

import * as ts from 'typescript';
import { environment } from 'src/environments/environment.prod';
import { AssetApiService } from 'src/app/services/asset-api.service';
@Component({
  selector: 'app-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormWrapperComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() wrapp: boolean;
  @Input() multiSelectPayload: boolean;
  @Input() multiSelectIds: any;

  @Output() done: EventEmitter<any> = new EventEmitter();

  public editable = new BehaviorSubject(false);
  // public isValid = new BehaviorSubject(false);
  public save = new BehaviorSubject(false);
  public edit = false;
  public isDirty = false;
  public readOnly = false;

  public cancel: Array<any> = [];
  private validForms = false;
  public form: any = {};
  public recalculatedHitted = false;
  @Output('disableDeactivate') disableDeactivate = new EventEmitter<boolean>();

  constructor(
    private crudService: CrudService,
    private actionService: ActionService,
    private router: Router,
    private ar: ActivatedRoute,
    private api: AssetApiService,
    private ref: ChangeDetectorRef
  ) {
    this.cancel = this.data?.action['on-cancel'] || [];
    this.readOnly = this.data?.action?.crud.readOnly;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  deactivate(e: any) {
    console.log('deactivate', e);
    this.disableDeactivate.emit(e);
  }

  ngOnInit(): void {
    console.log('received data', this.data);
    this.cancel = this.data?.action['on-cancel'] || [];
    this.readOnly = this.data?.action?.crud?.readOnly || true;
    if (this.data?.action?.crud?.type === '.CreateAction') {
      this.editable.next(true);
    }
  }

  isFormValid = () => {
    return this.validForms;
  };

  isValid(any: any): void {
    console.log(any);
    this.validForms = any;
  }

  onDirty(any?: any): void {
    this.isDirty = true;
  }
  editForm(): void {
    this.editable.next(!this.edit);
  }

  handleOnCancell(): void {
    this.cancel.forEach((element: any) => {
      console.log('HANDLE CANCEL', element);
      this.actionService.handleCallbackAction(element);
    });
  }

  saveForm(): void {
    this.save.next(true);

    if (this.multiSelectPayload) {
      this.crudService.handleCrud(
        this.data.action.crud,
        {
          selectedIds: this.multiSelectIds,
          flat: this.form,
        },
        this.data.action['on-success'],
        this.data.action['on-error']
      );
    } else {
      this.crudService.handleCrud(
        this.data.action.crud,
        this.form,
        this.data.action['on-success'],
        this.data.action['on-error']
      );
    }
    this.done.next(null);
    this.isDirty = false;
  }

  dataFormat(any?: any): void {
    console.log('TEST', Object.values(any));

    if (any) {
      console.log('ASSIGN ', any);
      this.form = Object.assign(this.form, any);
    }
    Object.keys(this.form).forEach((key) =>
      this.form[key] === undefined ? delete this.form[key] : {}
    );
  }

  public handleHeaderButton(btn: any): void {
    btn.actions.forEach((element) => {
      this.actionService.handleCallbackAction(element, btn);
    });
  }

  saveAllowed(): boolean {
    if (!!this.data.action?.recalculate) {
      const x = this.recalculatedHitted === true && this.edit === true;
      console.log('save with recalculate allowed', x);
      return x;
    }
    return true;
  }

  recalculate(): void {
    console.log('RECALCULATE', this.data.action.recalculate);
    let url: string = this.data.action.recalculate.url;
    const id = this.ar.snapshot.params.id || '';
    const action = this.ar.snapshot.params.action || '';
    url = url.replace(/\{\{(.*?)\}\}/g, (x) => {
      console.log(x);

      switch (x) {
        case `{{application}}`:
          return x.replace(`{{application}}`, environment.source);
        case `{{asset-uuid}}`:
          return x.replace(`{{asset-uuid}}`, id);
        case `{{action}}`:
          return x.replace(`{{action}}`, action);
        default:
          return x;
      }
    });
    console.log(url);
    this.save.next(true);

    this.api.post(url, this.form).subscribe({
      next: (value) => {
        this.data.action = {};
        this.data.action = value;
        console.log('updated action from recalculate');
        console.log(value);
        this.recalculatedHitted = true;
        this.ref.detectChanges();
      },
    });
  }
}
