<kendo-textbox #binding [disabled]="disabled" [clearButton]="true" [ngModel]="selectedAsset"
  (ngModelChange)="onChange($event)">
  <ng-template kendoTextBoxSuffixTemplate>
    <kendo-textbox-separator></kendo-textbox-separator>
    <button kendoButton fillMode="clear" class="send-button" (click)="openModel(selectView, button)">Choose</button>
  </ng-template>
</kendo-textbox>



<ng-template #selectView>
  <div class="selectview">
    <div class="treeview">
      <kendo-treeview [nodes]="treeData" textField="caption" kendoTreeViewHierarchyBinding childrenField="children"
        kendoTreeViewExpandable [kendoTreeViewCheckable]="false" (nodeClick)="onNodeClick($event); next();" [style]=""
        [filterable]="true">
        <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
          <span [class]="dataItem.iconcss"></span>
          <span [class]="dataItem.captioncss" [ngClass]="{'k-text-primary': dataItem.uuid === asset?.uuid}"> {{
            dataItem.caption
            }}</span>
        </ng-template>
      </kendo-treeview>
    </div>
    <div class="detailview">

      <kendo-textbox [(ngModel)]="search" placeholder="Search.." (ngModelChange)="skip = 0 ;next()">
        <ng-template kendoTextBoxPrefixTemplate>
          <span class="k-input-icon k-icon k-i-search ng-star-inserted"></span>
          <kendo-textbox-separator></kendo-textbox-separator>
        </ng-template>
      </kendo-textbox>

      <kendo-textbox *ngIf="asset?.caption" [(ngModel)]="asset.caption" [disabled]="true">
        <ng-template kendoTextBoxPrefixTemplate>
          Parent
          <kendo-textbox-separator></kendo-textbox-separator>
        </ng-template>
      </kendo-textbox>
      <kendo-textbox *ngIf="!asset?.caption" [disabled]="true">
        <ng-template kendoTextBoxPrefixTemplate>
          Parent
          <kendo-textbox-separator></kendo-textbox-separator>
        </ng-template>
      </kendo-textbox>

      <form [formGroup]="formGroup">
        <kendo-formfield>
          <!-- {{ formGroup.controls.templateValue.value | json}} -->
          <kendo-formerror>You need to select atleast one template.</kendo-formerror>
          <kendo-multiselect [data]="options['allowed-templates']" [formControlName]="'templateValue'"
            [allowCustom]="false" [disabled]="false" textField="caption" valueField="template" [fillMode]="'outline'"
            [rounded]="rounded">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span class="icontag {{dataItem.icon}}"></span>
              <span class="template">{{ dataItem.caption }}</span> ({{ dataItem.template }})
            </ng-template>
            <ng-template kendoMultiSelectTagTemplate let-dataItem class="k-bg-light">
              <span class="icontag {{dataItem.icon}}"></span>
              <span class="tag">{{dataItem.caption }}</span>

            </ng-template>
          </kendo-multiselect>
        </kendo-formfield>
      </form>


      <kendo-grid *ngIf="gridData" [data]="gridData" [pageable]="true" [pageSize]="pageSize"
        (pageChange)="pageChange($event)" [skip]="skip">
        <kendo-grid-column *ngFor="let col of options.columns" [field]="col.field" [title]="col.caption">
        </kendo-grid-column>
        <kendo-grid-column [title]="''">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <button kendoButton (click)="selectRow(dataItem)">Select</button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>


</ng-template>

<ng-template #button>
  <div class="action-btn">
    <button kendoButton (click)="closeModal()"> Close </button>
  </div>
</ng-template>
