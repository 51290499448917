<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label [for]="name" text="Name"></kendo-label>
    <kendo-textbox formControlName="name" [clearButton]="true" #name required></kendo-textbox>
    <kendo-formerror>Error: name is required</kendo-formerror>
  </kendo-formfield>

  <button *ngIf="isCreate" kendoButton [themeColor]="'success'" (click)="create()"
    [disabled]="!form.dirty || !this.form.valid">
    Create
  </button>
  <button *ngIf="!isCreate" kendoButton [themeColor]="'success'" (click)="create()">
    Update
  </button>
</form>
