import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { BehaviorSubject } from 'rxjs';
import { DashboardApiService } from 'src/app/services/dashboard-api.service';
import { WidgetItem } from '../overview/overview.component';

@Injectable({
  providedIn: 'root',
})
export class WidgetHandlerService {
  public widgets: Map<string, Widget> = new Map<string, Widget>();

  public $change: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private api: DashboardApiService, private notificationService: NotificationService) {}

  public getWidgets(dashboardKey: string): Map<string, Widget> {
    const m = new Map<string, any>();

    this.api.getDashboardWidgets(dashboardKey).subscribe({
      next: (data) => {
        // TODO: set map
       Object.entries(data).forEach((d: any) => {
        m.set(d[1].key,  d[1]);
      });
       console.log('TEST', m);
       this.widgets = m;
       return m;
      },
    });
    return m;
  }

  public storeWidgets(dashboardKey: string): void {
    this.api
      .updateDashboardWidgets(dashboardKey, Object.fromEntries(this.widgets))
      .subscribe({
        next: (data) => {
          console.log(data);
          this.notificationService.show({
            content: 'Your dashboard has been saved.',
            cssClass: 'button-notification',
            animation: { type: 'slide', duration: 400 },
            position: { horizontal: 'right', vertical: 'top' },
            type: { style: 'success', icon: true },
            closable: true,
          });
          this.$change.next(true);
        },
        error: (error) => {
          console.log(error);
          this.notificationService.show({
            content: 'Something whent wrong',
            cssClass: 'button-notification',
            animation: { type: 'slide', duration: 400 },
            position: { horizontal: 'right', vertical: 'top' },
            type: { style: 'warning', icon: true },
            closable: true,
          });
        },
      });

  }

  public addWidget(dashboardKey: string, widget: Widget): void {
    console.log('add new widget ', widget, 'to dashboard', dashboardKey);
    this.widgets.set(widget.key, widget);
    this.storeWidgets(dashboardKey);
    // this.$change.next(this.widgets);
  }

  replacer(key, value): any {
    if (value instanceof Map) {
      return {
        ...Array.from(value.entries()), // or with spread: value: [...value]
      };
    } else {
      return value;
    }
  }

  public setSize(
    dashboardKey: string,
    key: string,
    colSpan?: number,
    rowSpan?: number,
    index?: number,
  ): void {
    const x = this.widgets.get(key);
    console.log('setSize', key, x);
    x.layout.colSpan = colSpan || 1;
    x.layout.rowSpan = rowSpan || 1;
    x.index = index;
    this.widgets.set(key, x);
    console.log('READY TO STORE', this.widgets);
    // this.storeWidgets(dashboardKey);
    // this.$change.next(this.widgets);
  }

  public removeWidget(dashboardKey: string, key: string): void {
    this.widgets.delete(key);
    this.storeWidgets(dashboardKey);
    // this.$change.next(this.widgets);
  }
}
interface Widget {
  key: string;
  caption: string;
  type: string;
  index: number;
  layout: Layout;
}
interface Layout {
  rowSpan: number;
  colSpan: number;
}
