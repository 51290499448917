import { Component, ElementRef, Input, OnInit, Optional, Renderer2, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ActionService } from 'src/app/services/action-service';


const matches = (el, selector) =>
  (el.matches || el.msMatchesSelector).call(el, selector);


@Component({
  selector: 'ui-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     multi: true,
  //     useExisting: UiTableComponent
  //   }
  // ]
})
export class UiTableComponent implements OnInit, ControlValueAccessor {



  constructor(
    private formBuilder: FormBuilder,
    public actionService: ActionService,
    public modalService: DialogService,
    @Optional() @Self() public ngControl: NgControl

  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    if (!this.style.height) {
      this.style.height = '200px';
    }
  }
  @ViewChild(GridComponent)
  private grid: GridComponent;


  @Input() columns: Array<any> = [];
  public value: any[] = [];
  @Input() style: any = {};
  @Input() rowActions: Array<any> = new Array<any>();

  // readOnly sets al columns in disabled state
  @Input() readOnly = false;
  @Input() editable: BehaviorSubject<boolean>;

  @Input() saveAction: Observable<boolean> = new Subject<boolean>();

  public view: unknown[];

  public formGroup: FormGroup;

  public editedRowIndex: number;
  private docClickSubscription: Subscription = new Subscription();
  private isNew: boolean;


  private emptyRow: any = {};

  public changes: any = {};
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    console.log('MS write value func', value);
    if (value !== undefined) {
      this.value = value;
    } else {
      this.value = new Array<any>();
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {

  }

  ngOnInit(): void {
    this.ngControl.control.setValidators([this.validate.bind(this)]);
    this.ngControl.control.updateValueAndValidity({emitEvent: true});
    this.saveAction.subscribe(data => {
      console.log(this.value);
    });
    this.columns.forEach(e => {
      // maybe set default ?
      this.emptyRow[`${e.field}`] = null;
    });

    console.log(this.emptyRow);
  }


  public ngOnDestroy(): void {
    this.docClickSubscription.unsubscribe();
  }

  public createFormGroup(dataItem: any): FormGroup {
    const form = new FormGroup({

    });
    this.columns.forEach(e => {
      const validators: Array<ValidatorFn> = [];
      if (Array.isArray(e.validation)) {
        console.log('Has a validation array defined');
        e.validation?.forEach((val: any) => {
          switch (val.type) {
            case 'min':
              validators.push(Validators.min(val.min));
              break;
            case 'max':
              validators.push(Validators.max(val.max));
              break;
            case 'required':
              validators.push(Validators.required);
              break;
            case 'requiredTrue':
              validators.push(Validators.requiredTrue);
              break;
            case 'email':
              validators.push(Validators.email);
              break;
            case 'minLength':
              validators.push(Validators.minLength(val.minLength));
              break;
            case 'maxLength':
              validators.push(Validators.maxLength(val.maxLength));
              break;
            case 'pattern':
              validators.push(Validators.min(val.pattern));
              break;
            case 'regex':
              validators.push(Validators.pattern(val.regex));
              break;
            case 'nullValidator':
              validators.push(Validators.nullValidator);
              break;
            default:
              break;
          }
        });
      }
      // maybe set default ?
      this.emptyRow[`${e.field}`] = null;
      form.addControl(e.field, new FormControl(dataItem[e.field], validators));
    });
    return form;
  }

  public addHandler(): void {
    this.closeEditor();

    this.formGroup = this.createFormGroup({});
    this.isNew = true;
    console.log('row to add', this.formGroup);
    this.grid.addRow(this.formGroup);
    console.log(this.grid.data);
  }

  public saveRow(): void {
    console.log(this.formGroup.getRawValue());
    if (this.formGroup && this.formGroup.valid) {
      this.saveCurrent();
    }
  }

  public deleteRow(e: any): void {
    if (!confirm('Are you sure?')){
      return;
    }
    this.value.splice(this.editedRowIndex, 1);
    this.closeEditor();
    console.log(e);
  }

  public cellClickHandler({
    isEdited,
    dataItem,
    rowIndex,
  }: CellClickEvent): void {
    if (isEdited || (this.formGroup && !this.formGroup.valid) || this.editable.getValue() === false) {
      return;
    }

    if (this.isNew) {
      rowIndex += 1;
    }

    this.saveCurrent();

    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;

    this.grid.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
    this.ngControl.control.markAsTouched();
  }

  private saveCurrent(): void {
    if (this.formGroup) {
      // update data
      console.log(this.formGroup);
      if (this.isNew){
        this.value.push(this.formGroup.value);
      } else{
        this.value[this.editedRowIndex] = this.formGroup.value;
      }
      this.closeEditor();
    }
  }


  public convertType(e: string): string {
    switch (e) {
      case 'number':
        return 'numeric';
      case 'textbox':
        return 'text';
      default:
        return '';
    }
  }

  validate({ value }: FormControl) {
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  public onStateChange(state: any): void {
    console.log('MS STATE write value', state);
  }
}
