import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CategoryAxisNotesLabelComponent } from '@progress/kendo-angular-charts';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private dialog: DialogService) {}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('http ->', event);
        }
        if (event instanceof HttpErrorResponse) {
          if (event.status >= 500) {
            this.dialog.open({ title: event.error });
          }
          return event;
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason:
            error && error.error && error.error.reason
              ? error.error.reason
              : '',
          status: error.status,
          body: error.message,
        };
        console.log(data);
        if (error.status >= 400) {
          this.dialog.open({ title: 'Something is wrong', content: error.error, actions: [{ text: 'Ok', themeColor: 'primary' }] });
        }
        if (error.status == 0) {
          this.dialog.open({ title: 'Something is wrong',
          content: `${error.message}` , actions: [{ text: 'Ok', themeColor: 'primary' }] });
        }
        return throwError(error);
      })
    );
  }
}
