<kendo-card style="width: 100%">
  <kendo-card-header>
    <h3>CDR Export Browser</h3>
  </kendo-card-header>
  <kendo-card-actions>
    <!-- <kendo-switch
      [(ngModel)]="useRange"
      (ngModelChange)="next()"
    ></kendo-switch>
    <p>From</p>
    <kendo-datetimepicker
      [format]="'MM/dd/yyyy HH:mm'"
      [(value)]="range.start"
      (valueChange)="next()"
      [disabled]="!useRange"
    ></kendo-datetimepicker>

    <p>Until</p>
    <kendo-datetimepicker
      [format]="'MM/dd/yyyy HH:mm'"
      [(value)]="range.end"
      (valueChange)="next()"
      [disabled]="!useRange"
    ></kendo-datetimepicker> -->
    <button kendoButton (click)="openCreateDialog(createExport, actions)">Create Export</button>
    <button kendoButton (click)="resetFilters()">Reset Filters</button>

    <!-- <button kendoButton (click)="openDialogWindow($event, debugView)">
      Debug View
    </button> -->
  </kendo-card-actions>

  <kendo-grid #grid [data]="gridData" [pageSize]="pageSize" [skip]="skip" [sort]="gridSettings.state.sort || []"
    [filter]="gridSettings.state.filter || filterUnsused" [sortable]="false" [pageable]="config" [filterable]="true"
    [resizable]="true" [reorderable]="true" [columnMenu]="false" (dataStateChange)="dataStateChange($event)"
    (columnReorder)="saveState($event, grid)" (columnResize)="saveStateResize($event, grid)"
    (columnVisibilityChange)="saveStateColumVisible($event, grid)" (filterChange)="saveState($event, grid)"
    [scrollTop]="true" [loading]="loading">
    <!-- toolbar items -->
    <ng-template kendoGridToolbarTemplate>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-grid-column-chooser [scrollLeft]="true">
      </kendo-grid-column-chooser>
    </ng-template>
    <kendo-grid-column *ngFor="let col of gridSettings.columnsConfig" [field]="col.field" [title]="col.title || ''"
      [width]="col._width || 250" [filter]="col.filter || 'text'" [filterable]="col.filterable"
      [hidden]="col.hidden || false" [format]="col.format">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column" let-sort="sort">
        <kendo-grid-string-filter-cell *ngIf="col.filter == 'text'" [showOperators]="false" [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>

        <kendo-grid-boolean-filter-cell *ngIf="col.filter == 'boolean'" [column]="column" [filter]="filter">
        </kendo-grid-boolean-filter-cell>

        <kendo-grid-numeric-filter-cell *ngIf="col.filter == 'numeric'" [showOperators]="false" [column]="column"
          [filter]="filter">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Actions" [sticky]="true" [width]="150" [filter]="false" [resizable]="false"
      [reorderable]="false">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button kendoButton (click)="openDialogWindow(dataItem, detailView, updateActions)">
          View
        </button>
        <button kendoButton class="k-bg-success" *ngIf="dataItem.suspend == false" (click)="suspend(dataItem)">
          <i class="fa-solid fa-play"></i>          Active </button>
        <button kendoButton class="k-bg-warning" *ngIf="dataItem.suspend == true"  (click)="suspend(dataItem)">
          <i class="fa-solid fa-pause"></i>
          Suspended </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</kendo-card>

<ng-template #detailView>
  <kendo-label [for]="name" text="Edit" style="padding: 1rem;"></kendo-label>
  <kendo-switch [(ngModel)]="detailDataEdit" [title]="'edit'"></kendo-switch>

  <kendo-card style="width: 100%; margin-top: 1rem;">
    <kendo-card-header>
      Export
    </kendo-card-header>
    <kendo-card-body *ngIf="detailData">

      <kendo-formfield>
        <kendo-label [for]="name" text="Export Name"></kendo-label>
        <kendo-textbox name="name" [clearButton]="true" required [(ngModel)]="detailData.name" [disabled]="!detailDataEdit"></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="interval" text="Run interval"></kendo-label>
        <kendo-numerictextbox name="interval" [clearButton]="true" required [(ngModel)]="detailData.interval" [disabled]="!detailDataEdit">
        </kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="object_size" text="Object Size"></kendo-label>
        <kendo-numerictextbox name="interval" [clearButton]="true" required [(ngModel)]="detailData.object_size" [disabled]="!detailDataEdit">
        </kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="max_objects_per_run" text="Max Object per run"></kendo-label>
        <kendo-numerictextbox name="interval" [clearButton]="true" required [(ngModel)]="detailData.max_objects_per_run" [disabled]="!detailDataEdit">
        </kendo-numerictextbox>
      </kendo-formfield>

    </kendo-card-body>

  </kendo-card>




  <kendo-card style="width: 100%; margin-top: 1rem; margin-bottom: 1rem;">
    <kendo-card-header>
      Bucket
    </kendo-card-header>
    <kendo-card-body *ngIf="detailData">

      <kendo-formfield>
        <kendo-label [for]="s3_bucket_name" text="Bucket name"></kendo-label>
        <kendo-textbox name="s3_bucket_name" [clearButton]="true" required [(ngModel)]="detailData.s3_bucket_name" [disabled]="!detailDataEdit">
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="s3_bucket_prefix" text="Bucket Prefix"></kendo-label>
        <kendo-textbox name="s3_bucket_prefix" [clearButton]="true" required [(ngModel)]="detailData.s3_bucket_prefix" [disabled]="!detailDataEdit">
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="s3_object_file_template" text="Bucket Template"></kendo-label>
        <kendo-textbox name="s3_object_file_template" [clearButton]="true" required [disabled]="!detailDataEdit"
          [(ngModel)]="detailData.s3_object_file_template"></kendo-textbox>
      </kendo-formfield>

    </kendo-card-body>
  </kendo-card>

  <!-- detailJobs -->
  <p>Note.. this is live view of your jobs.</p>
  <kendo-grid [data]="detailJobs">
    <kendo-grid-column field="run_after" title="Run After">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.run_after | date:"short"}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="completed_on" title="Completed On">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.completed_on | date:"short"}}
        <p *ngIf="dataItem.completed_on">{{ parseTime(dataItem.completed_on) | timeago:'live'}}</p>
      </ng-template>
     </kendo-grid-column>
    <kendo-grid-column field="started_at" title="Started At">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.started_at | date:"short"}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="completion_meta" title="meta">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.completion_meta | json }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="error" title="Error">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.error | json }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

</ng-template>
<ng-template #debugView>
  <ngx-json-viewer [json]="gridData"></ngx-json-viewer>
</ng-template>



<ng-template #createExport>
  <kendo-card style="width: 100%; margin-top: 1rem; margin-bottom: 1rem;">
    <kendo-card-header>
      Export
    </kendo-card-header>
    <kendo-card-body>
      <kendo-formfield>
        <kendo-label [for]="exportName" text="Export Name"></kendo-label>
        <kendo-textbox name="exportName" [clearButton]="true" required [(ngModel)]="exportToCreate.exportName">
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="runAfter" text="Run After"></kendo-label>
        <kendo-datetimepicker name="runAfter" [clearButton]="true" required [(ngModel)]="exportToCreate.runAfter">
        </kendo-datetimepicker>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="interval" text="Run interval"></kendo-label>
        <kendo-numerictextbox name="interval" [clearButton]="true" required [(ngModel)]="exportToCreate.interval">
        </kendo-numerictextbox>
      </kendo-formfield>
    </kendo-card-body>

  </kendo-card>

  <kendo-card style="width: 100%; margin-bottom: 1rem;">
    <kendo-card-header>
      Bucket
    </kendo-card-header>
    <kendo-card-body>
      <kendo-formfield>
        <kendo-label [for]="s3_bucket_name" text="S3 Bucket Name"></kendo-label>
        <kendo-textbox name="s3_bucket_name" [clearButton]="true" required [(ngModel)]="exportToCreate.s3BucketName">
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="s3_bucket_prefix" text="S3 Bucket Prefix"></kendo-label>
        <kendo-textbox name="s3_bucket_prefix" [clearButton]="true" required [(ngModel)]="exportToCreate.s3BucketPrefix">
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="s3_object_file_template" text="S3 File Template"></kendo-label>
        <kendo-textbox name="s3_object_file_template" [clearButton]="true" required [(ngModel)]="exportToCreate.s3ObjectFileTemplate">
        </kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="objectSize" text="Object Size" ></kendo-label>
        <kendo-numerictextbox name="objectSize" [clearButton]="true" required [(ngModel)]="exportToCreate.objectSize" >
        </kendo-numerictextbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="maxObjectsPerRun" text="Max Objects Per Run"></kendo-label>
        <kendo-numerictextbox name="maxObjectsPerRun" [clearButton]="true" required [(ngModel)]="exportToCreate.maxObjectsPerRun">
        </kendo-numerictextbox>
      </kendo-formfield>
    </kendo-card-body>



  </kendo-card>


</ng-template>

<ng-template #actions>
  <button kendoButton (click)="submitCreate()">
    Create
  </button>
</ng-template>



<ng-template #updateActions>
  <button kendoButton (click)="submitUpdate()" *ngIf="detailDataEdit">
    Update
  </button>
  <button kendoButton (click)="dialogDetail$.close(); pullJob$.next(false)">
    Cancel
  </button>
</ng-template>
