import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import * as data from './mockdata/data.json';
import * as data2 from './mockdata/data2.json';
import * as dashboardData from './mockdata/dashboard.json';
import * as dataTicket from './mockdata/data-ticket.json';
import { DialogService } from '@progress/kendo-angular-dialog';
import { map, catchError } from 'rxjs/operators';
import { JsonFormComponent } from '../crm/templates/json-form/json-form.component';


const urls = [
  {
    url: 'https://api.dev.fullstackinfra.nl/gui/applications/sim4u/main-menu/items/c1db8129-afa1-47b5-963f-3f1f27ed1ce9/actions/update-invoice/screendetails',
    json: data
  },
  {
    url: 'https://api.dev.fullstackinfra.nl/gui/applications/sim4u/main-menu/items/test/actions/test/screendetails',
    json: data2
  },
  {
    url: 'https://api.dev.fullstackinfra.nl/dashboard',
    json: dashboardData
  },

];

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private dialog: DialogService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    for (const element of urls) {
      if (request.url === element.url) {
        return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpErrorResponse) {
            this.dialog.open({title: event.error});

          }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
          let data = {};
          data = {
              reason: error && error.error && error.error.reason ? error.error.reason : '',
              status: error.status,
              body: error.message,
          };
          console.log(data);
          this.dialog.open({title: 'Something is wrong', content: JSON.stringify(data), width: '50%', height: '50%'});
          // location.href = "/oops"
          return throwError(error);
      }));

  }
}
