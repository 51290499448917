<div class="custom-toolbar">
  <button kendoButton icon="menu" fillMode="flat" (click)="drawer.toggle()"></button>
  <div class="space">

  </div>
  <button kendoButton icon="add" *ngIf="editMode" (click)="openCreateDashboard(createDashboard)"> Create Dashboard
  </button>
  <button kendoButton (click)="debugState()" class="k-bg-success" *ngIf=" editMode"> <i class="fas fa-save    "></i> Save Dashboard </button>
  <button kendoButton (click)="deleteDashboard()" class="k-bg-warning" *ngIf=" editMode"> <i class="fas fa-trash"></i> Delete Dashboard </button>

  <button kendoButton (click)="openWizard(wizard)" *ngIf="editMode"> <i class="fa fa-plus-square" aria-hidden="true"></i> Add Widget </button>
  <kendo-switch class="editMode" [(ngModel)]="editMode"> Edit Mode</kendo-switch>
</div>

<kendo-drawer-container>

  <kendo-drawer #drawer mode="push" [(expanded)]="expanded">
    <ng-template kendoDrawerTemplate>
      <div class="menuItem" *ngFor="let item of dashboards" routerLink="/dashboard/{{item.name}}"
        routerLinkActive="k-bg-primary k-text-light" (click)="editMode = false;">
        <span [class]="item.icon"></span>
        <span>
          {{ item.caption }}
        </span>
      </div>

    </ng-template>
  </kendo-drawer>

  <kendo-drawer-content>
    <kendo-tilelayout #tilelayout [columns]="16" [rowHeight]="255" [resizable]="editMode" [reorderable]="editMode"
      [autoFlow]="'none'">

      <kendo-tilelayout-item *ngFor="let widget of widgets | keyvalue" id="{{widget.key || ''}}"
        [title]=" widget.value.caption || widget.value.key" [colSpan]="widget.value?.layout.colSpan || 1"
        [rowSpan]="widget.value?.layout.rowSpan || 1" [resizable]="true" [order]="widget.value.index">
        <div class="actionbar">
          <div class="delete" *ngIf="editMode">
            <button kendoButton class="k-bg-warning" (click)="deleteItem(widget.key)" icon="trash"></button>
          </div>
        </div>
        <div class="wrap" *ngIf="widget.value.values">
          <app-counter *ngIf="widget.value?.type == 'counter'" [data]="keyToData(widget.value?.key)[0]"></app-counter>
          <app-barchart *ngIf="widget.value?.type == 'barchart'" [data]="keyToData(widget.value?.key)[0]">
          </app-barchart>
          <app-linechart *ngIf="widget.value?.type == 'linechart'" [data]="keyToData(widget.value?.key)[0]">
          </app-linechart>
          <app-piechart *ngIf="widget.value?.type == 'piechart'" [data]="keyToData(widget.value?.key)[0]">
          </app-piechart>
        </div>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
  </kendo-drawer-content>
</kendo-drawer-container>

<ng-template #wizard>
  <kendo-treeview [nodes]="itemsToAdd" [textField]="'caption'" kendoTreeViewExpandable kendoTreeViewHierarchyBinding
    childrenField="children">
    <ng-template kendoTreeViewNodeTemplate let-dataItem>
      <app-preview-widget
        [preview]="{key: dataItem.key, title: dataItem.caption, type: dataItem.type, children: dataItem.children}">
      </app-preview-widget>
    </ng-template>
  </kendo-treeview>
</ng-template>

<ng-template #createDashboard>
  <form class="k-form" [formGroup]="form">
    <kendo-formfield>
      <kendo-label [for]="caption" text="Dashboard Name"></kendo-label>
      <kendo-textbox formControlName="caption" [clearButton]="true" #caption required></kendo-textbox>
      <kendo-formerror>Error: Dashboard Name is required</kendo-formerror>
    </kendo-formfield>
    <kendo-expansionpanel style="margin-top: 0.5rem;" title="more">
      <kendo-formfield>
        <kendo-label [for]="icon" text="Icon"></kendo-label>
        <kendo-textbox formControlName="icon" [clearButton]="true" #icon></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <div class="k-form-field-checkbox-wrap">
          <kendo-label class="k-checkbox-label" for="shared" text="Shared"></kendo-label>
          <input kendoCheckBox id="shared" type="checkbox" formControlName="shared" />
        </div>
      </kendo-formfield>

    </kendo-expansionpanel>


    <button kendoButton *ngIf="form.valid" [themeColor]="'success'" (click)="submitForm()" style="margin-top: 0.5rem;">
      Create
    </button>
    <button kendoButton [themeColor]="'warning'" (click)="createdDashboardRef.close()" style="margin-top: 0.5rem;">
      Cancel
    </button>
  </form>

</ng-template>


<div kendoDialogContainer></div>
