<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label [for]="name" text="Name"></kendo-label>
    <kendo-textbox formControlName="name" [clearButton]="true" #name required></kendo-textbox>
    <kendo-formerror>Error: name is required</kendo-formerror>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="email" text="E-Mail"></kendo-label>
    <kendo-textbox formControlName="email" [clearButton]="true" #email required></kendo-textbox>
    <kendo-formerror>Error: email is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="opt_out" text="Opt Out"></kendo-label>
    <kendo-switch formControlName="opt_out" #opt_out required></kendo-switch>
  </kendo-formfield>

  <kendo-formfield *ngIf="isMode('CRM_ONLY') || isMode('ALL') || isMode('CRM_DASHBOARD_ONLY')">
    <kendo-label [for]="belongs_to_asset_id" text="Belongs To Asset"></kendo-label>
    <kendo-textbox formControlName="belongs_to_asset_id" [clearButton]="true" #belongs_to_asset_id></kendo-textbox>
    <button kendoButton *ngIf="!chooseAsset" (click)="chooseAsset = !chooseAsset">
      choose assets
    </button>
    <app-asset-browser *ngIf="chooseAsset" (selected)="setchoosed($event.uuid)"></app-asset-browser>
    <kendo-formerror>Error: identity is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield *ngIf="isMode('HELPDESK_ONLY')">
    <kendo-label [for]="organisation_id" text="Organisation"></kendo-label>
    <!-- <kendo-textbox formControlName="organisation_id" [clearButton]="true" #organisation_id></kendo-textbox> -->
    <kendo-combobox
      #combo
      [data]="data"
      [filterable]="true"
      textField="name"
      valueField="id"
      [valuePrimitive]="true"
      formControlName="organisation_id"
    >
    </kendo-combobox>
    <kendo-formerror>Error: organisation is required</kendo-formerror>
  </kendo-formfield>


  <kendo-formfield>
    <kendo-label [for]="internal" text="Internal"></kendo-label>
    <kendo-switch formControlName="internal" #internal required></kendo-switch>
  </kendo-formfield>

  <button *ngIf="isCreate" kendoButton [themeColor]="'success'" (click)="create()"
    [disabled]="!form.dirty || !form.valid || disabled">
    Create
  </button>
  <button *ngIf="!isCreate" kendoButton [themeColor]="'success'" (click)="create()" [disabled]="disabled">
    Update
  </button>
</form>
