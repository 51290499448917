import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryAxisNotesLabelComponent } from '@progress/kendo-angular-charts';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { MenuInfo, MenuItem } from '../models/menu';
import { PaginationState } from './param.service';

@Injectable({
  providedIn: 'root',
})
export class AssetApiService {
  public basePath = `${environment.basePath}/gui/applications/${environment.source}`;

  public paramSub: BehaviorSubject<any> = new BehaviorSubject(false);

  public paramsURL = {};
  private params = new Map<string, any>();
  constructor(private http: HttpClient, private ar: ActivatedRoute) {
    this.basePath = `${environment.basePath}/gui/applications/${environment.source}`;
    // console.log('SETTED BASE PATH', this.basePath);

    this.paramSub.subscribe((x) => {
      this.paramsURL = {};
      this.ar.queryParams.forEach((prm) => {
        // console.log(prm)
        this.paramsURL = Object.assign(this.paramsURL, prm);

      });
      // console.log(this.paramsURL)
    });
  }

  // Retrieves base info
  getInfo(): Observable<MenuInfo> {
    return this.http.get<MenuInfo>(`${this.basePath}/info`);
  }

  search(s: string, params?: Map<string, any>): Observable<any> {
    if (!params) {
      params = new Map<string, any>();
    }
    // console.log('param', this.paramsURL)
    return this.http.post<MenuInfo>(
      `${this.basePath}/search`,
      { search: s },
      // { params: new HttpParams().appendAll(this.paramsURL) }
    );
  }

  // Retrieves Root Elements
  // Maxlevels optional
  getMainMenuRootElements(): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/main-menu/root-elements?maxlevels=50`
    );
  }
  getIotProviders(find: string): Observable<any> {
    return this.http.get<any>(`${environment.basePath}/ar/sim4u-test/assets`, {
      params: new HttpParams().appendAll({
        template: 'abacus.sim4u.iotprovider.v1',
        find,
      }),
    });
  }

  // Retrieves Root Elements
  // Maxlevels optional
  getTreeQuery(): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/main-menu/root-elements?maxlevels=50`
    );
  }

  getAssetQuery(
    repository: string,
    currentPage: number,
    pageSize: number,
    parent?: string,
    find?: string,
    template?: string[]
  ): Observable<any> {
    const par: any = {};
    if (parent) {
      par.parent = parent;
    }
    if (find) {
      par.find = find;
    }
    if (template) {
      par.template = template.join(',');
    }
    par.currentPage = currentPage.toFixed();
    par.pageSize = pageSize.toFixed();
    par.action = 'countedfetch';
    return this.http.get<any>(
      `${environment.basePath}/ar/${repository}/assets`,
      {
        params: new HttpParams().appendAll(par),
      }
    );
  }

  // Fetches sub items
  getMainMenuSubItems(id: any): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(
      `${this.basePath}/main-menu/items/${id}/expand`
    );
  }

  getSubMenu(id: any): Observable<any> {
    this.basePath;

    return this.http.get<any>(`${this.basePath}/main-menu/items/${id}/click`);
  }

  getBreadCrumb({ id, action }: { id: any; action: any }) {
    return this.http.get<any>(
      `${this.basePath}/main-menu/items/${id}/actions/${action}/breadcrumb`
    );
  }
  getContextMenu(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/main-menu/items/${id}/contextmenu`
    );
  }

  // This returns a action to be setted
  getMainMenuClick(id: any): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/main-menu/items/${id}/mainmenuclick`
    );
  }

  getScreenDetails(
    id: any,
    action: string,
    pagination?: PaginationState,
    searchField?: string,
    params?: Map<string, any>
  ) {
    action = action.replace('--', '-');
    const x: Map<string, any> = new Map<string, any>();
    // if (pagination) {
    //   x.set('currentPage', pagination.currentPage || 0);
    //   x.set('pageSize', pagination.pageSize || 20);
    // }
    if (searchField) {
      x.set('find', searchField);
    }

    if (!params) {
      params = new Map<string, any>();
    }

    return this.http.get<any>(
      `${this.basePath}/main-menu/items/${id}/actions/${action}/screendetails`,
      {
        params: new HttpParams()
          .appendAll(Object.fromEntries(x))
          .appendAll(Object.fromEntries(params)),
      }
    );
  }

  getAction({
    id,
    action,
    pagination,
    searchField,
  }: {
    id: any;
    action: string;
    pagination?: PaginationParams;
    searchField?: string;
  }) {
    action = action.replace('--', '-');
    if (pagination) {
      if (searchField) {
        return this.http.get<any>(
          `${
            this.basePath
          }/main-menu/items/${id}/actions/${action}/click?pageSize=${
            pagination.pageSize || 20
          }&currentPage=${pagination.currentPage}&find=${searchField}`
        );
      }
      return this.http.get<any>(
        `${
          this.basePath
        }/main-menu/items/${id}/actions/${action}/click?pageSize=${
          pagination.pageSize || 20
        }&currentPage=${pagination.currentPage}`
      );
    }
    return this.http.get<any>(
      `${this.basePath}/main-menu/items/${id}/actions/${action}/click`
    );
  }

  // LOGIC PLAN
  getRuleTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.basePath}/gui/logicplan/ruletypes`
    );
  }

  post(path: string, body: any): Observable<any> {
    return this.http.post<any>(`${environment.basePath}${path}`, body);
  }
}

export class PaginationParams {
  pageSize = 10;
  currentPage = 1;
}
