<div class="demo">
  <kendo-card width="360px">
    <kendo-card-header class="k-hstack">

      <div>
        <h1 kendoCardTitle>Ooops</h1>
        <p kendoCardSubtitle>Something whent wrong.</p>
        <p>
          {{ state | json }}
        </p>
      </div>
    </kendo-card-header>
    <kendo-card-actions class="icon-card-actions">
      <div>
        <button class="k-button k-flat" [routerLink]="'/'">
            Return
        </button>
      </div>
    </kendo-card-actions>
  </kendo-card>
</div>
