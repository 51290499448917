<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label [for]="title" text="Title"></kendo-label>
    <kendo-textbox formControlName="title" [clearButton]="true" #title required></kendo-textbox>
    <kendo-formerror>Error: title is required</kendo-formerror>
  </kendo-formfield>

  <!-- <kendo-formfield>
    <kendo-label [for]="reference" text="reference"></kendo-label>
    <kendo-textbox formControlName="reference" [clearButton]="true" #reference></kendo-textbox>
    <kendo-formerror>Error: refence is required</kendo-formerror>
  </kendo-formfield> -->

  <kendo-formfield>
    <kendo-label [for]="message" text="message"></kendo-label>
    <kendo-textbox formControlName="message" [clearButton]="true" #message required></kendo-textbox>
    <kendo-formerror>Error: message is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield *ngIf="chooseAssetAllow && getMode('ALL') " >
    <button kendoButton (click)="openDialog('Choose Asset', assetView, null)">Choose Asset</button>
  </kendo-formfield>
  <!-- <kendo-formfield>
    <kendo-label [for]="belongs_to_asset_id" text="belongs_to_asset_id"></kendo-label>
    <kendo-textbox formControlName="belongs_to_asset_id" [clearButton]="true" #belongs_to_asset_id></kendo-textbox>

  </kendo-formfield> -->

  <kendo-formfield *ngIf="getMode('HELPDESK_ONLY')">
    <kendo-label [for]="organisation_id" text="Organisation"></kendo-label>
    <!-- <kendo-textbox formControlName="organisation_id" [clearButton]="true" #organisation_id></kendo-textbox> -->
    <kendo-combobox
      #combo
      [data]="data"
      [filterable]="true"
      textField="name"
      valueField="id"
      [valuePrimitive]="true"
      formControlName="organisation_id"
    >
    </kendo-combobox>
    <kendo-formerror>Error: organisation is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield  *ngIf="chooseAssetAllow && getMode('ALL') ">
    <kendo-label [for]="asset_name" text="Asset Name"></kendo-label>
    <kendo-textbox formControlName="asset_name" [clearButton]="true" #asset_name></kendo-textbox>

  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="ticket_type" text="Type"></kendo-label>
    <kendo-dropdownlist formControlName="ticket_type" #ticket_type required textField="ticket_type" [data]="types"
      [defaultItem]="form.controls.ticket_type.value">
    </kendo-dropdownlist>
    <kendo-formhint>Changing type will update the Last Action field</kendo-formhint>
    <kendo-formerror>Error: type is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="status" text="Status"></kendo-label>
    <kendo-dropdownlist formControlName="status" #status required textField="status" [data]="statuses"
      [defaultItem]="form.controls.status.value">
    </kendo-dropdownlist>
    <kendo-formhint>Changing status will update the Last Action field</kendo-formhint>

    <kendo-formerror>Error: status is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="level" text="Level"></kendo-label>
    <kendo-dropdownlist formControlName="level" #level required textField="levels" [data]="levels"
      [defaultItem]="form.controls.level.value">
    </kendo-dropdownlist>
    <kendo-formhint>Changing level will update the Last Action field</kendo-formhint>

    <kendo-formerror>Error: status is required</kendo-formerror>
  </kendo-formfield>
  <kendo-formfield *ngIf="isCreate && meta">
    <app-meta-view [(meta)]="meta"></app-meta-view>
  </kendo-formfield>

  <kendo-formfield>
    <button kendoButton (click)="openDialog('Choose Solver', solverView, null)">Choose Solver</button>

  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="solver_email" text="Solver Email"></kendo-label>
    <kendo-textbox formControlName="solver_email" [clearButton]="false" #solver_email required [disabled]="true">
    </kendo-textbox>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="solver_name" text="Solver Name"></kendo-label>
    <kendo-textbox formControlName="solver_name" [clearButton]="false" #solver_name required [disabled]="true">
    </kendo-textbox>
  </kendo-formfield>


  <button *ngIf="isCreate" kendoButton [themeColor]="'success'" (click)="create()"
    [disabled]="!form.dirty || !this.form.valid">
    Create
  </button>
  <button *ngIf="!isCreate" kendoButton [themeColor]="'success'" (click)="create()">
    Update
  </button>
</form>


<ng-template #solverView>
  <ui-user-form-browser [internal]="true" (selected)="setSolver($event)"></ui-user-form-browser>
</ng-template>

<ng-template #assetView>
  <app-asset-browser (selected)="setAsset($event)">
  </app-asset-browser>
</ng-template>
