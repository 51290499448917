import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
import { TicketApiService } from 'src/app/services/ticket-api.service';
import { getChanges } from 'src/app/shared/changes';

@Component({
  selector: 'app-support-ticket-status-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class SupportTicketStatusFormComponent implements OnInit {
  public chooseAsset = false;
  private createAction = false;

  public form: FormGroup;

  @Output() submitted = new EventEmitter<boolean>(false);

  @Input() formData: any;

  constructor(
    public api: TicketApiService,
    private modal: ModalService,
    private router: Router,
    private ar: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log(this.formData);
    if (!this.formData) {
      console.log('create empty form');
      this.createAction = true;
      this.formData = {
        name: '',
      };
    }

    this.form = new FormGroup({
      name: new FormControl(this.formData.name, [
        Validators.required,
        Validators.minLength(3),
      ]),
    });
  }

  public get isCreate(): boolean {
    return this.createAction;
  }
  create() {
    console.log(this.form.getRawValue());

    const changes = getChanges(this.form);

    switch (this.createAction) {
      case true:
        if (this.form.invalid) {
          return;
        }
        console.log(changes);
        this.api.createStatus(changes).subscribe({
          next: (n) => {
            this.router.navigate([], {
              relativeTo: this.ar,
              queryParams: { updated: new Date() },
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
            this.submitted.next(true);
            if (!!this.modal.windowRef) { this.modal.closeWindow(); }
            if (!!this.modal.ref) { this.modal.closeDialog(); }
          },
        });
        break;

      default:
        console.log(changes, 'not implemented');
        // this.api.updatel(changes).subscribe({
        //   next: (n) => {
        //     this.router.navigate([], {
        //       relativeTo: this.ar,
        //       queryParams: { updated: n.id },
        //       queryParamsHandling: 'merge', // remove to replace all query params by provided
        //     });
        //     this.submitted.next(true);
        //     this.modal.closeWindow();
        //   },
        // });
        break;
    }
  }
}
