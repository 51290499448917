import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview/overview.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { BaseComponent } from './base.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { CounterComponent } from './widgets/counter/counter.component';
import { PiechartComponent } from './widgets/piechart/piechart.component';
import { LinechartComponent } from './widgets/linechart/linechart.component';
import { BarchartComponent } from './widgets/barchart/barchart.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IconsModule } from '@progress/kendo-angular-icons';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { PreviewWidgetComponent } from './ui/preview-widget/preview-widget.component';
import { UiModule } from '../ui-helpdesk/ui.module';
@NgModule({
  declarations: [
    OverviewComponent,
    BaseComponent,
    CounterComponent,
    PiechartComponent,
    LinechartComponent,
    BarchartComponent,
    PreviewWidgetComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartsModule,
    FormsModule,
    ButtonsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    IconsModule,
    LayoutModule,
    DropDownsModule,
    DialogsModule,
    TreeViewModule,
    UiModule
  ]
})
export class DashboardModule { }
