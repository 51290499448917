import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardApiService } from '../services/dashboard-api.service';

@Injectable({ providedIn: 'root' })
export class ViewDashboardResolver implements Resolve<any> {
  constructor(private api: DashboardApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    const key = route.paramMap.get('key');
    return this.api.getDashboardWidgets(key);
  }
}


@Injectable({ providedIn: 'root' })
export class DashboardsResolver implements Resolve<any> {
  constructor(private api: DashboardApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.api.getDashboards();
  }
}

