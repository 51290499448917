import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  DomSanitizer,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Component({
  selector: 'ui-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class AttachmentPreviewComponent implements OnInit {
  @Input() fileName: string;
  @Input() p: any;

  constructor(private sanitizer: DomSanitizer, private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  public Preview(template: TemplateRef<any>) {
    this.dialogService.open({ title: this.p.name, content: template, height: '90vh', width: '90vw' });
  }

  public fixURL(data: any, large?: boolean): SafeResourceUrl {
    switch (data.preview_type) {
      case 'image/png':
        if (large === true) {
          return this.sanitizer.bypassSecurityTrustHtml(
            ` <img style="height: 500px; width: 500px;" src="data:image/png;base64, ${data.preview}" />    `
          );
        }
        return this.sanitizer.bypassSecurityTrustHtml(
          ` <img style="height: 50px; width: 50px;" src="data:image/png;base64, ${data.preview}" />    `
        );
      case 'document/pdf':
        if (large === true) {
          return this.sanitizer.bypassSecurityTrustHtml(
            ` <ngx-extended-pdf-viewer base64Src="${data.preview}"   [height]="'100%'" [width]="'100%'"
            [useBrowserLocale]="true"></ngx-extended-pdf-viewer>`
          );
        }
        return this.sanitizer.bypassSecurityTrustHtml(
          ` Click here `

       );
      case 'application/json':
        return this.sanitizer.bypassSecurityTrustHtml(
          ` Click here `
       );

      default:
        return this.sanitizer.bypassSecurityTrustHtml(
          ` <span> No preview </span> `
        );
    }
  }
}
