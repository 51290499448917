import { AfterViewInit, Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { TicketApiService } from 'src/app/services/ticket-api.service';

@Component({
  selector: 'app-organisation-assign',
  templateUrl: './organisation-assign.component.html',
  styleUrls: ['./organisation-assign.component.scss']
})
export class OrganisationAssignComponent implements OnInit, AfterViewInit {
  @ViewChild('combo') combo: ComboBoxComponent;
  public data: Array<any>;
  public form: FormGroup = new FormGroup({
    organisation_id: new FormControl()
  });

  @Input() ticketID: string;
  @Output() submitted: EventEmitter<boolean> = new EventEmitter<false>();

  constructor(
    public api: TicketApiService,

  ) { }


  ngOnInit(): void {
    this.form = new FormGroup({
      organisation_id: new FormControl('', [Validators.required])
    });
  }
  ngAfterViewInit(): void {

    const contains = (value) => (s) =>
    s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1;

    this.combo.filterChange
    .asObservable()
      .subscribe((x) => {
      this.combo.loading = true;
      const search: Map<string, string> = new Map();
      search.set('name', 'contains#' + x);
      console.log(x);
      this.api.listOrganisations(1, 20, search).subscribe({next: (n) => {
        this.data = n.records;
      }});
      this.combo.loading = false;
    });
  }
  submit() {
    this.api.updateTicketPartiel(this.ticketID, this.form.value).subscribe({next: (n) => {
      console.log(n);
      this.submitted.emit(true);
    }});
  }
}
