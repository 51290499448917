import { TableColumnDefinition } from '../models/table';
import { GridSettings } from '../shared/table';

export const TicketGridSettings: GridSettings = {
  state: {
    skip: 0,
    take: 5,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: '',
        dir: 'asc',
      },
    ],
  },
  // gridData: undefined,
  columnsConfig: [
    {
      field: 'id',
      title: 'ID',
      filterable: false,
      _width: 60,
      hidden: true,
    },
    {
      field: 'belongs_to_asset_id',
      title: 'Asset UUID',
      filterable: true,
      _width: 300,
      hidden: true,
    },
    {
      field: 'title',
      title: 'Title',
      filter: 'text',
      filterable: true,
      _width: 300,
      hidden: false,
    },
    {
      field: 'organisation.name',
      title: 'Organisation',
      filter: 'text',
      filterable: true,
      _width: 300,
      hidden: false,
    },
    {
      field: 'level',
      title: 'Level',
      filter: 'text',
      filterable: true,
      _width: 200,
      hidden: false,
    },
    {
      field: 'ticket_type',
      title: 'Type',
      filter: 'text',
      filterable: true,
      _width: 200,
      hidden: false,
    },
    {
      field: 'reference',
      title: 'Reference',
      filter: 'text',
      filterable: true,
      _width: 300,
      hidden: false,
    },
    {
      field: 'status',
      title: 'Status',
      filter: 'text',
      filterable: true,
      _width: 300,
      hidden: false,
    },

  ],
};
