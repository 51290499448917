import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { StatePersistingService } from '../services/grid-state.service';
import { DynamicFormService } from './modals/forms/forms.service';

@Component({
  selector: 'crm-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css'],
})
export class BaseComponent implements OnInit {
  public baseState: baseParams;

  public isViewActive = false;
  public isSubActive = false;

  public loading = false;

  constructor(
    private router: Router,
    private modal: DynamicFormService,
    private stateSerrivce: StatePersistingService
  ) {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
    this.baseState = this.stateSerrivce.getView('base') || baseState;
  }

  ngOnInit(): void {}

  showModal(any: any) {
    this.modal.showDynamicFormModal(
      '85917e66-9aac-4408-9b53-1c091d69cfbf',
      'create-contact'
    );
  }

  // treeview
  public get treeViewSize(): string {
    return this.baseState.treeview;
  }

  public set treeViewSize(newSize: string) {
    this.baseState.treeview = newSize;
    this.stateSerrivce.setView('base', this.baseState);
  }

  // sub menu
  public get subViewSize(): string {
    return this.baseState.submenu;
  }

  public set subViewSize(newSize: string) {
    this.baseState.submenu = newSize;
    this.stateSerrivce.setView('base', this.baseState);
  }

  // right view
  public get rightSize(): string {
    return this.baseState.right;
  }

  public set rightSize(newSize: string) {
    this.baseState.right = newSize;
    this.stateSerrivce.setView('base', this.baseState);
  }

  activateView(any?: any) {
    this.isViewActive = true;
  }
  deactivateView(any?: any) {
    this.isViewActive = false;
  }
  activateSub(any?: any) {
    this.isSubActive = true;
  }
  deactivateSub(any?: any) {
    this.isSubActive = false;
  }
}

export interface baseParams {
  treeview: string;
  submenu: string;
  right: string;
}

export const baseState: baseParams = {
  treeview: '20%',
  submenu: '20%',
  right: '',
};
