<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label for="organisation_id" text="Organisation"></kendo-label>
    <kendo-combobox
      #combo
      [data]="data"
      [filterable]="true"
      textField="name"
      valueField="id"
      [valuePrimitive]="true"
      formControlName="organisation_id"
      placeholder="Type... to start a search"
    >
    </kendo-combobox>
    <kendo-formerror>Error: organisation is required</kendo-formerror>
  </kendo-formfield>
</form>

<button kendoButton *ngIf="form.valid" (click)="submit()"> Assign to organisation </button>
