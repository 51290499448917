export const environment = {
  production: true,
  basePath: (window as any).env.apiUrl || 'https://api.dev.fullstackinfra.nl',
  source: (window as any).env.apiSource || 'sim4u',
  portalMode: (window as any).env.portalMode || 'HELPDESK_ONLY',
  keycloak: {
    config: {
      url:
        (window as any).env.KcUrl || 'https://idp.dev.fullstackinfra.nl/auth',
      realm: (window as any).env.KcRealm || 'abacus-gui',
      clientId: (window as any).env.KcClientId || 'portal',
    },
  },
  enableSentry: (window as any).env.EnableSentry || false,
  sentryToken:
    (window as any).env.SentryToken ||
    'https://884b5e4c1cd34d009ab46e3aaee0287d@o1131280.ingest.sentry.io/6175583',
  cdr: {
    api:
      (window as any).env.CdrApi || 'https://cdr.dev.fullstackinfra.nl/api/v1',
  },
  ticket: {
    api: (window as any).env.TicketApi || 'http://localhost:5000/api/v1',
    cron: (window as any).env.CronApi || 'http://localhost:8000/api/v1',
  },
  files: {
    api: (window as any).env.FilesApi || 'http://localhost:5000/api/v1',
  },
  charon: {
    api:
      (window as any).env.CharonApi ||
      'https://charon.dev.fullstackinfra.nl/api/v1',
    cron: (window as any).env.CharonCron || 'http://localhost:8000/api/v1',
  },
  audit: {
    api:
      (window as any)['env']['auditApi'] ||
      'http://localhost:5001/portal-api/v1',
  },
};
