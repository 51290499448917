import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { TicketApiService } from 'src/app/services/ticket-api.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'ui-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit, OnChanges {
  @Input() ticket: any;
  @Input() disabled = false;



  public statuses = [];
  public types = [];
  public levels = [];

  @Output('canDeactivate') canDeactivate = new EventEmitter<boolean>();



  constructor(
    private api: TicketApiService,
    private router: Router,
    private ar: ActivatedRoute,
    public dialogService: ModalService
  ) {
    this.composeStatusList();
    this.composeTypesList();
    this.composeLevelList();
  }

  public form: FormGroup = new FormGroup({
    reference: new FormControl(),
    status: new FormControl(),
    level: new FormControl(),
    type: new FormControl(),
    title: new FormControl(),
    last_action: new FormControl(),
    solver: new FormControl(),
    solver_email: new FormControl(),
    solver_name: new FormControl(),
    is_escalated_since: new FormControl(),
    belongs_to_asset_id: new FormControl(),
  });


  getMode(mode: string): boolean {
    return environment.portalMode === mode;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.buildForm();
    this.form.valueChanges.subscribe({next: n => {
      console.log('fire', this.form.dirty);
      this.canDeactivate.emit(this.form.dirty);
    }});
  }

  buildForm(): void {
    this.form = new FormGroup({
      reference: new FormControl(this.ticket.reference),
      status: new FormControl(this.ticket.status),
      level: new FormControl(this.ticket.level),
      ticket_type: new FormControl(this.ticket.ticket_type),
      title: new FormControl(this.ticket.title),
      last_action: new FormControl(this.ticket.last_action),
      solver: new FormControl(this.ticket.solver),
      solver_email: new FormControl(this.ticket.solver_email),
      solver_name: new FormControl(this.ticket.solver_name),
      is_escalated_since: new FormControl(this.ticket.is_escalated_since),
      // belongs_to_asset_id: new FormControl(this.ticket.belongs_to_asset_id),
    });

    if (this.getMode('ALL')) {
      this.form.addControl('belongs_to_asset_id', new FormControl(this.ticket.belongs_to_asset_id));
      this.form.controls.belongs_to_asset_id.disable({ onlySelf: true });
    }

    this.form.controls.reference.disable({ onlySelf: true });
    this.form.controls.is_escalated_since.disable({ onlySelf: true });
    //  this.form.controls.title.disable({onlySelf: true});
    this.form.controls.last_action.disable({ onlySelf: true });
    this.form.controls.solver_email.disable({ onlySelf: true });
    this.form.controls.solver_name.disable({ onlySelf: true });
    this.form.controls.solver.disable({ onlySelf: true });
    if (this.disabled) {
      this.form.disable();
    }
  }

  openDialog(
    title: string,
    contentRef: TemplateRef<any>,
    actionsRef: TemplateRef<any>
  ) {
    this.dialogService.openDialog(title, contentRef, actionsRef);
  }

  setSolver(e: any) {
    this.dialogService.closeDialog();
    this.form.patchValue(
      { solver: e.id, solver_email: e.email, solver_name: e.name },
      { emitEvent: true }
    );
    // this.form.controls.solver.enable();
    this.form.controls.solver.markAsDirty();
    this.form.controls.solver.markAsTouched();
    console.log(e);
  }

  composeStatusList(): void {
    this.statuses = [];
    const s = this.api.listStatuses().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.statuses.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }
  composeTypesList(): void {
    this.types = [];
    const s = this.api.listTypes().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.types.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }

  composeLevelList(): void {
    this.levels = [];
    const s = this.api.listLevels().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.levels.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }

  submit() {
    console.log(this.form.value);
    const changes = this.getChanges();
    this.api.updateTicketPartiel(this.ticket.id, changes).subscribe({
      next: (n) => {
        this.form.markAsPristine();
        this.canDeactivate.emit(false);
        console.log('DEBUG FORM STATE', this.form.dirty);
        this.router.navigate([], {
          relativeTo: this.ar,
          queryParams: { updated: n.last_action },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      },
    });
  }

  private getChanges(): any {
    const updatedValues: any = {};
    this.getUpdates(this.form, updatedValues);
    return updatedValues;
  }

  private getUpdates(
    formItem: FormGroup | FormArray | FormControl,
    updatedValues: any,
    name?: string
  ) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = formItem.controls[formControlName];

          if (formControl instanceof FormControl) {
            this.getUpdates(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getUpdates(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getUpdates(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }
  resetAsset(e: any) {
    console.log(e);
    this.form.controls.belongs_to_asset_id.setValue(e.uuid);
    this.form.controls.belongs_to_asset_id.markAsTouched();
    this.form.controls.belongs_to_asset_id.markAsDirty();
  }
}
