<!-- <div class="example-config">
  <h5>Included: {{ value | json }}</h5>
  <h5>Available: {{ available | json }} {{available.length}}</h5>
  {{ disabled }}
</div> -->

<div class="wrap" *ngIf="!disabled">



  <div class="included">
    <kendo-label style="color: #424242;">
      included
    </kendo-label>
    <kendo-sortable #sortLeft [kendoSortableBinding]="value" emptyText="Add available" class="row">
      <ng-template let-item="item">
        <div *ngIf="item" class="item node" [ngClass]="{'item disabled': disabled}">
          <p style="font-size: 1rem; font-weight: 500; color: black;"> {{ friendlyName(item.id)?.caption ||
            item.id }}
          </p>
          <div class="row">
            <p *ngIf="options?.control?.caption" style="margin-right: 0.5rem; width: 50%;"> {{ options.control.caption
              }} </p>

            <!-- <kendo-dropdownlist *ngIf="options?.control?.type === 'listbox'" [data]="options.control.options"
              [(ngModel)]="item.value" [disabled]="disabled">
            </kendo-dropdownlist> -->


            <kendo-dropdownlist *ngIf="options?.control?.type === 'listbox'" [(ngModel)]="item.value"
              [data]="options.control.options" [textField]="options.control.binding.textField"
              [valueField]="options.control.binding.valueField"
              [valuePrimitive]="options.control.binding.valuePrimitive" [disabled]="disabled">
            </kendo-dropdownlist>



            <kendo-textbox *ngIf="options?.control?.type === 'textbox'" [(ngModel)]="item.value" [disabled]="disabled">
            </kendo-textbox>
            <kendo-switch *ngIf="options?.control?.type === 'checkbox'" [(ngModel)]="item.value" [disabled]="disabled">
            </kendo-switch>
            <button kendoButton (click)="drop(item)" [disabled]="disabled"> delete </button>
          </div>
        </div>
      </ng-template>
    </kendo-sortable>
  </div>
  <div class="available">
    <kendo-label style="color: #424242;">
      available
    </kendo-label>
    <div *ngFor="let item of available">
      <div *ngIf="item" class="item node-b" [ngClass]="{'item disabled': disabled}">
        <p style="font-size: 1rem; font-weight: 500; color: black;"> {{ friendlyName(item).caption}}</p>
        <button kendoButton (click)="add(item)" [disabled]="disabled"> Add </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!disabled">
  <p> Drag and drop items for reordering. </p>
</div>

<div class="wrap" *ngIf="disabled">
  <div class="included">
    <kendo-label style="color: #424242;">
      included
    </kendo-label>
    <div *ngFor="let item of value">
      <div *ngIf="item" class="item node hidden" [ngClass]="{'item disabled': disabled}">
        <p style="font-size: 1rem; font-weight: 500; color: black; text-align: left;"> {{
          friendlyName(item.id).caption}}
        </p>
        <div class="row">
          <p *ngIf="options?.control?.caption" style="margin-right: 0.5rem; width: 50%;"> {{ options.control.caption
            }} </p>
          <kendo-textbox [(ngModel)]="item.value" [disabled]="disabled">
          </kendo-textbox>
          <button kendoButton (click)="drop(item)" [disabled]="disabled"> delete </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="available">
    <h5>Available</h5>
    <div *ngFor="let item of  available">
      <div *ngIf="item" class="item node-b" [ngClass]="{'item disabled': disabled}">
        {{ friendlyName(item).caption}} <button kendoButton (click)="add(item)" [disabled]="disabled"> Add </button>
      </div>
    </div>
  </div> -->
</div>
