import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { State } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';
import { ActionService } from 'src/app/services/action-service';
import { AssetApiService } from 'src/app/services/asset-api.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'ui-assetselector',
  templateUrl: './assetselector.component.html',
  styleUrls: ['./assetselector.component.scss'],
})
export class UiAssetSelectorComponent
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, OnDestroy
{
  @ViewChild('selectView') selectView: TemplateRef<any>;
  @ViewChild('button') button: TemplateRef<any>;


  private searchSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  @ViewChild('binding') textbox: TextBoxComponent;
  selectedAsset = '';
  @Input() options: any;

  public treeData: any;
  public asset: any;


  public templateValue = [];

  public gridData: GridDataResult = {
    data: [],
    total: 0,
  };

  public pageSize = 10;
  public skip = 0;

  public search = '';

  @Input() disabled: boolean;
  @Input() isCreate: boolean;

  public changes: any = {};
  public dialogRef: DialogRef;

  private validTemplateValue = this.templateValue.length > 0;

  public formGroup: FormGroup;

  onChange: any = () => {
    console.log('changed');
    this.ngControl.control.markAllAsTouched();
  };
  onTouched: any = () => {};
  discloseValidatorChange = () => {}; // Called on a validator change or re-validation;

  constructor(
    public actionService: ActionService,
    public assetApi: AssetApiService,
    public modalService: DialogService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.formGroup = new FormGroup({
      templateValue: new FormControl([], Validators.required)
    });
    this.formGroup.valueChanges.subscribe({next: (v) => {
      this.next();
    }})
  }
  ngOnDestroy(): void {}
  ngOnInit(): void {
    this.formGroup.controls.templateValue.setValue(this.options['allowed-templates']);
    this.ngControl.control.updateValueAndValidity();
    this.searchSubject$.subscribe({
      next: (n) => {
        this.find();
      },
    });
    this.searchSubject$.next(true);
  }

  ngAfterViewInit(): void {
    // this.openModel(this.selectView, this.button);
  }
  ngOnChanges(changes: SimpleChanges): void {}

  openModel(template: TemplateRef<any>, btn: TemplateRef<any>): void {
    this.loadTree();
    this.dialogRef = this.modalService.open({
      title: 'Select asset',
      content: template,
      // width: '80vw',
      actions: btn
    });
  }

  onNodeClick(e: any) {
    console.log(e);
    this.asset = {
      parentUUID: e.item.dataItem.parentUUID,
      uuid: e.item.dataItem.uuid,
      caption: e.item.dataItem.caption,
    };
    this.skip = 0;
    this.searchSubject$.next(true);
  }

  next(): void {
    if (this.formGroup.controls.templateValue.valid) {
      this.searchSubject$.next(true)
    }
  }
  pageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.searchSubject$.next(true);
  }

  find(): void {

    var x = [];
    if (Array.isArray(this.formGroup.controls.templateValue.value)) {
      this.formGroup.controls.templateValue.value.forEach(val => {
        x.push(val.template);
      });
    }


    this.assetApi
      .getAssetQuery(
        'sim4u-test',
        this.skip / this.pageSize + 1,
        this.pageSize,
        this.asset?.uuid,
        this.search,
        x,
      )
      .subscribe({
        next: (v) => {
          this.gridData.data = v.assets;
          this.gridData.total = v['total-count'];
        },
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  loadTree(): void {
    this.assetApi.getTreeQuery().subscribe({
      next: (v) => {
        this.treeData = v;
      },
    });
  }

  validateTemplates() {

  }

  selectRow(e: any) {
    console.log(e);
    this.search = '';
    this.selectedAsset = e.uuid;
    this.ngControl.control.setValue(this.selectedAsset);
    this.ngControl.control.markAllAsTouched();

    this.closeModal();
  }

  writeValue(value: any): void {
    console.log('write value func', value);
    if (value !== undefined) {
      this.selectedAsset = value;
    } else {
      this.selectedAsset = '';
    }

    console.log('write value', this.ngControl.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    console.log(this.disabled);
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
  public onStateChange(state: State): void {
    console.log('write value', state);
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.discloseValidatorChange = fn;
  }
}
