import { TableColumnDefinition } from '../models/table';
import { GridSettings } from '../shared/table';

export const CharonGridSettings: GridSettings = {
  state: {
    skip: 0,
    take: 5,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: '',
        dir: 'asc',
      },
    ],
  },
  // gridData: undefined,
  columnsConfig: [
    {
      field: 'id',
      title: 'ID',
      filterable: false,
      _width: 60,
      hidden: false,
    },
    {
      field: 'iccid',
      title: 'Iccid',
      filter: 'text',
      filterable: true,
      _width: 100,
      hidden: true,
    },
    {
      field: 'started',
      title: 'Started Timestamp',
      filter: 'date',
      filterable: false,
      _width: 240,
      hidden: true,
    },
    {
      field: 'created_at',
      title: 'Created Timestamp',
      filter: 'date',
      filterable: false,
      _width: 240,
      hidden: true,
    },
    {
      field: 'completed',
      title: 'Completed TimeStamp',
      filter: 'date',
      filterable: false,
      _width: 240,
      hidden: true,
    },
    {
      field: 'action',
      title: 'Action',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'client_type',
      title: 'Client Type',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'instance_name',
      title: 'Instance',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },

  ],
};
