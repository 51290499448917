<!-- <div style="width: 100%; max-height: 100;">
  <ngx-json-viewer [json]="data"></ngx-json-viewer>
</div> -->

<div class="fit">

  <kendo-card>
    <kendo-card-header>
      <h3> HLR Instances </h3>
    </kendo-card-header>
    <kendo-card-actions>

    </kendo-card-actions>
    <kendo-grid [data]="data">
      <kendo-grid-column field="name" title="Name"> </kendo-grid-column>
      <kendo-grid-column field="type" title="Type"> </kendo-grid-column>
      <kendo-grid-column field="connection_string" title="Connection String">
      </kendo-grid-column>
      <div *kendoGridDetailTemplate="let dataItem">
        <ngx-json-viewer [json]="dataItem.defaults"></ngx-json-viewer>
        <!-- <category-details [category]="dataItem.defaults"></category-details> -->
      </div>
    </kendo-grid>


  </kendo-card>

</div>
