import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryAxisNotesLabelComponent } from '@progress/kendo-angular-charts';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  ColumnReorderEvent,
  ColumnVisibilityChangeEvent,
  GridComponent,
  GridDataResult, PagerSettings, ColumnResizeArgs
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { TicketGridSettings } from 'src/app/config/ticket';
import { StatePersistingService } from 'src/app/services/grid-state.service';
import { ModalService } from 'src/app/services/modal.service';
import {
  MessageCreate,
  TicketApiService,
} from 'src/app/services/ticket-api.service';
import { GridSettings } from 'src/app/shared/table';

@Component({
  selector: 'ui-ticket-browser',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TicketBrowserComponent implements OnInit, OnDestroy, OnChanges {
  public loading = false;
  public Subj$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public sub: Subscription;
  public ref: DialogRef;

  public config: PagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [20, 50, 100, 500],
    previousNext: true,
    position: 'bottom',
  };
  private now: Date = new Date();
  private lastMonth: Date = new Date();
  public useRange = false;
  public range = { start: this.lastMonth, end: this.now };

  // detail view
  public detailData: any;
  public users: any;

  // state
  public gridSettings: any;
  public filterUnsused: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  // gridData
  public gridData: GridDataResult = {
    data: [],
    total: 0,
  };

  // pagination
  // TODO: should rework from params
  public pageSize = 100;
  public skip = 0;

  // router
  public routerSub$: Subscription;

  // Create ticket form
  public form: FormGroup;

  public formData: any = {
    title: '',
    reference: '',
    message: '',
    belongs_to_asset_id: '',
    ticket_type: 'inquiry',
  };

  public newMessage: MessageCreate = {
    internal: false,
    message: '',
    notifySubscribers: true,
    visible: true,
    draft: false,
  };

  constructor(
    private ticketApi: TicketApiService,
    private router: Router,
    private ar: ActivatedRoute,
    public dialogService: DialogService,
    public persistingService: StatePersistingService,
    public dialog: ModalService
  ) {
    var gridSettings: GridSettings =
      this.persistingService.get('grid') || TicketGridSettings;

    if (
      gridSettings.columnsConfig.length !=
      TicketGridSettings.columnsConfig.length
    ) {
      gridSettings = TicketGridSettings;
    }

    if (gridSettings !== null) {
      this.gridSettings = this.mapGridSettings(gridSettings);
    }

    this.lastMonth.setMonth(0);
    this.now.setMonth(1);


    // form to create tickets
    this.setForm();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.sub.unsubscribe();
    this.ngOnInit();
  }

  public get savedStateExists(): boolean {
    return !!this.persistingService.get('grid');
  }

  ngOnDestroy(): void {
    if (!!this.Subj$) {
      this.Subj$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.sub = this.Subj$.subscribe((x) => {
      this.loading = true;
      var ff: Map<string, string> = new Map<string, string>();
      ff.set(
        'belongs_to_asset_id',
        'eq#' + this.ar.snapshot.data.data.action.id || ''
      );
      this.gridSettings.state?.filter?.filters.forEach((f: any) => {
        console.log(f);
        ff.set(f.field, `${f.operator}#${f.value}`);
      });

      ff.set('sortArray', JSON.stringify(this.gridSettings.state?.sort));
      if (this.useRange) {
        ff.set('period', JSON.stringify(this.range));
      }

      this.ticketApi
        .listTickets(this.skip / this.pageSize + 1, this.pageSize, ff)
        .subscribe({
          next: (data) => {
            console.log("GRID DATA CALLED")
            this.gridData = {
              data: data.records,
              total: data.total_records,
            };
          },
          error: (error) => {
            console.log(error);
          },
          complete: () => {
            this.loading = false;
          },
        });
    });
  }

  public next(): void {
    this.loading = true;
    this.Subj$.next(true);
  }

  public loadDetail(data: any): void {
    forkJoin({
      ticket: this.ticketApi.getTicket(data.id),
      messages: this.ticketApi.getTicketMessages(data.id),
      users: this.ticketApi.getTicketUsers(data.id),
    }).subscribe((data) => {
      this.detailData = {
        ticket: data.ticket,
        messages: data.messages,
        users: data.users,
      };
      this.users = data.users;
    });
  }

  public openDialogWindow(
    data: any,
    template: TemplateRef<any>,
    actions: TemplateRef<any>
  ): void {
    console.log(event);
    if (data.id) {
      this.loadDetail(data);
    }

    this.ref = this.dialogService.open({
      title: 'Detail view',
      content: template,
      height: '80vh',
      width: '80vw',
      actions: actions,
    });
  }
  public closeDialog() {
    this.ref.close();
  }
  public dataStateChange(state: State): void {
    console.log('state', state);
    this.gridSettings.state = state;
    this.pageSize = state.take;
    this.skip = state.skip;
    this.next();
  }

  public saveGridSettings(grid: GridComponent): void {
    const columns = grid.columns;
    //add only the required column properties to save local storage space
    const gridConfig = {
      state: this.gridSettings.state,
      columnsConfig: this.gridSettings.columnsConfig,
    };

    this.persistingService.set('grid', gridConfig);
  }

  public mapGridSettings(gridSettings: GridSettings): GridSettings {
    const state = gridSettings.state;
    this.mapDateFilter(state?.filter);

    return {
      state,
      columnsConfig: gridSettings.columnsConfig?.sort(
        (a: any, b: any) => a.orderIndex - b.orderIndex
      ),
    };
  }

  private mapDateFilter = (descriptor: any) => {
    const filters = descriptor.filters || [];

    filters.forEach((filter: any) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === 'FirstOrderedOn' && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };

  public resetFilters() {
    this.useRange = false;
    this.gridSettings.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.next();
  }

  public saveStateResize(e: Array<ColumnResizeArgs>, grid: GridComponent) {
    console.log(e, this.gridSettings.columnsConfig);

    for (let col of this.gridSettings.columnsConfig) {
      for (let ecol of e) {
        if (ecol.column['field'] == col.field) {
          col['_width'] = ecol.newWidth;
          console.log('updated', col);
        }
      }
    }
    console.log(e, this.gridSettings.columnsConfig);

    this.saveGridSettings(grid);
  }

  public saveState(e: ColumnReorderEvent, grid: GridComponent) {
    console.log(this.gridSettings.columnsConfig);

    this.gridSettings.columnsConfig = this.moveInArray(
      this.gridSettings.columnsConfig,
      e.oldIndex,
      e.newIndex
    );
    console.log(this.gridSettings.columnsConfig);

    this.saveGridSettings(grid);
  }
  public saveStateColumVisible(
    e: ColumnVisibilityChangeEvent,
    grid: GridComponent
  ): void {
    console.log('columns to change', e, this.gridSettings.columnsConfig);

    for (let col of this.gridSettings.columnsConfig) {
      for (let ecol of e.columns) {
        if (ecol['field'] == col.field) {
          col.hidden = ecol.hidden;
        }
      }
    }
    console.log(this.gridSettings.columnsConfig);

    this.saveGridSettings(grid);
  }
  moveInArray(arr: any, from: number, to: number) {
    // Make sure a valid array is provided
    if (Object.prototype.toString.call(arr) !== '[object Array]') {
      throw new Error('Please provide a valid array');
    }

    // Delete the item from it's current position
    var item = arr.splice(from, 1);

    // Make sure there's an item to move
    if (!item.length) {
      throw new Error('There is no item in the array at index ' + from);
    }

    // Move the item to its new position
    arr.splice(to, 0, item[0]);
    return arr;
  }

  public createMessage() {
    console.log(this.detailData.ticket);
    const ticket = this.detailData.ticket;
    this.ticketApi.createMessage(this.newMessage, ticket.id).subscribe(
      (data) => {
        this.newMessage = {
          internal: false,
          message: '',
          notifySubscribers: true,
          visible: true,
          draft: false,
        };
        this.loadDetail(this.detailData.ticket);
      },
      (error) => {}
    );
  }

  // create tickets
  public setForm(): void {
    this.form = new FormGroup({
      title: new FormControl(this.formData.title, [Validators.required]),
      reference: new FormControl(this.formData.reference, [
        Validators.required,
      ]),
      message: new FormControl(this.formData.message, [Validators.required]),
      belongs_to_asset_id: new FormControl(
        this.ar.snapshot.data.data.action.id
      ),
      ticket_type: new FormControl(this.formData.ticket_type, [
        Validators.required,
      ]),
    });
    this.form.controls.belongs_to_asset_id.disable();
  }

  createNewTicket(): void {
    console.log(this.form.value);
    this.ticketApi.createTicket(this.form.getRawValue()).subscribe({
      next: (n) => {
        this.next();
        this.dialog.windowRef.close();
        this.setForm();
      },
      error(e): void {},
      complete: () => {
        this.dialog.windowRef.close();
      },
    });
  }
  // end
}
