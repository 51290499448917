<!-- <div class="rounded">
  <p class="label"> Reference </p>
  <p class="name"> {{ticket.reference }} </p>
  <p class="label"> Status </p>
  <p class="name"> {{ticket.status }} </p>
  <p class="label"> Type </p>
  <p class="name"> {{ticket.ticket_type }} </p>
  <p class="label"> Title </p>
  <p class="name"> {{ticket.title }} </p>
  <p class="label"> Last action </p>
  <p class="name"> {{ticket.last_action | timeago:'live' }} </p>
</div> -->


<form class="k-form" [formGroup]="form">

  <kendo-formfield>
    <kendo-label [for]="reference" text="Reference"></kendo-label>
    <kendo-textbox formControlName="reference" [clearButton]="false" #reference required></kendo-textbox>
    <kendo-formerror>Error: Reference is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="status" text="Status"></kendo-label>
    <kendo-dropdownlist formControlName="status" #status required textField="status" [data]="statuses"
      [defaultItem]="form.controls.status.value">
    </kendo-dropdownlist>
    <!-- <kendo-formhint>Changing status will update the Last Action field</kendo-formhint> -->

    <kendo-formerror>Error: status is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="level" text="Level"></kendo-label>
    <kendo-dropdownlist formControlName="level" #level required textField="levels" [data]="levels"
      [defaultItem]="form.controls.level.value">
    </kendo-dropdownlist>
    <!-- <kendo-formhint>Changing level will update the Last Action field</kendo-formhint> -->

    <kendo-formerror>Error: status is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="ticket_type" text="Type"></kendo-label>
    <kendo-dropdownlist formControlName="ticket_type" #ticket_type required textField="ticket_type" [data]="types"
      [defaultItem]="form.controls.ticket_type.value">
    </kendo-dropdownlist>
    <!-- <kendo-formhint>Changing type will update the Last Action field</kendo-formhint> -->

    <kendo-formerror>Error: type is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="title" text="Title"></kendo-label>
    <kendo-textbox formControlName="title" [clearButton]="false" #title required></kendo-textbox>
    <kendo-formerror>Error: title is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="last_action" text="Last Action"></kendo-label>
    <kendo-textbox formControlName="last_action" [clearButton]="false" #last_action required></kendo-textbox>
  </kendo-formfield>
  <kendo-formfield *ngIf="getMode('ALL')">
    <button kendoButton (click)="openDialog('Re Choose Asset', rechooseAsset, null)">Re Choose Asset</button>
  </kendo-formfield>
  <kendo-formfield *ngIf="getMode('ALL')">
    <kendo-label [for]="belongs_to_asset_id" text="Asset ID"></kendo-label>
    <kendo-textbox formControlName="belongs_to_asset_id" [clearButton]="false" #belongs_to_asset_id required>
    </kendo-textbox>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="is_escalated_since" text="Escalated Since"></kendo-label>
    <kendo-textbox formControlName="is_escalated_since" [clearButton]="false" #is_escalated_since required>
    </kendo-textbox>

  </kendo-formfield>
  <kendo-formfield>
    <!-- <kendo-label [for]="solver" text="Solver"></kendo-label> -->
    <!-- <kendo-textbox formControlName="solver" [clearButton]="false" #solver required [disabled]="true">
    </kendo-textbox> -->
    <button kendoButton (click)="openDialog('Assign ticket', solverView, null)">Assign ticket</button>

  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="solver_email" text="Assignee Email"></kendo-label>
    <kendo-textbox formControlName="solver_email" [clearButton]="false" #solver_email required [disabled]="true">
    </kendo-textbox>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="solver_name" text="Assignee Name"></kendo-label>
    <kendo-textbox formControlName="solver_name" [clearButton]="false" #solver_name required [disabled]="true">
    </kendo-textbox>
  </kendo-formfield>

  <button kendoButton *ngIf="form.dirty && form.valid" (click)="submit()" [themeColor]="'success'"> Save </button>

</form>


<ng-template #solverView>
  <ui-user-form-browser [internal]="'true'" (selected)="setSolver($event)"></ui-user-form-browser>
</ng-template>

<ng-template #rechooseAsset>
  <app-asset-browser (selected)="resetAsset($event); dialogService.closeDialog()"></app-asset-browser>
</ng-template>
