import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { BaseComponent } from './crm/base.component';
import { CanDeactivateGuard } from './guards/candeactivate.guard';
import { AuthGuard } from './guards/keycloak';
import { ErrorPageComponent } from './status/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'oops',
    component: ErrorPageComponent,
    // canActivate:[ AuthGuard]
  },
  {
    path: 'audit',
    loadChildren: () =>
      import('./audit/audit.module').then((m) => m.AuditModule),
    canActivate: [AuthGuard],
  },
];
export const PORTAL_MODE = environment.portalMode;
// Load modules based on enviroment
function LoadRoutes(): void {
  if (PORTAL_MODE === 'ALL' || PORTAL_MODE === 'HELPDESK_ONLY') {
    routes.push({
      path: 'helpdesk',
      loadChildren: () =>
        import('./helpdesk/helpdesk.module').then((m) => m.HelpdeskModule),
      canActivate: [AuthGuard],
    });
  }
  if (
    PORTAL_MODE === 'ALL' ||
    PORTAL_MODE === 'CRM_ONLY' ||
    PORTAL_MODE === 'CRM_DASHBOARD_ONLY'
  ) {
    routes.push({
      path: 'crm',
      loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
      canActivate: [AuthGuard],
      component: BaseComponent,
    });
  }
  if (
    PORTAL_MODE === 'ALL' ||
    PORTAL_MODE === 'DASHBOARD_ONLY' ||
    PORTAL_MODE === 'CRM_DASHBOARD_ONLY'
  ) {
    routes.push({
      path: 'dashboard',
      loadChildren: () =>
        import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      canActivate: [AuthGuard],
    });
  }

  if (PORTAL_MODE === 'ALL') {
    routes.push({
      path: 'files',
      loadChildren: () =>
        import('./files/files.module').then((m) => m.FilesModule),
      canActivate: [AuthGuard],
    });
  }
  // SET REDIRECT PATHS
  switch (PORTAL_MODE) {
    case 'ALL':
      routes.push({
        path: '',
        redirectTo: '/crm',
        pathMatch: 'full',
      });
      break;

    case 'CRM_ONLY':
      routes.push({
        path: '',
        redirectTo: '/crm',
        pathMatch: 'full',
      });
      break;
    case 'CRM_DASHBOARD_ONLY':
      routes.push({
        path: '',
        redirectTo: '/crm',
        pathMatch: 'full',
      });
      break;

    case 'HELPDESK_ONLY':
      routes.push({
        path: '',
        redirectTo: '/helpdesk/overview',
        pathMatch: 'full',
      });
      routes.push({
        path: 'helpdesk',
        redirectTo: '/helpdesk/overview',
        pathMatch: 'full',
      });
      break;

    case 'DASHBOARD_ONLY':
      routes.push({
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      });
      break;
    default:
      break;
  }
}
LoadRoutes();

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [CanDeactivateGuard],
})
export class AppRoutingModule {}
