import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ModalService } from 'src/app/services/modal.service';
import { TicketApiService } from 'src/app/services/ticket-api.service';
import { getChanges } from 'src/app/shared/changes';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, AfterViewInit {
  @ViewChild('combo') combo: ComboBoxComponent;
  public data: Array<any>;

  public chooseAsset = false;
  private createAction = false;
  public disabled = false;

  public form: FormGroup;

  @Output() submitted = new EventEmitter<boolean>(false);

  @Input() formData: any;

  constructor(
    public api: TicketApiService,
    private modal: ModalService,
    private router: Router,
    private ar: ActivatedRoute
  ) {}
  ngAfterViewInit(): void {
    const contains = (value) => (s) =>
    s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1;

    this.combo.filterChange
    .asObservable()
      .subscribe((x) => {
      this.combo.loading = true;
      const search: Map<string, string> = new Map();
      search.set('name', 'contains#' + x);
      console.log(x);
      this.api.listOrganisations(1, 20, search).subscribe({next: (n) => {
        this.data = n.records;
      }});
      this.combo.loading = false;
    });
  }

  isMode(mode: string): boolean {
    return  environment.portalMode === mode;
  }

  ngOnInit(): void {
    console.log(this.formData);
    if (!this.formData) {
      console.log('create empty form');
      this.createAction = true;
      this.formData = {
        name: '',
        email: '',
        opt_out: false,
        identity: '',
        role: '',
        source: 'PORTAL',
        belongs_to_asset_id: '',
        internal: false,
      };
      this.api.listOrganisations(1, 20).subscribe({next: (n) => {
        this.data = n.records;
      }});
    }

    this.form = new FormGroup({
      name: new FormControl(this.formData.name, [
        Validators.required,
        Validators.minLength(3),
      ]),
      email: new FormControl(this.formData.email, [Validators.email]),
      opt_out: new FormControl(this.formData.opt_out, []),
      identity: new FormControl(this.formData.identity, []),
      role: new FormControl(this.formData.role, []),
      source: new FormControl(this.formData.source, [Validators.required]),
      belongs_to_asset_id: new FormControl(
        this.formData.belongs_to_asset_id,
        []
      ),
      internal: new FormControl(this.formData.internal, []),
    });
    if (this.isMode('HELPDESK_ONLY')){
      this.form.addControl('organisation_id', new FormControl('', Validators.required));
    }
  }

  setchoosed(e: any) {
    console.log(e);
    this.form.patchValue({ belongs_to_asset_id: e }, { emitEvent: true });
    this.form.controls.belongs_to_asset_id.markAsDirty();
    this.form.controls.belongs_to_asset_id.markAsTouched();
  }

  public get isCreate(): boolean {
    return this.createAction;
  }

  create() {
    this.disabled = true;
    console.log(this.form.getRawValue());

    const changes = getChanges(this.form);

    switch (this.createAction) {
      case true:
        if (this.form.invalid) {
          return;
        }
        console.log(changes);
        this.api.createUsers(changes).subscribe({
          next: (n) => {
            this.router.navigate([], {
              relativeTo: this.ar,
              queryParams: { updated: n.id },
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
            this.submitted.next(true);
            this.modal.closeWindow();
            this.disabled = false;
          },
          error: (e) => {
            this.disabled = false;
          }
        });
        break;

      default:
        console.log(changes);
        this.api.updateUserPartial(this.formData.id, changes).subscribe({
          next: (n) => {
            this.router.navigate([], {
              relativeTo: this.ar,
              queryParams: { updated: n.id },
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
            this.submitted.next(true);
            this.modal.closeWindow();
            this.disabled = false;
          },
          error: (e) => {
            this.disabled = false;
          }
        });
        break;
    }
  }
}
