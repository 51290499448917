<kendo-grid
[data]="value"
id="inlineGrid"
(cellClick)="cellClickHandler($event)"
(add)="addHandler()"
>
<ng-template kendoGridToolbarTemplate *ngIf="(editable | async)">
  <button kendoGridAddCommand *ngIf="!formGroup && (editable | async)">Add new</button>
  <div *ngIf="formGroup && ( editable | async ) == true">
    <button kendoButton [disabled]="!formGroup.valid" (click)="saveRow()">
      Ok
    </button>
    <button kendoButton themeColor="primary" (click)="cancelHandler()">
      Cancel
    </button>
  </div>
</ng-template>

<kendo-grid-column *ngFor="let col of columns"
  [field]="col.field"
  [title]="col.caption"
  [format]="col.format"
  editor="{{convertType(col.type)}}"
>
<ng-template  kendoGridEditTemplate let-dataItem="dataItem" *ngIf="col.disabled === true">
    {{ dataItem[col.field] }}
</ng-template >

<!-- dropdown list -->
<ng-template  kendoGridEditTemplate let-dataItem="dataItem" *ngIf="col.disabled !== true && col.type === 'dropdownlist' && isNew">
  <kendo-dropdownlist [data]="col.options" [(ngModel)]="dataItem[col.field]" > </kendo-dropdownlist>
</ng-template >

<ng-template  kendoGridEditTemplate let-dataItem="dataItem" *ngIf="col.disabled !== true && col.type === 'dropdownlist' && !isNew">
 <form class="k-form" [formGroup]="formGroup">
  <kendo-formfield>
    <kendo-label [for]="col.field"></kendo-label>
    <kendo-dropdownlist #col.field [formControlName]="col.field" [data]="col.options">
    </kendo-dropdownlist>
  </kendo-formfield>
</form>

</ng-template >
<ng-template  kendoGridCellTemplate let-dataItem="dataItem" *ngIf="col.type === 'dropdownlist'">
  {{ dataItem[col.field] }}
</ng-template >


</kendo-grid-column>
<kendo-grid-column [sticky]="true" width="100px" *ngIf="(editable | async)">
  <ng-template kendoGridEditTemplate="" let-dataItem>
    <button kendoButton themeColor="warning" >
      <i class="fa fa-trash" aria-hidden="true" (click)="deleteRow(dataItem)"></i>
    </button>
  </ng-template>
</kendo-grid-column>

</kendo-grid>
