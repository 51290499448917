<div style="width: 100%; display: flex; justify-content: center; margin-top: 2rem;">
  <kendo-card class="k-hstack" style="width: 300px;">
    <kendo-card-header>Waiting for your download</kendo-card-header>
    <kendo-card-body>
      <kendo-loader *ngIf="loading"
      [type]="'pulsing'"
      [themeColor]="'secondary'"
      [size]="'large'"
    >
    </kendo-loader>
    </kendo-card-body>
    <kendo-card-footer *ngIf="loading">
      <button kendoButton *ngFor="let btn of onWaitButtons" (click)="actionButtonClick(btn)"> {{ btn.caption }}</button>
    </kendo-card-footer>
    <!-- <kendo-card-footer *ngIf="!loading">
      <button kendoButton *ngFor="let btn of onWaitButtons"> {{ btn.caption }}</button>
    </kendo-card-footer> -->
  </kendo-card>
</div>

