import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
  Router,
} from '@angular/router';

@Injectable({
  providedIn: 'any',
})
export class QueryParamService {
  constructor(private router: Router, private activateRoute: ActivatedRoute) {}

  public GetCurrent(): ParamMap {
    return this.activateRoute.snapshot.queryParamMap;
  }

  public GetParam(s: string): any {
    if (this.activateRoute.snapshot.queryParamMap.has(s)) {
      return this.activateRoute.snapshot.queryParamMap.getAll(s);
    }
    return '';
  }

  public DropParam(s: string): boolean {
    if (this.activateRoute.snapshot.queryParamMap.has(s)) {
      const x: any = {};
      x[s] = null;

      this.router.navigate([], {
        relativeTo: this.activateRoute,
        queryParams: {
          ...x
        },
        queryParamsHandling: 'merge',
      });

      return true;
    }
    return false;
  }

  public InitPagination(ops?: Map<string, any>): PaginationState {
    console.log('INIT PAGINATION');
    const r: PaginationState = {
      currentPage: '1',
      pageSize: '20',
    };

    if (this.activateRoute?.snapshot?.queryParamMap.has('currentPage')) {
      r.currentPage =
        this.activateRoute.snapshot.queryParamMap.get('currentPage');
    } else {
      this.SetPagination(r, ops);
    }
    if (this.activateRoute?.snapshot?.queryParamMap.has('pageSize')) {
      r.pageSize = this.activateRoute.snapshot.queryParamMap.get('pageSize');
    } else {
      this.SetPagination(r, ops);
    }
    return r;
  }

  public SetPagination(
    pagination: PaginationState,
    ops?: Map<string, any>
  ): void {
    console.log('SET PAGINATION CALLED');
    let o: any;
    if (ops) {
      // console.log('set pagination with ops', Object.fromEntries(ops));
      o = Object.fromEntries(ops);
    }

    this.router.navigate([], {
      relativeTo: this.activateRoute,
      queryParams: {
        currentPage: pagination.currentPage,
        pageSize: pagination.pageSize,
        ...o,
      },
      queryParamsHandling: 'merge',
      preserveFragment: false,
    });
  }

  public ConvertPaginationState(pagination: PaginationState): GridPagination {
    const r: GridPagination = {
      skip: (Number(pagination.currentPage) - 1) * Number(pagination.pageSize),
      take: Number(pagination.pageSize),
    };
    return r;
  }
  public ConvertGridPagination(pagination: GridPagination): PaginationState {
    const r: PaginationState = {
      currentPage: Number(pagination.skip / pagination.take + 1).toFixed(),
      pageSize: pagination.take.toFixed(),
    };
    return r;
  }
}

export interface PaginationState {
  currentPage: string;
  pageSize: string;
}

export interface GridPagination {
  skip: number;
  take: number;
}
