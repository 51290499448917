import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ExportModel } from '../models/export';

@Injectable({
  providedIn: 'root',
})
export class TicketApiService {
  public basePath = `${environment.ticket.api}`;

  constructor(private http: HttpClient) {}

  // TICKETS
  listTickets(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/tickets`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  getTicket(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/tickets/${id}`, {});
  }

  createTicket(ticket: TicketCreate, template?: any): Observable<any> {
    Object.keys(ticket).forEach((key) => {
      if (ticket[key] === '') {
        delete ticket[key];
      }
    });
    return this.http.put<any>(`${this.basePath}/tickets`, {
      ...ticket,
      meta: template,
    });
  }

  updateTicketPartiel(id: string, fields: Map<string, any>): Observable<any> {
    return this.http.post<any>(`${this.basePath}/tickets/${id}`, {
      ...fields,
    });
  }
  // updateTicketPartial(id: string, fields: Map<string, any>): Observable<any> {
  //   return this.http.patch('', {...fields})
  // }

  // MESSAGES
  listMessages(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/tickets/${id}/messages`, {});
  }

  getMessages(ticketID: string, messageID: string): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/tickets/${ticketID}/messages/${messageID}`,
      {}
    );
  }

  createMessage(body: MessageCreate, ticketID: string): Observable<any> {
    return this.http.put<any>(`${this.basePath}/tickets/${ticketID}/messages`, {
      notify_subscribers: body.notifySubscribers,
      visible: body.visible,
      internal: body.internal,
      message: body.message,
      draft: body.draft,
    });
  }
  // update message not implemtend frontend.

  updateMessagePartial(
    ticketID: string,
    messageID: string,
    fields: Map<string, string>
  ): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/tickets/${ticketID}/messages/${messageID}`,
      { ...fields }
    );
  }

  // USERS

  listUsers(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/users`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  getUser(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/users/${id}`, {});
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/users/${id}`, {});
  }

  deleteTicket(id: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/tickets/${id}`, {});
  }

  deleteMessage(messageId: string, ticketId: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/tickets/${ticketId}/messages/${messageId}`, {});
  }

  listUserTickets(
    id: string,
    currentPage: string,
    pageSize: string
  ): Observable<any> {
    return this.http.get<any>(`${this.basePath}/users/${id}/tickets`, {
      params: new HttpParams().appendAll({
        currentPage,
        pageSize,
      }),
    });
  }
  addUserTicket(id: string, users: string[]): Observable<any> {
    return this.http.post<any>(`${this.basePath}/tickets/${id}/users`, {
      users,
    });
  }

  createUsers(form: any): Observable<any> {
    return this.http.put<any>(`${this.basePath}/users`, { ...form });
  }

  updateUserPartial(id: string, fields: Map<string, any>): Observable<any> {
    return this.http.post<any>(`${this.basePath}/users/${id}`, { ...fields });
  }

  // roles ar non paginated
  listRoles(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/roles`, {
      params: new HttpParams(),
    });
  }

  // STATUS
  listStatuses(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/statuses`, {
      params: new HttpParams(),
    });
  }
  createStatus(name: any): Observable<any> {
    return this.http.put<any>(`${this.basePath}/statuses`, { ...name });
  }

  listTypes(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/types`, {
      params: new HttpParams(),
    });
  }
  createType(name: any): Observable<any> {
    return this.http.put<any>(`${this.basePath}/types`, { ...name });
  }

  listLevels(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/levels`, {
      params: new HttpParams(),
    });
  }
  createLevels(name: any): Observable<any> {
    return this.http.put<any>(`${this.basePath}/levels`, { ...name });
  }

  // POLICIES

  listPolicies(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/policies`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  getPolicie(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/policies/${id}`, {});
  }

  createPolicie(ticket: TicketCreate): Observable<any> {
    return this.http.put<any>(`${this.basePath}/policies`, { ticket });
  }

  updatePoliciePartial(id: string, fields: Map<string, any>): Observable<any> {
    return this.http.patch<any>(`${this.basePath}/policies/${id}`, {
      ...fields,
    });
  }

  // CUSTOM
  getTicketUsers(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/tickets/${id}/users`, {
      params: new HttpParams().appendAll({
        currentPage: 1,
        pageSize: 100,
      }),
    });
  }
  getTicketMessages(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/tickets/${id}/messages`, {
      params: new HttpParams().appendAll({
        currentPage: 1,
        pageSize: 100,
      }),
    });
  }

  // escalation

  listEscalated(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/escalated`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  createEscalationRule(escalationRule: any): Observable<any> {
    Object.keys(escalationRule).forEach((key) => {
      if (escalationRule[key] === '') {
        delete escalationRule[key];
      }
    });
    return this.http.put<any>(`${this.basePath}/policies`, {
      ...escalationRule,
    });
  }

  updateEscalationRulePartial(
    id: string,
    fields: Map<string, any>
  ): Observable<any> {
    return this.http.post<any>(`${this.basePath}/policie/${id}`, {
      ...fields,
    });
  }

  getEscalationRule(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/policie/${id}`, {});
  }

  deleteEscalationRule(id: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/policie/${id}`, {});
  }


  listMyself(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/myself`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }
  deEscalateTicket(id: string): Observable<any> {
    return this.http.put<any>(
      `${this.basePath}/escalated/${id}/deescalate`,
      {}
    );
  }

  escalateTicket(id: string): Observable<any> {
    return this.http.put<any>(
      `${this.basePath}/escalated/${id}/escalate`,
      {}
    );
  }

  public Convert(values: Map<string, string>): Object {
    const x = {};
    values.forEach((key, value) => {});

    return values.entries();
  }
  // META

  listMeta(
    currentPage: number,
    pageSize: number,
    ticketID: string,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/ticket-meta/${ticketID}/meta`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }
  getMeta(ticketID: string, metaID: string): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/ticket-meta/${ticketID}/meta/${metaID}`,
      {}
    );
  }

  updateMetaPartial(
    ticketID: string,
    metaID: string,
    fields: Map<string, any>
  ): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/ticket-meta/${ticketID}/meta/${metaID}`,
      {
        meta: fields,
      }
    );
  }
  getDefaultTemplate(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/default-template`, {});
  }

  // Email Config
  listEmailConfigs(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/email-config`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  getEmailConfig(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/email-config/${id}`, {});
  }

  createEmailConfig(config: any): Observable<any> {
    Object.keys(config).forEach((key) => {
      if (config[key] === '') {
        delete config[key];
      }
    });
    return this.http.put<any>(`${this.basePath}/email-config`, {
      ...config,
    });
  }

  updateEmailConfigPartion(
    id: string,
    fields: Map<string, any>
  ): Observable<any> {
    return this.http.post<any>(`${this.basePath}/email-config/${id}`, {
      ...fields,
    });
  }
  deleteEmailConfig(
    id: string
  ): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/email-config/${id}`, {
    });
  }

  getEmailConfigStatus(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.ticket.cron}/status/cron/${id}`,
      {}
    );
  }

  // attachments

  listTicketAttachments(ticketID: string): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/tickets/${ticketID}/attachments`,
      {
        params: new HttpParams(),
      }
    );
  }
  listTicketMessageAttachments(ticketID: string, messageID: string): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/tickets/${ticketID}/messages/${messageID}/attachments`,
      {
        params: new HttpParams(),
      }
    );
  }
  downloadAttachMent(ticketID: string, data: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/tickets/${ticketID}/attachments`,
      data,
      {
        params: new HttpParams(),
      }
    );
  }
  deleteAttachMent(ticketID: string, data: any): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/tickets/${ticketID}/attachments/delete`,
      data,
      {
        params: new HttpParams(),
      }
    );
  }

  uploadAttachment(ticketID: string, messageID: string, formData: FormData): Observable<any> {

    return this.http.put<any>(
      `${this.basePath}/tickets/${ticketID}/upload`,
      formData,
      {
        params: new HttpParams(),
      }
    );
  }
  uploadMessageAttachment(ticketID: string, messageID: string, formData: FormData): Observable<any> {

    return this.http.put<any>(
      `${this.basePath}/tickets/${ticketID}/messages/${messageID}/upload`,
      formData,
      {
        params: new HttpParams(),
      }
    );
  }

  // Organisations
  createOrganisation(organisation: any): Observable<any> {
    Object.keys(organisation).forEach((key) => {
      if (organisation[key] === '') {
        delete organisation[key];
      }
    });
    return this.http.put<any>(`${this.basePath}/organisations`, {
      ...organisation,
    });
  }

  listOrganisations(
    currentPage: number,
    pageSize: number,
    values?: Map<string, string>
  ): Observable<any> {
    let x = {};
    if (values) {
      x = Object.fromEntries(values);
    }

    return this.http.get<any>(`${this.basePath}/organisations`, {
      params: new HttpParams()
        .appendAll({
          currentPage: currentPage.toFixed().toString(),
          pageSize: pageSize.toFixed().toString(),
        })
        .appendAll(x),
    });
  }

  updateOrganisationPartial(
    id: string,
    fields: Map<string, any>
  ): Observable<any> {
    return this.http.post<any>(`${this.basePath}/organisation/${id}`, {
      ...fields,
    });
  }
  updateOrganisationSettingsPartial(
    id: string,
    fields: Map<string, any>
  ): Observable<any> {
    return this.http.post<any>(`${this.basePath}/organisation/${id}/settings`, {
      ...fields,
    });
  }

  getOrganisation(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/organisation/${id}`, {});
  }
  getOrganisationSettings(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/organisation/${id}/settings`, {});
  }

  deleteOrganisation(id: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/organisation/${id}`, {});
  }

}

export interface MessageCreate {
  notifySubscribers: boolean;
  visible: boolean;
  internal: boolean;
  draft: boolean;
  message: string;
}

export interface TicketCreate {
  title: string;
  reference: string;
  message: string;
  belongs_to_asset_id: string;
  ticket_type: string;
}

export interface UserCreate {
  name: string;
  email: string;
  opt_out: boolean;
}
