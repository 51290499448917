import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EditorResizableOptions } from '@progress/kendo-angular-editor';
import { ModalService } from 'src/app/services/modal.service';
import {
  MessageCreate,
  TicketApiService,
} from 'src/app/services/ticket-api.service';

@Component({
  selector: 'app-ticket-actions',
  templateUrl: './ticket-actions.component.html',
  styleUrls: ['./ticket-actions.component.scss'],
})
export class TicketActionsComponent implements OnInit, OnChanges {
  @Input() ticket: any;

  @Output('canDeactivate') canDeactivate = new EventEmitter<boolean>();

  public ticketID = '';

  public replyMessage = '';
  public notifySubscribers = true;
  public visible = false;
  public internal = false;
  public draft = false;

  public resizable: boolean | EditorResizableOptions = {
    minWidth: 800,
    minHeight: 150,
    maxWidth: 800,
    maxHeight: 450,
  };

  constructor(
    public dialogSerive: ModalService,
    private api: TicketApiService,
    private ar: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef

  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.ar.params.subscribe((x) => {
      this.ticketID = x.id;
    });
  }

  submitReply(): void {
    if (this.replyMessage == '') {
      this.dialogSerive.closeDialog();

      return;
    }
    this.api
      .createMessage(
        {
          notifySubscribers: this.notifySubscribers,
          visible: this.visible,
          internal: this.internal,
          message: this.replyMessage,
          draft: this.draft,
        },
        this.ticketID
      )
      .subscribe({
        next: (v) => {
          this.replyMessage = '';
          this.dialogSerive.closeDialog();
          this.router.navigate([], {
            relativeTo: this.ar,
            queryParams: { updated: v.created_at },
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
        },
        error: (e) => console.error(e),
        complete: () => {},
      });
  }

  openDialog(
    title: string,
    contentRef: TemplateRef<any>,
    actionsRef: TemplateRef<any>
  ) {
    this.dialogSerive.openDialog(title, contentRef, actionsRef, '100px');
  }

  setSelectedUserToAdd(e: any): void {
    console.log(e);
    this.api.addUserTicket(this.ticket.id, [e]).subscribe({
      next: (d) => {
        this.router.navigate([], {
          relativeTo: this.ar,
          queryParams: { updated: e },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
      },
    });
  }
  deescalate(): void {
    this.api.deEscalateTicket(this.ticket.id).subscribe({
      next: (d) => {
        this.router.navigate([], {
          relativeTo: this.ar,
          queryParams: { updated: new Date() },
          queryParamsHandling: 'merge',
        });
      },
    });
  }
  escalate(): void {
    this.api.escalateTicket(this.ticket.id).subscribe({
      next: (d) => {
        this.router.navigate([], {
          relativeTo: this.ar,
          queryParams: { updated: new Date() },
          queryParamsHandling: 'merge',
        });
      },
    });
  }

  viewAttachments() {
    this.router.navigate(['/helpdesk/tickets/overview/', this.ar.snapshot.params.id, 'attachments' ], {});
  }
}
