import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ActionService } from 'src/app/services/action-service';
import { AssetApiService } from 'src/app/services/asset-api.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  public data: any;

  public action = '';
  public id = '';
  public dirt = false;

  public routerSub: Subscription;

  canDeactivate(): Observable<boolean> | boolean {
    if (this.dirt) {
      const x = confirm('There are unsaved changes! Are you sure?');
      if (x) {
        this.dirt = false;
        return true;
      }
      return false;
    }
    return true;
  }
  deactivate(e: boolean) {
    this.dirt = e;
  }

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private api: AssetApiService,
    private actionHandler: ActionService
  ) {
    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.data = {};
        this.data = this.ar.snapshot.data.data || undefined;
        this.actionHandler.handleCallbackAction(this.data);
        if (this.data.action.type === '.GenerateTableExport') {
          window.open(this.data.action['download-url']);
        }
      }
      if (val instanceof ResolveEnd) {
        this.data = {};
        this.data = this.ar.snapshot.data.data || undefined;
        this.actionHandler.handleCallbackAction(this.data);
        if (this.data.action.type === '.GenerateTableExport') {
          window.open(this.data.action['download-url']);
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }

  onDirty(event?: boolean) {
    this.dirt = true;
  }

  ngOnInit(): void {}
}
