<kendo-card style="width: 100%;">
  <kendo-card-header>
    <h3> CDR Browser </h3>
  </kendo-card-header>
  <kendo-card-actions>
    <kendo-switch [(ngModel)]="useRange" (ngModelChange)="next()"></kendo-switch>
    <p>From</p>
    <kendo-datetimepicker [format]="'MM/dd/yyyy HH:mm'" [(value)]="range.start" (valueChange)="next()"
      [disabled]="!useRange"></kendo-datetimepicker>

    <p>Until</p>
    <kendo-datetimepicker [format]="'MM/dd/yyyy HH:mm'" [(value)]="range.end" (valueChange)="next()"
      [disabled]="!useRange"></kendo-datetimepicker>
      <button kendoButton (click)="resetFilters()">
        Reset Filters
      </button>
  </kendo-card-actions>
  <kendo-grid [data]="gridView" [skip]="skip" [pageable]="true" (pageChange)="pageChange($event)"
    (filterChange)="changedFilter($event)" [pageSize]="pageSize" [pageable]="config" [filterable]="true" [sortable]="{
      allowUnsort: allowUnsort,
      mode: multiple ? 'multiple' : 'single'
    }" [sort]="sort" (sortChange)="sortChange($event)" [filter]="filter" [scrollable]="'scrollable'" [resizable]="true"
    (cellClick)="openDialogWindow($event, detailView)" (columnVisibilityChange)="columnState($event)">

    <ng-template kendoGridToolbarTemplate>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-grid-column-chooser [scrollLeft]="true"></kendo-grid-column-chooser>
    </ng-template>

    <kendo-grid-column [width]="100" field="cdr_id" title="ID" [filterable]="false">

    </kendo-grid-column>
    <kendo-grid-column [width]="250" field="owner_uuid" title="Owner UUID" [filterable]="false" [columnMenu]="true" [hidden]="true">
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="type" title="Type" [sortable]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column" let-sort="sort">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="description" title="description" [sortable]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="a" title="a">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="b" title="b">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="c" title="c" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>

      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="d" title="d" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="billed" title="Billed">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-boolean-filter-cell [column]="column" [filter]="filter">
        </kendo-grid-boolean-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="exported" title="Exported">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-boolean-filter-cell [column]="column" [filter]="filter">
        </kendo-grid-boolean-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <!-- period -->
    <kendo-grid-column [width]="250" field="bill_timestamp" title="Billed Time" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      </ng-template>
    </kendo-grid-column>

    <!-- period -->
    <kendo-grid-column [width]="250" field="start_timestamp" title="Start Time" [hidden]="false">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">

      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" field="unit_name" title="Unit Name">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="duration" title="duration" [hidden]="true" >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [showOperators]="false"
            [column]="column"
            [filter]="filter">

        </kendo-grid-numeric-filter-cell>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="volume" title="volume" [hidden]="true" >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-numeric-filter-cell>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="unit" title="unit" [hidden]="true" >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-numeric-filter-cell>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="cost" title="cost" [hidden]="true" >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-numeric-filter-cell>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="origin_description" title="origin_description" [hidden]="true" >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-numeric-filter-cell>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="destination_description"
      title="destination_description" [hidden]="true" >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="success" title="success" [hidden]="true" > </kendo-grid-column>

    <kendo-grid-column [width]="250" [filterable]="true" field="status" title="status" [hidden]="true" > </kendo-grid-column>
  </kendo-grid>
</kendo-card>



<ng-template #detailView>
  <ngx-json-viewer [json]="detailData"></ngx-json-viewer>
</ng-template>
