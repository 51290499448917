import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CharonApiService } from 'src/app/services/charon-api.service';

@Component({
  selector: 'ui-charon-instances-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class CharonInstancesViewComponent implements OnInit {

  @Input() data: any;
  @Input() divHeight = '100px';

  constructor(private api: CharonApiService) { }

  ngOnInit(): void {
    this.api.getInstances().subscribe({
      next: (v) => {
        this.data = v;
      }
    })
  }

}
