import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WidgetHandlerService } from '../../services/widget-handler.service';

@Component({
  selector: 'app-preview-widget',
  templateUrl: './preview-widget.component.html',
  styleUrls: ['./preview-widget.component.scss'],
})
export class PreviewWidgetComponent implements OnInit {
  @Input() preview: any;

  constructor(public widgetHandler: WidgetHandlerService, private ar: ActivatedRoute) {}

  ngOnInit(): void {}

  public add() {
    this.widgetHandler.addWidget(this.ar.snapshot.paramMap.get('key'), {
      key: this.preview.key,
      caption: this.preview.title,
      type: this.preview.type,
      index: 0,
      layout: {
        rowSpan: 1,
        colSpan: 1
      }
    });
  }
}
