<div class="wrap-card">
  <kendo-card class="create-card">
    <kendo-card-header>
      Create new asset
      <small>this is a mock</small>
    </kendo-card-header>
    <kendo-card-body>
      <app-form *ngIf="data" [data]="data.action.form.layout" title="AssetStructuredForm" (outputData)="onSave($event)"  ></app-form>
    </kendo-card-body>
  </kendo-card>
</div>

<ngx-json-viewer [json]="template.fields"></ngx-json-viewer>
