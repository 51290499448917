<!-- <div height="100%" width="100%" [innerHtml]="fixURL(p, false)" (click)="Preview(previewModal)"></div> -->

<button kendoButton (click)="Preview(previewModal)" [themeColor]="'info'">
  <i class="fa fa-paperclip" aria-hidden="true"></i>
  {{fileName}} </button>

<ng-template #previewModal>
  <div *ngIf="p.preview_type === 'image/png'" height="100%" width="100%" [innerHtml]="fixURL(p, true)"></div>
  <ngx-extended-pdf-viewer *ngIf="p.preview_type === 'document/pdf'" [base64Src]="p.preview" [height]="'100%'"
    [width]="'100%'" [useBrowserLocale]="true"></ngx-extended-pdf-viewer>
  <ngx-json-viewer *ngIf="p.preview_type === 'application/json'" [json]="p.preview"></ngx-json-viewer>
</ng-template>
