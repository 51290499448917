import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { AssetApiService } from 'src/app/services/asset-api.service';
import { TicketApiService } from 'src/app/services/ticket-api.service';

@Component({
  selector: 'ui-user-form-browser',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserFormBrowserComponent implements OnInit {
  public data = [];
  public search = '';


  @Input() internal = false;

  public searchName = '';
  public searchEmail = '';



  public gridData: GridDataResult = {
    total: 0,
    data: [],
  };
  public pageSize = 10;
  public skip = 0;

  @Output() selected = new EventEmitter<any>();

  constructor(private ticketApi: TicketApiService) {
    // this.fetchUsers();
  }

  ngOnInit(): void {
    this.fetchUsers();
  }
  searchNext(any: any): void {
    this.fetchUsers();
  }

  clicked(e: any): void {
    console.log(e);
    this.selected.next(e);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.fetchUsers();
  }

  fetchUsers(): void {
    let vals: Map<string, string> = new Map<string,string>();
    console.log("internal", this.internal)
    if (this.internal) {
      vals.set('internal', 'eq#true')
      console.log(vals)
    }
    if (this.searchName !== '') {
      vals.set('name', `contains#${this.searchName}`)
      console.log(vals)
    }
    if (this.searchEmail) {
      vals.set('email', `contains#${this.searchEmail}`)
      console.log(vals)
    }
    this.ticketApi.listUsers((this.skip / this.pageSize) + 1, this.pageSize, vals).subscribe({
      next: (v) => {
        this.gridData = {
          total: v.total_records,
          data: v.records
        };
      },
    });
  }
}
