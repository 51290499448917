<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label for="name" text="Rule Name"></kendo-label>
    <kendo-textbox
      formControlName="name"
    ></kendo-textbox>
    <kendo-formerror
      >Error: Name is required</kendo-formerror
    >
  </kendo-formfield>


  <kendo-formfield>
    <kendo-label [for]="'at_status'" text="At Status"></kendo-label>
    <kendo-dropdownlist formControlName="at_status" #status required textField="at status" [data]="statuses"
      [defaultItem]="form.controls.at_status.value">
    </kendo-dropdownlist>
    <!-- <kendo-formhint>Changing status will update the Last Action field</kendo-formhint> -->

    <kendo-formerror>Error: status is required</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="'at_priority'" text="At Priority"></kendo-label>
    <kendo-dropdownlist formControlName="at_priority" #level required textField="at priority" [data]="levels"
      [defaultItem]="form.controls.at_priority.value">
    </kendo-dropdownlist>
    <kendo-formerror>Error: Priority is required</kendo-formerror>
  </kendo-formfield>


  <kendo-formfield>
    <kendo-label [for]="'allowed_duration_min'" text="Allowed Duration (minutes)"></kendo-label>
    <kendo-numerictextbox formControlName="allowed_duration_min"> </kendo-numerictextbox>
    <!-- <kendo-formhint>Changing status will update the Last Action field</kendo-formhint> -->

    <kendo-formerror>Error: status is required</kendo-formerror>
  </kendo-formfield>

    Next status and priority is the state which will be set after the allowed duration is exceeded.

  <kendo-formfield>
    <kendo-label [for]="'next_status'" text="At Status"></kendo-label>
    <kendo-dropdownlist formControlName="next_status" #status required textField="Next status" [data]="statuses"
      [defaultItem]="form.controls.next_status.value">
    </kendo-dropdownlist>
    <!-- <kendo-formhint>Changing status will update the Last Action field</kendo-formhint> -->

    <kendo-formerror>Error: status is required</kendo-formerror>
  </kendo-formfield>



  <kendo-formfield>
    <kendo-label [for]="'next_priority'" text="At Priority"></kendo-label>
    <kendo-dropdownlist formControlName="next_priority" #level required textField="Next priority" [data]="levels"
      [defaultItem]="form.controls.next_priority.value">
    </kendo-dropdownlist>
    <kendo-formerror>Error: Priority is required</kendo-formerror>
  </kendo-formfield>


  <kendo-formfield>
    <kendo-label [for]="'organisation_id'" text="Organisation"></kendo-label>
    <app-organisation-selector formControlName="organisation_id"> </app-organisation-selector>
    <kendo-formerror>Error: Organisation is required</kendo-formerror>
  </kendo-formfield>




  <button
    *ngIf="form.dirty && !isCreate"
    kendoButton
    [themeColor]="'success'"
    (click)="update()"
  >
    Update
  </button>
  <button
    *ngIf="form.dirty && isCreate"
    [disabled]="!form.valid"
    kendoButton
    [themeColor]="'success'"
    (click)="create()"
  >
    Create
  </button>
</form>
