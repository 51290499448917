import {
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { TicketApiService } from 'src/app/services/ticket-api.service';
import { getChanges } from 'src/app/shared/changes';

@Component({
  selector: 'ui-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageComponent implements OnInit, OnChanges {

  constructor(
    public api: TicketApiService,
    private modal: ModalService,
    private router: Router,
    private ar: ActivatedRoute
  ) {}

  public get isCreate(): boolean {
    return this.createAction;
  }
  private createAction = false;
  public edit = false;
  public attachmentList: any;
  public files: any[] = [];

  @Input() message: any;

  public form: FormGroup;
  public uploadForm: FormGroup;

  @Output() submitted = new EventEmitter<boolean>(false);

  public startedSubmitDelay = false;
  public cancel = false;
  ngOnChanges(changes: SimpleChanges): void {
    // this.ngOnInit();
  }

  ngOnInit(): void {
    if (!this.message) {
      console.log('create empty form');
      this.createAction = true;
      this.message = {
        id: '',
        draft: true,
        email: '',
        from: '',
        internal_message: true,
        name: '',
        notify_subscribers: false,
        opt_out: false,
        ticket_id: '',
        user_id: '',
        visible: true,
        body: '',
      };
    }

    this.form = new FormGroup({
      id: new FormControl(this.message.id, []),
      draft: new FormControl(this.message.draft, []),
      email: new FormControl(this.message.email, []),
      from: new FormControl(this.message.from, []),
      internal_message: new FormControl(this.message.internal_message, []),
      name: new FormControl(this.message.name, []),
      notify_subscribers: new FormControl(this.message.notify_subscribers, []),
      opt_out: new FormControl(this.message.opt_out, []),
      ticket_id: new FormControl(this.message.ticket_id, []),
      user_id: new FormControl(this.message.user_id, []),
      visible: new FormControl(this.message.visible, []),
      body: new FormControl(this.message?.body?.payload['text/html'] || '', [
        Validators.required,
      ]),
    });

    this.uploadForm = new FormGroup({
      file: new FormControl('', [Validators.required]),
    });
    this.api
      .listTicketMessageAttachments(this.message.ticket_id, this.message.id)
      .subscribe({
        next: (e) => {
          this.attachmentList = e;
        },
      });
  }
  async submitDelay(): Promise<void> {
    this.startedSubmitDelay = true;
    // await this.sleep(5000);
    if (this.cancel) {
      this.startedSubmitDelay = false;
      this.cancel = false;
      return;
    }

    this.submitNow();
    this.startedSubmitDelay = false;
    this.cancel = false;
  }

  sleep(ms): Promise<unknown> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  openFileUpload(contentRef: TemplateRef<any>): void {
    this.modal.openDialog('Upload a file', contentRef, undefined, "20vh", "50vw");
  }
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  submitUpload(e?: any): void {
    console.log(e);
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);
    this.api
      .uploadMessageAttachment(
        this.message.ticket_id,
        this.message.id,
        formData
      )
      .subscribe({
        next: (v) => {
          this.modal.closeDialog();
          this.router.navigate([], {
            relativeTo: this.ar,
            queryParams: { updated: v.VersionID },
            queryParamsHandling: 'merge',
          });
        },
      });
  }

  submitNow(): void {
    this.form.controls.draft.setValue(false);
    this.form.controls.draft.markAsDirty();
    this.form.controls.draft.markAsTouched();
    const changes = getChanges(this.form);
    console.log('SUBMIT NOW', changes);
    this.api
      .updateMessagePartial(this.message.ticket_id, this.message.id, changes)
      .subscribe({
        next: (n) => {
          this.router.navigate([], {
            relativeTo: this.ar,
            queryParams: { updated: new Date().getUTCDate() },
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
          this.submitted.next(true);
          this.modal.closeWindow();
        },
      });
  }

  submit(): void {
    console.log(this.form.getRawValue());
    const changes = getChanges(this.form);

    switch (this.createAction) {
      case true:
        console.log(changes, 'not implemented');

        // if (this.form.invalid) {
        //   return;
        // }
        // console.log(changes);
        // this.api.createMessage(changes, ticket).subscribe({
        //   next: (n) => {
        //     this.router.navigate([], {
        //       relativeTo: this.ar,
        //       queryParams: { updated: new Date() },
        //       queryParamsHandling: 'merge', // remove to replace all query params by provided
        //     });
        //     this.submitted.next(true);
        //     if (!!this.modal.windowRef) { this.modal.closeWindow(); }
        //     if (!!this.modal.ref) { this.modal.closeDialog(); }
        //   },
        // });
        break;

      default:
        console.log(changes, 'not implemented');
        this.api
          .updateMessagePartial(
            this.message.ticket_id,
            this.message.id,
            changes
          )
          .subscribe({
            next: (n) => {
              this.router.navigate([], {
                relativeTo: this.ar,
                queryParams: { updated: new Date().toISOString() },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              });
              this.submitted.next(true);
              this.modal.closeWindow();
            },
          });
        break;
    }
  }

  deleteMessage(){
    if (!confirm("Are you sure?")){
      return
    }
    this.api
          .deleteMessage(
            this.message.id,
            this.message.ticket_id,
          )
          .subscribe({
            next: (n) => {
              this.router.navigate([], {
                relativeTo: this.ar,
                queryParams: { updated: new Date().toISOString() },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              });
              this.submitted.next(true);
              this.modal.closeWindow();
            },
          });
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  function(){}
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    // upload
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
