<ng-template #windowContent>
  <!-- test content
  {{data$ | async | json}} -->
  <div *ngIf="windowData">
    <app-form [editMode]="editMode$" (allValid)="isValid($event)" [data]="windowData.action.form.layout"
      [title]="windowData.action.form.title" [saveAction]="save$" (outputData)="dataFormat($event)"></app-form>
  </div>
</ng-template>

<ng-template #actionsContent>
  <div>
    <kendo-dialog-actions style="border: 0px;">
      <button kendoButton class="k-bg-success"
        *ngIf="windowData.action['save-caption'] && windowData.action.crud.readOnly !== true"
        (click)="handleButton($event, 'save')" [disabled]="!FormValid()">{{windowData.action['save-caption'] ||
        ""}}</button>
      <button kendoButton class="k-bg-warning"
        *ngIf="windowData.action.crud.type === '.DeleteAction' && windowData.action.crud.readOnly !== false"
        (click)="handleButton($event, 'delete')">{{"Delete"}}</button>
      <button kendoButton *ngIf="windowData.action['cancel-caption']"
        (click)="handleButton($event, 'cancel')">{{windowData.action['cancel-caption'] || ""}}</button>
    </kendo-dialog-actions>
  </div>
</ng-template>
