import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AssetApiService } from '../services/asset-api.service';

@Injectable({ providedIn: 'root' })
export class SubMenuResolver implements Resolve<any> {
  constructor(private api: AssetApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    const id = route.paramMap.get('id');
    if (id === null){
      return;
    }
    return this.api.getSubMenu(id);
  }
}
