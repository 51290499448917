import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  TileLayoutComponent,
} from '@progress/kendo-angular-layout';
import { Observable, Subscription } from 'rxjs';
import { DashboardApiService } from 'src/app/services/dashboard-api.service';
import { WidgetHandlerService } from '../services/widget-handler.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit, OnDestroy {
  public widgetHandlerObserver$: Subscription;
  public valuesObserver$: Subscription;
  public widgetObserver$: Subscription;

  public expanded = true;
  public editMode = true;
  public dashboardData: any;
  public keys: Array<string> = [];

  public form: FormGroup;

  @ViewChild('tilelayout') tile: TileLayoutComponent;

  public widgets: Map<string, any>;

  public itemsToAdd: Array<WidgetItem> = [];

  public dashboards: any;
  public dashboardKey: string;
  public createdDashboardRef: DialogRef;

  constructor(
    public widgetHandler: WidgetHandlerService,
    public dashboardApi: DashboardApiService,
    public ar: ActivatedRoute,
    public router: Router,
    public dialogService: DialogService
  ) {
    this.dashboards = this.ar.snapshot.data.dashboards || undefined;
    this.dashboardKey = this.ar.snapshot.paramMap.get('key');

    this.router.events.subscribe({next: (e) => {
      if (e instanceof NavigationEnd) {
        this.dashboards = this.ar.snapshot.data.dashboards || undefined;
        this.dashboardKey = this.ar.snapshot.paramMap.get('key');
        this.widgetHandler.$change.next(true);
      }
    }});

    this.form = new FormGroup({
      caption: new FormControl('', [Validators.minLength(3)]),
      shared: new FormControl(true),
      icon: new FormControl(''),
    });
    this.form.controls.shared.disable();
  }
  ngOnDestroy(): void {
   if (!!this.widgetHandlerObserver$) {
      this.widgetHandlerObserver$.unsubscribe();
   }
   if (!!this.valuesObserver$) {
      this.valuesObserver$.unsubscribe();
   }
   if (!!this.widgetObserver$) {
      this.widgetObserver$.unsubscribe();
   }

  }

  ngOnInit(): void {
    // this.widgets = this.widgetHandler.widgets;
    this.widgetHandlerObserver$ = this.widgetHandler.$change.asObservable().subscribe({
      next: (e) => {
        console.log('NEXT fetch');
        this.widgets = this.widgetHandler.getWidgets(this.dashboardKey);
        this.keys = [];
        console.log('OVERVIEW', this.widgets);
        this.widgets?.forEach((val, key) => {
          this.keys.push(key);
        });
      },
    });

    this.valuesObserver$ = this.dashboardApi.getDashboardValues(this.dashboardKey).subscribe({
      next: (data) => {
        console.log(data);
        this.dashboardData = data;
        // this.keys = [];
        // data?.forEach(element => {
        //   this.keys.push(element.key);
        // });
      },
    });

    this.widgetObserver$ = this.dashboardApi.getWidgets().subscribe({
      next: (data) => {
        this.itemsToAdd = data;
      },
    });
  }

  // TODO: currently a static mock (@Wim -> maybe create a filter to provide the key add the widgets endpoints)
  public selectabledItems(): Array<WidgetItem> {
    return this.itemsToAdd.filter((x) => {
      return this.keys.indexOf(x.key) < 0;
    });
  }

  public keyToData(key: string): any {
    if (Array.isArray(this.dashboardData)) {
      return this.dashboardData.filter((x) => {
        if (x.key === key) {
          return x;
        }
      });
    } else {
      return [];
    }
  }

  public deleteItem(key: string): void {
    this.widgetHandler.removeWidget(this.dashboardKey, key);
  }

  public debugState(): void {
    this.tile.items.forEach((e) => {
      console.log(e.elem.nativeElement.id, {
        rowSpan: e.rowSpan,
        colSpan: e.colSpan,
        order: e.order
      });
      this.widgetHandler.setSize(
        this.dashboardKey,
        e.elem.nativeElement.id,
        e.colSpan,
        e.rowSpan,
        e.order
      );
    });
    this.widgetHandler.storeWidgets(this.dashboardKey);
    this.editMode = false;
  }

  public openWizard(template: TemplateRef<any>): void {
    this.dialogService.open({
      title: 'add widget',
      content: template,
    });
  }

  public openCreateDashboard(template: TemplateRef<any>): void {
    this.createdDashboardRef =  this.dialogService.open({
      title: 'Create Dashboard',
      content: template,
    });
  }
  public submitForm(): void {
    this.dashboardApi.createDashboard(this.form.getRawValue()).subscribe({next: (data) => {
      console.log('ADDED', data);
      this.createdDashboardRef.close();
      this.form.reset();
      this.dashboardApi.getDashboards().subscribe({next: (n) => {
        this.dashboards = n;
      }});
    }});
  }

  public deleteDashboard(): void {
    if (confirm(`Are you sure to delete ${this.dashboardKey} dashboard`)) {
      this.dashboardApi.deleteDashboard(this.dashboardKey).subscribe({next: (d) => {
        this.dashboardApi.getDashboards().subscribe({next: (n) => {
          this.dashboards = n;
        }});
      }});
    }
  }
}

export class WidgetItem {
  title: string;
  key: string;
  type: string;
}
