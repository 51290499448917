import { LogicPlanRule } from './logic-plan.component';

export interface LogicPlanRules extends Array<LogicPlanRule> {
  swap?(a: LogicPlanRule, b: LogicPlanRule): void;
}

export function ArrayMove(
  arr: Array<LogicPlanRule>,
  item: LogicPlanRule,
  up: boolean
): Array<LogicPlanRule> {
  const list = CountParent(arr, item);
  if (up) {
    if (item.priority <= 1) {
      // do nothing already first
      console.log('do nothing already first');
    } else {
      // const itemToMoveIndex = list.find((val) => val.priority = item.priority);
      // console.log("curernt index", itemToMoveIndex);
      const oldItem = list.find((val) => val.priority === item.priority);
      const newItem = list.find((val) => val.priority === item.priority - 1);
      console.log(oldItem, ' to pos of', newItem);
      oldItem.priority = oldItem.priority - 1;
      newItem.priority = newItem.priority + 1;
      // update
      let index = arr.findIndex((o) => o.id === oldItem.id);
      if (index > -1) {
        arr[index] = oldItem;
      } else {
        arr.push(oldItem);
      }
      index = arr.findIndex((o) => o.id === newItem.id);
      if (index > -1) {
        arr[index] = newItem;
      } else {
        arr.push(newItem);
      }
    }
  } else {
    if (item.priority >= list.length) {
      // do nothing already last
      console.log('do nothing already last');
    } else {
      const oldItem = list.find((val) => val.priority === item.priority);
      const newItem = list.find((val) => val.priority === item.priority + 1);
      console.log(oldItem, ' to pos of', newItem);
      oldItem.priority = oldItem.priority + 1;
      newItem.priority = newItem.priority - 1;
      // update
      let index = arr.findIndex((o) => o.id === oldItem.id);
      if (index > -1) {
        arr[index] = oldItem;
      } else {
        arr.push(oldItem);
      }
      index = arr.findIndex((o) => o.id === newItem.id);
      if (index > -1) {
        arr[index] = newItem;
      } else {
        arr.push(newItem);
      }
    }
    // }

    console.log('move a child item', list.length);
  }

  return arr;
}

export function CountParent(
  arr: Array<LogicPlanRule>,
  item: LogicPlanRule
): Array<LogicPlanRule> {
  return arr.filter((val) => val.parentId === item.parentId);
}

export function RemoveItem(
  arr: Array<LogicPlanRule>,
  item: LogicPlanRule
): Array<LogicPlanRule> {

  const list = CountParent(arr, item);

  if (item.priority === list.length) {
    arr = arr.filter((val) => val.id !== item.id);
  } else {
    console.log("REMOVE and set")
    // delete
    arr = arr.filter((val) => val.id !== item.id);
    // we need to shift the priorities

    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.priority > item.priority) {
        // let s = arr.map((val, idx, arr) => {val.id === element.id}, element.parentId - 1);
        const elidx = arr.findIndex((o) => o.id === element.id);
        arr[elidx].priority = element.priority - 1;
      }
    }

    // IF parentID not null delete childs
    if (item.parentId === null) {
      arr = arr.filter((val) => val.parentId !== item.id);
    }

  }
  console.log(arr.length)

  return arr

}
