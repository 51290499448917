import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ui-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() contact: any;
  @Input() isStarter: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
