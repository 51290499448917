import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { CategoryAxisNotesLabelComponent } from '@progress/kendo-angular-charts';
import {
  CreateFormGroupArgs,
  FlatBindingDirective,
  ReactiveEditingDirective,
  TreeListComponent,
} from '@progress/kendo-angular-treelist';
import { SortDescriptor } from '@progress/kendo-data-query';
import { fromEvent, Observable, Subject, Subscription, take } from 'rxjs';
import { items } from 'src/app/helpdesk/items-flat';
import { ModalService } from 'src/app/services/modal.service';
import { ArrayMove, CountParent, LogicPlanRules, RemoveItem } from './array';
import {
  findDataItem,
  removeDropHint,
  tableRow,
  isSameRow,
  closestWithMatch,
  getContentElement,
  focusRow,
  showDropHint,
  closest,
} from './utils';

@Component({
  selector: 'app-logic-plan',
  templateUrl: './logic-plan.component.html',
  styleUrls: ['./logic-plan.component.scss'],
})
export class LogicPlanComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
  @ViewChild('treelist') public treelist!: TreeListComponent;

  logicPlan: Array<LogicPlanRule>;
  // logicPlan: LogicPlanRules;

  @Input() readOnly = false;
  @Input() editMode: Observable<boolean> = new Subject<boolean>();
  public edit = false;

  @Input() saveAction: Observable<boolean> = new Subject<boolean>();
  @Output('outputData') outputData = new EventEmitter<any>();

  private currentSubscription!: Subscription;
  private indexSub!: Subscription;

  public draggedRowEl!: HTMLTableRowElement;

  public draggedItem!: LogicPlanRule;
  public targetedItem!: LogicPlanRule;

  public expandedKeys: number[] = [];
  public newLogicPlanRuleId!: any;
  public isParentDragged: boolean = false;

  // EDIT
  public ruleToEdit: LogicPlanRule;

  public formGroup: FormGroup;
  public isCreate = false;

  public sort: SortDescriptor[] = [
    {
      field: 'priority',
      dir: 'asc',
    },
  ];
  // form control

  disabled = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    private renderer: Renderer2,
    private zone: NgZone,
    public dialog: ModalService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  isLast(item: LogicPlanRule):boolean {
    return CountParent(this.logicPlan, item).length === item.priority;
  }
  isFirst(item: LogicPlanRule):boolean {
    return item.priority === 1;
  }

  remove(item: LogicPlanRule): void {
    console.log(item)
    this.logicPlan = RemoveItem(this.logicPlan, item);
  }

  moveUp(item: LogicPlanRule) {
    this.logicPlan = ArrayMove(this.logicPlan, item, true);
    console.log(this.logicPlan);
  }
  moveDown(item: LogicPlanRule) {
    this.logicPlan = ArrayMove(this.logicPlan, item, false);
    console.log(this.logicPlan);
  }

  writeValue(value: LogicPlanRule[]): void {
    console.log('VALUE ', value);
    if (value !== undefined) {
      this.logicPlan = value;
    } else {
      this.logicPlan = new Array<LogicPlanRule>();
    }
    // this.formGroup.controls.priority.setValue()
  }

  public fitColumns(): void {
    this.zone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.treelist.autoFitColumns();
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate({ value }: FormControl) {
    console.log('VALID', value);
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnDestroy();
    this.ngOnInit();
  }

  public log(...any: any) {
    console.log(...any);
  }

  public createFormGroup({ isNew, dataItem }: CreateFormGroupArgs): any {
    const item = isNew ? {} : dataItem;

    this.formGroup = new FormGroup({
      id: new FormControl(item.id),
      parentId: new FormControl(item.parentId),
      ruleTypeIcon: new FormControl(item.ruleTypeIcon),
      ruleTypeAction: new FormControl(item.ruleTypeAction, [
        Validators.required,
        Validators.minLength(1),
      ]),
      ruleDescription: new FormControl(
        item.ruleDescription,
        Validators.required
      ),
      actionDescription: new FormControl(
        item.actionDescription,
        Validators.required
      ),
      priority: new FormControl(
        item.priority,
        Validators.required
      ),
    });
    this.formGroup.disable();
    return this.formGroup;
  }

  public get allowSave(): boolean {
    const save = this.formGroup?.touched || false;
    return save;
  }

  ngOnInit(): void {
    this.ngControl.control.setValidators([this.validate.bind(this)]);
    // this.logicPlan = testRules;
    // this.setDraggableRows();
    this.editMode.subscribe({
      next: (v) => {
        console.log('logicplan to ', v);
        this.edit = v;
        // this.setDraggableRows();
      },
    });
    this.setRowIndex();
    // this.fitColumns();
  }
  public ngOnDestroy(): void {
    if (!!this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
  }

  public countChilds(parentID: any): number {
    return this.logicPlan.filter((val) => val.parentId === parentID).length;
  }

  public editRule(
    rule: any,
    formTemplate: TemplateRef<any>,
    isNew?: boolean
  ): void {
    console.log('CREATE OR EDIT', typeof rule, isNew);

    if (!isNew) {
      this.ruleToEdit = rule.viewItem.data;
      this.dialog.openDialog('Edit rule', formTemplate);
    } else {
      if (rule.viewItem?.parent?.id) {
      }
      console.log("ROW WITH PARENT", rule.viewItem?.parent?.id, this.countChilds(rule.viewItem?.parent?.id))
      const pr = this.countChilds(rule.viewItem?.parent?.id || null) + 1;
      this.ruleToEdit = {
        id: this.logicPlan.length + 1 || 1,
        parentId: rule.viewItem?.parent?.id || null,
        ruleTypeIcon: '',
        ruleTypeAction: '',
        ruleDescription: '',
        actionDescription: '',
        priority: pr,
      };
      this.dialog.openDialog('Create rule', formTemplate);
    }
    console.log(rule);
  }


  public submittedUpdate(rule: LogicPlanRule): void {
    console.log(rule);
    const index = this.logicPlan.indexOf(this.ruleToEdit);
    if (index < 0) {
      return;
    }
    this.logicPlan[index] = rule;
    this.dialog.closeDialog();
    console.log(this.logicPlan);
    // this.treelist.reload(this.logicPlan, false);
    this.treelist.data = this.logicPlan;

    this.setDraggableRows();
    this.ruleToEdit = null;
    this.onChange(this.logicPlan);
  }

  public submittedCreate(rule: LogicPlanRule): void {
    console.log(rule);

    this.logicPlan.push(rule);
    this.dialog.closeDialog();
    console.log(this.logicPlan);
    // this.treelist.reload(this.logicPlan, false);
    this.treelist.data = this.logicPlan;

    this.setDraggableRows();
    this.ruleToEdit = null;
    this.onChange(this.logicPlan);
  }

  public rebind(directive: FlatBindingDirective): void {
    directive.rebind();
  }
  public created(directive: ReactiveEditingDirective, data: any): void {
    console.log(data);
    this.formGroup.patchValue(data);
    this.dialog.closeDialog();
    this.formGroup.markAllAsTouched();
    this.onChange(this.logicPlan);
  }

  public createNewRow(parent: LogicPlanRule) {
    console.log(parent);
    const newRule: LogicPlanRule = {
      id: this.logicPlan.length + 1,
      parentId: parent.id,
      ruleTypeIcon: '',
      ruleTypeAction: '',
      ruleDescription: '',
      actionDescription: '',
      priority: 0,
    };
    this.logicPlan.push(newRule);
    this.treelist.data = this.logicPlan;

    this.treelist.updateView();
    // this.treelist.
    this.setDraggableRows();
    this.onChange(this.logicPlan);
  }

  public saveNew(any: any, directive: FlatBindingDirective): void {
    const form: FormGroup = any.formGroup;
    this.logicPlan.push(form.getRawValue());
    console.log('TRACE SAVE NEW', form.getRawValue());
    directive.rebind();
    this.setDraggableRows();
    this.onChange(this.logicPlan);
  }

  public setDraggableRows(): void {
    this.currentSubscription = this.handleDragAndDrop();
    const tableRows: HTMLTableRowElement[] = Array.from(
      document.querySelectorAll('.k-grid-content .k-grid-table-wrap tbody tr')
    );
    tableRows.forEach((row) => {
      this.renderer.setAttribute(row, 'draggable', `${this.edit}`);
    });
  }

  public setRowIndex(): void {
    // console.log("row index")
    // const tableRows: HTMLTableRowElement[] = Array.from(
    //   document.querySelectorAll('.k-grid-content .k-grid-table-wrap tbody tr')
    // );
    // console.log(tableRows)
    // tableRows.forEach((row, idx) => {
    //   console.log("ROW", idx, row.rowIndex);
    // });
  }

  public onToggle(): void {
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      if (!!this.currentSubscription) {
        this.currentSubscription.unsubscribe();
      }
      this.setDraggableRows();
    });
    this.setRowIndex();
  }

  private handleDragAndDrop(): Subscription {
    const table: HTMLElement[] = Array.from(
      document.querySelectorAll('.k-grid-content .k-grid-table-wrap tbody')
    );
    const sub = new Subscription(() => {});
    const dragStart: Observable<DragEvent> = fromEvent<DragEvent>(
      table,
      'dragstart'
    );
    const dragOver: Observable<DragEvent> = fromEvent<DragEvent>(
      table,
      'dragover'
    );
    const dragEnd: Observable<DragEvent> = fromEvent<DragEvent>(
      table,
      'dragend'
    );

    sub.add(
      dragStart.subscribe((e: DragEvent) => {
        this.draggedRowEl = <HTMLTableRowElement>e.target;
        if (this.draggedRowEl.tagName === 'TR') {
          this.draggedItem = <LogicPlanRule>(
            findDataItem(this.logicPlan, this.draggedRowEl)
          );
        }
      })
    );

    sub.add(
      dragOver.subscribe((e: DragEvent) => {
        e.preventDefault();
        removeDropHint(this.draggedRowEl);

        const element: HTMLElement = <HTMLElement>e.target;

        if (element.tagName === 'TD' || element.tagName === 'SPAN') {
          const currentRow = <HTMLTableRowElement>closest(element, tableRow);
          this.targetedItem = <LogicPlanRule>(
            findDataItem(this.logicPlan, currentRow)
          );

          // Prevent dragging parent row in its children
          let row: LogicPlanRule | undefined = this.targetedItem;
          this.isParentDragged = false;

          while (row!.parentId! >= 1) {
            const parentRow: LogicPlanRule | undefined = this.logicPlan.find(
              (item) => item.id === row!.parentId
            );

            if (parentRow!.id === this.draggedItem.id) {
              this.isParentDragged = true;
              e.dataTransfer!.dropEffect = 'none';
            }
            row = parentRow;
          }

          if (isSameRow(this.draggedItem, this.targetedItem)) {
            e.dataTransfer!.dropEffect = 'none';
          }

          if (
            !this.isParentDragged &&
            !isSameRow(this.draggedItem, this.targetedItem)
          ) {
            const containerOffest = { top: 0, left: 0 };
            this.getDropPosition(currentRow, e.clientY, containerOffest);
            this.draggedRowEl = currentRow;
          }
        }
      })
    );

    sub.add(
      dragEnd.subscribe((e: DragEvent) => {
        e.preventDefault();
        removeDropHint(this.draggedRowEl);

        if (
          this.draggedItem.id !== this.targetedItem.id &&
          !this.isParentDragged
        ) {
          this.draggedItem.parentId = this.newLogicPlanRuleId;
          this.zone.run(() => (this.logicPlan = [...this.logicPlan]));
        }
      })
    );

    return sub;
  }

  public getDropPosition(
    target: HTMLTableRowElement,
    clientY: number,
    containerOffset: { top: number; left: number }
  ): void {
    const item: HTMLElement | null = closestWithMatch(
      target,
      '.k-grid-table-wrap tbody tr'
    );
    const content: HTMLElement | null = getContentElement(item!);

    const itemViewPortCoords: DOMRect = content!.getBoundingClientRect();
    const itemDivisionHeight: number = itemViewPortCoords.height / 4;
    const pointerPosition: number = clientY - containerOffset.top;
    const itemTop: number = itemViewPortCoords.top - containerOffset.top;

    const isBefore: boolean = pointerPosition < itemTop + itemDivisionHeight;
    const isAfter: boolean =
      pointerPosition >=
      itemTop + itemViewPortCoords.height - itemDivisionHeight;
    const isOverTheSame: boolean = this.draggedItem.id === this.targetedItem.id;

    removeDropHint(this.draggedRowEl);
    focusRow(target);

    const draggedRowManagerId: any = this.draggedItem.parentId;
    const currentRowManagerId: any = this.targetedItem.parentId;

    if (isBefore) {
      showDropHint(target, 'before');
      this.reorderRows(0);
      if (draggedRowManagerId !== currentRowManagerId) {
        this.newLogicPlanRuleId = currentRowManagerId;
      }
    }

    if (isAfter) {
      showDropHint(target, 'after');
      this.reorderRows(1);
      if (draggedRowManagerId !== currentRowManagerId) {
        this.newLogicPlanRuleId = currentRowManagerId;
      }
    }

    if (!isOverTheSame && !isBefore && !isAfter) {
      this.newLogicPlanRuleId = this.targetedItem.id;
    }
  }

  public reorderRows(index: number): void {
    const draggedIndex: number = this.logicPlan.indexOf(this.draggedItem);
    this.logicPlan.splice(draggedIndex, 1);

    const targetedIndex: number = this.logicPlan.indexOf(this.targetedItem);
    this.logicPlan.splice(targetedIndex + index, 0, this.draggedItem);

    this.newLogicPlanRuleId = this.draggedItem.parentId;
  }
}

export class LogicPlanRule {
  id: number;
  parentId: number;
  ruleTypeIcon: string;
  ruleTypeAction: string;
  ruleDescription: string;
  actionDescription: string;
  dragging?: boolean;
  priority: number;
}

export const testRules: LogicPlanRule[] = [
  {
    id: 1,
    parentId: null,
    ruleTypeIcon: 'fa-solid fa-globe',
    ruleTypeAction: 'Region Match',
    ruleDescription: 'CallingLocation = Euro',
    actionDescription: 'Allowed, Rate = DataEU, PCC=high',
    priority: 1,
  },
  {
    id: 2,
    parentId: 1,
    ruleTypeIcon: '',
    ruleTypeAction: 'Balance Match Low',
    ruleDescription: 'MonthlyFUT > 0',
    actionDescription: 'PCC=low',
    priority: 1,

  },
  {
    id: 3,
    parentId: 1,
    ruleTypeIcon: '',
    ruleTypeAction: 'Balance Match Medium',
    ruleDescription: 'MonthlyFUT > 100',
    actionDescription: 'PCC=medium',
    priority: 3,

  },
  {
    id: 4,
    parentId: 1,
    ruleTypeIcon: '',
    ruleTypeAction: 'Balance Match High',
    ruleDescription: 'MonthlyFUT > 1000',
    actionDescription: 'PCC=high',
    priority: 2,

  },
  {
    id: 5,
    parentId: null,
    ruleTypeIcon: 'fa-solid fa-globe',
    ruleTypeAction: 'CatchAll',
    ruleDescription: 'Default Catch All',
    actionDescription: 'Block',
    priority: 2,

  },
  {
    id: 6,
    parentId: null,
    ruleTypeIcon: 'fa-solid fa-globe',
    ruleTypeAction: 'Catch Some',
    ruleDescription: 'Default Catch Some',
    actionDescription: 'Block',
    priority: 3,

  },
];
