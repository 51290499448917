import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import {
  NotificationService,
  Position,
  Type,
} from '@progress/kendo-angular-notification';
import { Action } from 'rxjs/internal/scheduler/Action';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { FormWrapperComponent } from '../crm/pages/form-wrapper/form-wrapper.component';
import { CrudService } from './crud-service';
import { DynamicFormService } from '../crm/modals/forms/forms.service';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  public refreshMainMenuAction$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public refreshSubMenuAction$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  public refreshViewAction$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private ns: NotificationService,
    private router: Router,
    private dialogService: DialogService,
    private dynamicModal: DynamicFormService
  ) {}

  // Args are something like a row.
  handleCallbackAction(action: any, args?: any) {
    // console.log('[TRACE] HANDLE CALLBACK ACTION', action);
    if (action.type === '.frontend.ShowNotification') {
      this.showToasterMessage(action, args);
    }
    if (action.type === '.frontend.RefreshMainMenuAction') {
      this.refreshMainMenuAction$.next(true);
    }
    if (action.type === '.frontend.RefreshSubMenuAction') {
      this.refreshSubMenuAction$.next(true);
    }
    if (action.type === '.frontend.RefreshViewAction') {
      this.refreshViewAction$.next(true);
    }
    if (action.type === '.frontend.RedirectParent') {
      var m: Map<string, string> = new Map<string, string>();
      if (action['url-parameters']) {
        action['url-parameters'].forEach((param: any) => {
          if (param.type == 'fix') {
            m.set(param['parameter-name'], param.value);
          }
          if (param.type == 'tablerow') {
            m.set(param['parameter-name'], args[param.fieldname]);
          }
        });
        m.set("redirected", new Date().toISOString());
      }
      // console.log('PARAMS', m);
      // this.refreshViewAction$.next(true);
      this.router.navigate(
        [`/crm/${action.uuid || action.id}/action/${action.action}`],
        { queryParams: Object.fromEntries(m) }
      );
    }
    if (action.type === '.frontend.Messagebox') {
      const actions: Array<any> = [];
      action.actionButtons?.forEach((button: any) => {
        actions.push({
          text: button.caption,
          actions: button.actions,
        });
      });
      const x = this.dialogService.open({
        title: action.headerTitle,
        actions,
        content: action.content,
      });
      x.result.subscribe((data: any) => {
        data.actions?.forEach((actionToHandle: any) => {
          // console.log('HANDLED ACTION ROM MESSAGEBOX', actionToHandle);
          this.handleCallbackAction(actionToHandle);
        });
      });
    }
    if (action.type === '.frontend.RedirectParentModal') {
      // console.log('HANDLE MODAL', action);
      this.dynamicModal.showDynamicFormModal(action.id, action.action);
    }
  }

  showToasterMessage(any: any, args?: any): void {
    // console.log('TOASTER ARGS', args);
    if (any.type !== '.frontend.ShowNotification') {
      return;
    }
    const type: Type = {};
    if (any['notification-type'] !== '') {
      any['notification-type'] = String(any['notification-type']).toLowerCase();
    }
    let toasterPosition: any;
    if (any.location !== '') {
      switch (any.location) {
        case 'RIGHT_UP':
          toasterPosition = { horizontal: 'right', vertical: 'top' };
          break;
        case 'RIGHT_DOWN':
          toasterPosition = { horizontal: 'right', vertical: 'bottom' };
          break;
        case 'MIDDLE_UP':
          toasterPosition = { horizontal: 'center', vertical: 'top' };
          break;
        case 'MIDDLE_DOWN':
          toasterPosition = { horizontal: 'center', vertical: 'bottom' };
          break;
        case 'LEFT_UP':
          toasterPosition = { horizontal: 'left', vertical: 'top' };
          break;
        case 'LEFT_DOWN':
          toasterPosition = { horizontal: 'left', vertical: 'bottom' };
          break;
        default:
          toasterPosition = { horizontal: 'right', vertical: 'top' };
          break;
      }
    }
    if (
      any['notification-type'] !== 'error' ||
      any['notification-type'] !== 'info' ||
      any['notification-type'] !== undefined
    ) {
      type.style = any['notification-type'];
      type.icon = true;
    } else {
      // console.log(any['notification-type']);
      type.style = 'success';
      type.icon = true;
    }
    let messageContent: string = any.caption;

    if (args) {
      if (args['success-count'] !== undefined) {
        messageContent = messageContent.replace(
          '{{success-count}}',
          args['success-count']
        );
      }
      if (args['error-count'] !== undefined) {
        messageContent = messageContent.replace(
          '{{error-count}}',
          args['error-count']
        );
      }
      if (args.totalrequested) {
        messageContent = messageContent.replace(
          '{{totalrequested}}',
          args.totalrequested
        );
      }
    }
    // console.log('MESSAGE', messageContent, args);

    this.ns.show({
      content: messageContent,
      cssClass: 'button-notification',
      animation: { type: 'slide', duration: 400 },
      position: toasterPosition,
      type,
      closable: any['require-confirmation'],
      width: 300,
    });
  }
}
