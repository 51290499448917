import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

import { ActionService } from './action-service';


@Injectable({
  providedIn: 'root'
})
export class CrudService {
  public basePath: string = environment.basePath;

  constructor(private http: HttpClient, private actionHandler: ActionService) {
    console.log('SETTED CRUD BASE PATH', this.basePath);
  }


  handleCrud(crudAction: CrudAction, payload?: any, onSucces?: any[], onError?: any[]): void {
    if (crudAction.httpmethod === 'PUT'){
      this.http.put(`${this.basePath}${crudAction.url}`, payload).toPromise().then(data => {
        onSucces?.forEach(action => {
          this.actionHandler.handleCallbackAction(action, data);
        });
        console.log(data);
      }).catch(error => {
        onError?.forEach(action => {
          this.actionHandler.handleCallbackAction(action, error);
        });
      });
    }
    if (crudAction.httpmethod === 'POST'){
      this.http.post(`${this.basePath}${crudAction.url}`, payload).toPromise().then(data => {
        onSucces?.forEach(action => {

          this.actionHandler.handleCallbackAction(action, data);
        });
        console.log(data);
      }).catch(error => {
        onError?.forEach(action => {
          this.actionHandler.handleCallbackAction(action, error);
        });
      });
    }
    if (crudAction.httpmethod === 'DELETE'){
      this.http.delete(`${this.basePath}${crudAction.url}`, payload).toPromise().then(data => {
        onSucces?.forEach(action => {
          this.actionHandler.handleCallbackAction(action, data);
        });
        console.log(data);
      }).catch(error => {
        onError?.forEach(action => {
          this.actionHandler.handleCallbackAction(action, error);
        });
      });
    }
  }



}

export class CrudAction {
  httpmethod?: string;
  readOnly = false;
  type?: string;
  url?: string;
}
