<div style="width: 100%; display: flex; justify-content: center; margin-top: 2rem;">
  <kendo-card class="k-hstack" style="width: 300px;">
    <kendo-card-header *ngIf="!showButton">Download is starting...</kendo-card-header>
    <kendo-card-header *ngIf="showButton">Try starting your download manually</kendo-card-header>
    <kendo-card-body *ngIf="showButton">
      <button kendoButton themeColor="primary" style="width: 100%;" (click)="download(downloadUrl)"> Manual Start
        Download</button>
    </kendo-card-body>
    <kendo-card-body *ngIf="!showButton">
      <kendo-loader [type]="'pulsing'" [themeColor]="'secondary'" [size]="'large'">
      </kendo-loader>
    </kendo-card-body>
  </kendo-card>
</div>
