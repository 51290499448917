import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  ResolveEnd,
  Router,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetApiService } from 'src/app/services/asset-api.service';
@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
  // encapsulation: ViewEncapsulation.,
})
export class SubmenuComponent implements OnInit, OnDestroy {
  public id = '';
  public activeAction: any = null;
  public oldId = '';
  public routerSub: Subscription;
  public payload: any;

  constructor(
    private ar: ActivatedRoute,
    private router: Router,
    private api: AssetApiService
  ) {
    this.routerSub = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof ResolveEnd) {
        this.oldId = this.id;
        this.activeAction = this.ar.snapshot.paramMap.get('action') || null;
        this.id = this.ar.snapshot.paramMap.get('id') || '';

        // console.log('SUBMENU ', this.ar.snapshot.data.data.subMenu);
        this.payload = this.ar.snapshot.data.data;

        // console.log(this.ar.snapshot.data);
        if (
          val.url === val.urlAfterRedirects &&
          !this.router.url.includes('/action/')
        ) {
          this.actionHandler();
        }
      }
    });
  }

  actionHandler() {
    if (this.payload.action.name) {
      if (this.activeAction === null) {
        this.action(this.payload.action.name);
      }
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  async fetchPayload() {
    this.payload = await this.api
      .getSubMenu(this.id)
      .toPromise()
      .then((data) => {
        return data;
      });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }
  isActive(item: any): boolean {
    return item['action-name'] === this.activeAction;
  }

  action(action: any): void {
    const ac = action['action-name'] || action;
    this.activeAction = ac;
    // console.log('ACTION CLICK', action);
    if (Array.isArray(action['url-parameters']) && action['url-parameters'].length > 0) {
      const m: Map<string, string> = new Map<string, string>();
      action['url-parameters'].forEach(param => {
        // console.log('ACTION CLICK param' , param);
        m.set(param['parameter-name'], param.value || param['default-value']);
      });
      this.router.navigate(
        [`/crm/${action.uuid || this.id}/action/${ac}`],
        { queryParams: Object.fromEntries(m), preserveFragment: false}
      );

    } else {
      console.log('navigate without url-parameters', `/crm/${action.uuid || this.id}/action/${ac}`);
      const blank = {
        currentPage: null,
        pageSize: null,
      };

      this.router.navigate(
        [`/crm/${action.uuid || this.id}/action/${ac}`], {queryParamsHandling: 'merge', queryParams: blank
      }
      );
    }
  }
}
