<div class="rounded">
  <p class="name" [ngClass]="{ 'k-text-primary': isStarter }">
    {{ contact.name }}
  </p>
  <p class="label" *ngIf="isStarter">Starter</p>
  <p
    class="email"
    [routerLink]="['/helpdesk/users/overview/' + contact.id + '/view']"
  >
    {{ contact.email_user || contact.email }}
  </p>
</div>
