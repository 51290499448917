import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  public basePath = `${environment.basePath}`;

  constructor(private http: HttpClient) {}

  getDashboards(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/gui/dashboards`, {
      params: new HttpParams(),
    });
  }
  createDashboard(body: any, ): Observable<any> {
    return this.http.post<any>(`${this.basePath}/gui/dashboards`, body, {
      params: new HttpParams(),
    });
  }
  deleteDashboard(key: string, ): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/gui/dashboards/${key}`, {
      params: new HttpParams(),
    });
  }

  // retrieve a list with available widgets
  getWidgets(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/gui/widgets`, {
      params: new HttpParams(),
    });
  }

  getDashboardWidgets(key: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/gui/dashboards/${key}/widgets`, {
      params: new HttpParams(),
    });
  }

  updateDashboardWidgets(key: string, dashboard: any): Observable<any> {
    console.log(dashboard);
    return this.http.put<any>(`${this.basePath}/gui/dashboards/${key}/widgets`, dashboard, {
      params: new HttpParams(),
    });
  }

  getDashboardValues(key: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/gui/dashboards/${key}/values`, {
      params: new HttpParams(),
    });
  }


}
