import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AssetApiService } from 'src/app/services/asset-api.service';
import { LogicPlanRule } from '../logic-plan/logic-plan.component';

@Component({
  selector: 'app-logic-plan-rule-form',
  templateUrl: './logic-plan-rule-form.component.html',
  styleUrls: ['./logic-plan-rule-form.component.scss'],
})
export class LogicPlanRuleFormComponent implements OnInit {
  @Input() rule: LogicPlanRule;
  @Input() disabled = false;
  @Input() noUpdate = true;
  @Input() isCreate = true;


  public form: FormGroup;
  public keys: Array<string> = new Array<string>();

  public ruleTypes: any[];

  @Output() submitted = new EventEmitter<LogicPlanRule>();
  @Output() created = new EventEmitter<LogicPlanRule>();
  constructor(private api: AssetApiService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      id: new FormControl(this.rule.id, []),
      parentId: new FormControl(this.rule.parentId, []),
      ruleTypeIcon: new FormControl(this.rule.ruleTypeIcon, []),
      ruleTypeAction: new FormControl(this.rule.ruleTypeAction, []),
      ruleDescription: new FormControl(this.rule.ruleDescription, []),
      actionDescription: new FormControl(this.rule.actionDescription, []),
      dragging: new FormControl(this.rule.dragging, []),
      priority: new FormControl(this.rule.priority, []),
    });

    if (this.form.controls.id) {
      this.form.controls.id.disable();
    }
    console.log(this.form);

    if (this.disabled) {
      this.form.disable();
    }

    this.form.valueChanges.subscribe({
      next: (changed) => {
        this.rule = this.form.getRawValue();
      },
    });
    this.api.getRuleTypes().subscribe({
      next: (types) => {
        this.ruleTypes = types;
      }
    })
  }

  update(): void {
    const type = this.ruleTypes.filter((val) => val.caption === this.form.controls.ruleTypeAction.value);
    console.log("type to set",type[0]);
    this.form.controls.ruleTypeIcon.setValue(type[0]?.icon);

    this.submitted.next(this.rule);
  }

  create(): void {
    const type = this.ruleTypes.filter((val) => val.caption === this.form.controls.ruleTypeAction.value);
    console.log("type to set",type[0]);
    this.form.controls.ruleTypeIcon.setValue(type[0]?.icon);
    this.created.next(this.rule);
  }
}
