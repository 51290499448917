<div class="loader" *ngIf="loading">
  <kendo-loader
    class="align-loader"
    [size]="'large'"
    [type]="'infinite-spinner'"
  ></kendo-loader>
</div>

<div class="breadcrumb">
  <app-bread-crumb></app-bread-crumb>
</div>
<!-- <button kendoButton (click)="showModal($event)">show modal</button> -->

<kendo-splitter>
  <kendo-splitter-pane
    class="splitterpane"
    [scrollable]="true"
    min="15%"
    [(size)]="treeViewSize"
  >
    <div class="tree">
      <app-treeview></app-treeview>
    </div>
  </kendo-splitter-pane>

  <kendo-splitter-pane
    class="splitterpane"
    [scrollable]="true"
    min="15%"
    [(size)]="subViewSize"
  >
    <router-outlet
      name="left"
      (activate)="activateSub($event)"
      (deactivate)="deactivateSub($event)"
    ></router-outlet>
  </kendo-splitter-pane>

  <kendo-splitter-pane class="splitterpane" [scrollable]="true" min="10%">
    <router-outlet
      name="right"
      (activate)="activateView($event)"
      (deactivate)="deactivateView($event)"
    >
    </router-outlet>
  </kendo-splitter-pane>
</kendo-splitter>

<div kendoWindowContainer></div>
<div kendoDialogContainer></div>
<modal-forms></modal-forms>
