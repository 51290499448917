import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TicketApiService } from 'src/app/services/ticket-api.service';
import { getChanges } from 'src/app/shared/changes';

@Component({
  selector: 'app-email-config-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class EmailConfigFormComponent implements OnInit {
  @Input() id: any;
  @Input() config: any;
  @Input() disabled = false;
  @Input() noUpdate = true;
  @Input() isCreate = false;
  @Input() excludeFields: Array<string> = new Array<string>();

  public form: FormGroup;
  public keys: Array<string> = new Array<string>();

  @Output() submitted = new EventEmitter<boolean>();

  constructor(private api: TicketApiService) {}

  ngOnInit(): void {
    console.log(this.config);
    this.form = new FormGroup({});

    Object.entries(this.config).forEach((val) => {
      this.form.addControl(val[0], new FormControl(val[1]));
      if (this.excludeFields.indexOf(val[0]) < 0){
        this.keys.push(val[0]);
      }
      if (val[0] !== 'id') {
        // this.form.controls.val[0].disable();
      }

    });
    if (this.form.controls.id) {
      this.form.controls.id.disable();
    }
    console.log(this.form);
    if (this.disabled) {
      this.form.disable();
    }

    // name: new FormControl(this.formData.name, [
    //   Validators.required,
    //   Validators.minLength(3),
    // ]),
    this.form.valueChanges.subscribe({
      next: (changed) => {
        this.config = this.form.getRawValue();
      },
    });
  }

  formatTitle(s: string): string {
    const splist = s.split('_');
    splist.forEach((val, index) => {
      splist[index] = val.replace(
        val,
        (txt) => txt[0].toUpperCase() + txt.substr(1).toLowerCase()
      );
    });
    return splist.join(' ');
  }

  update(): void {
    const changes = getChanges(this.form);
    this.api.updateEmailConfigPartion(this.id, changes).subscribe({
      next: (val) => {
        this.config = val;
        this.form.markAsPristine();
        this.submitted.next(true);
      },
    });
  }

  create(): void {
    this.api.createEmailConfig(this.form.value).subscribe({
      next: (val) => {
        this.config = val;
        this.form.markAsPristine();
        this.submitted.next(true);
      },
    });
  }
}
