import { stagger } from '@angular/animations';
import { NgIf } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  ColumnReorderEvent,
  ColumnVisibilityChangeEvent,
  GridComponent,
  GridDataResult,
  PageChangeEvent, PagerSettings, ColumnResizeArgs
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ExportBrowserGridSettings } from 'src/app/config/exporrt';
import { ExportModel } from 'src/app/models/export';
import { CdrApiService } from 'src/app/services/cdr-api.service';
import { StatePersistingService } from 'src/app/services/grid-state.service';
import { ColumnSettings, GridSettings } from 'src/app/shared/table';

@Component({
  selector: 'ui-export-browswer',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportBrowserComponent implements OnInit, OnDestroy {
  public loading = false;
  public Subj$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public config: PagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [20, 50, 100, 500],
    previousNext: true,
    position: 'bottom',
  };
  private now: Date = new Date();
  private lastMonth: Date = new Date();
  public useRange = false;
  public range = { start: this.lastMonth, end: this.now };

  // detail view
  public detailData: any;
  public detailJobs: any;
  public detailDataEdit = false;

  // state
  public gridSettings: any;
  public filterUnsused: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  // gridData
  public gridData: GridDataResult = {
    data: [],
    total: 0,
  };

  // pagination
  // TODO: should rework from params
  public pageSize = 100;
  public skip = 0;

  // router
  public routerSub$: Subscription;

  //export
  public exportToCreate: ExportModel = {
    exportName: "daily",
    assetUuid: "",
    interval: 1440,
    startFrom: new Date(),
    s3BucketName: 'bucket_name',
    s3BucketPrefix: 'bucket_prefix',
    s3ObjectFileTemplate: '{{ExportID}}/{{JobID}}/export-{{TimeStamp}}-{{Rows}}-{{PageNumber}}.json',
    objectSize: 2000,
    maxObjectsPerRun: 500,

  }

  constructor(
    private cdrApi: CdrApiService,
    private router: Router,
    private ar: ActivatedRoute,
    public dialogService: DialogService,
    public persistingService: StatePersistingService
  ) {
    var gridSettings: GridSettings =
      this.persistingService.get('grid') || ExportBrowserGridSettings;

    if (
      gridSettings.columnsConfig.length != ExportBrowserGridSettings.columnsConfig.length
    ) {
      gridSettings = ExportBrowserGridSettings;
    }

    if (gridSettings !== null) {
      this.gridSettings = this.mapGridSettings(gridSettings);
    }

    this.lastMonth.setMonth(0);
    this.now.setMonth(1);

    this.Subj$.subscribe((x) => {
      this.loading = true;
      var ff: Map<string, string> = new Map<string, string>();
      ff.set('owner_uuid', this.ar.snapshot.data.data.action.id || this.ar.snapshot.params.id || "");
      this.gridSettings.state?.filter?.filters.forEach((f: any) => {
        console.log(f);
        ff.set(f.field, `${f.operator}#${f.value}`);
      });

      ff.set('sortArray', JSON.stringify(this.gridSettings.state?.sort));
      if (this.useRange) {
        ff.set('period', JSON.stringify(this.range));
      }
      if (this.ar.snapshot.data.data.action.id !== '') {
        this.cdrApi
          .listExports(this.skip / this.pageSize + 1, this.pageSize, ff)
          .subscribe({
            next: (data) => {
              this.gridData = {
                data: data.records,
                total: data.total_records,
              };
            },
            error: (error) => {
              console.log(error);
            },
            complete: () => {
              this.loading = false;
            },
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (!!this.Subj$) {
      this.Subj$.unsubscribe();
    }
    // if (!!this.router.events) this.routerSub$.unsubscribe();
  }

  ngOnInit(): void {
    // this.routerSub$ = this.router.events.subscribe((e) => {
    //   if (e instanceof NavigationEnd) {
    //     this.Subj$.next(true);
    //   }
    // });
  }

  public next() {
    this.loading = true;
    this.Subj$.next(true);
  }

  public dialogDetail$: DialogRef
  public dialogResult$: Subscription
  public pullJob$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public pullJobSub$: Subscription

  public redoPullJob() {
    if (this.pullJob$.getValue() === true) {
      setTimeout(() => {
        this.pullJob$.next(this.pullJob$.getValue())
        this.redoPullJob()
      }, 5000);
    }
  }

  public openDialogWindow(data: any, template: TemplateRef<any>, actions: TemplateRef<any>) {
    console.log(data);
    if (data.id) {
      this.cdrApi.getExport(data.id, data.owner).subscribe((view) => {
        this.detailData = view;
      });
    }

    this.pullJobSub$ = this.pullJob$.subscribe(x => {
      this.cdrApi.getExportJobs(data.id, data.owner).subscribe((jobs) => {
        this.detailJobs = jobs;
      });
    })
    this.pullJob$.next(true)
    this.redoPullJob()

    this.dialogDetail$ = this.dialogService.open({
      title: 'Detail view',
      content: template,
      height: '80vh',
      width: '80%',
      actions: actions,
    });

    this.dialogResult$ = this.dialogDetail$.result.subscribe(result => {
      console.log(result)
      this.pullJob$.next(false)
      this.pullJobSub$.unsubscribe()
    })
  }

  public createDialog: DialogRef


  public submitCreate() {
    this.cdrApi.createExport(this.exportToCreate, this.ar.snapshot.data.data.action.id || this.ar.snapshot.params.id || "").subscribe({next: (data) => {
      this.detailData = data;
      this.next();
    }});
    this.exportToCreate = {
      exportName: "daily",
      assetUuid: "",
      interval: 1440,
      startFrom: new Date(),
      s3BucketName: 'bucket_name',
      s3BucketPrefix: 'bucket_prefix',
      s3ObjectFileTemplate: "{{ExportID}}/{{JobID}}/export-{{TimeStamp}}-{{Rows}}-{{PageNumber}}.json",
      objectSize: 2000,
      maxObjectsPerRun: 500,
    };
    this.createDialog.close();
    this.next();
  }

  public async submitUpdate() {
    await this.cdrApi.updateExport(this.detailData, this.detailData.id, this.detailData.owner).subscribe();
    this.dialogDetail$.close();
    this.detailDataEdit = false;
    this.next()
  }

  public openCreateDialog(template: TemplateRef<any>, actions: TemplateRef<any>) {
    this.createDialog = this.dialogService.open({
      title: "Create new export",
      content: template,
      height: '80vh',
      width: '50vh',
      actions: actions,
    });
  }

  public get savedStateExists(): boolean {
    return !!this.persistingService.get('grid');
  }

  public dataStateChange(state: State): void {
    console.log('state', state);
    this.gridSettings.state = state;
    this.pageSize = state.take;
    this.skip = state.skip;
    this.next();
  }

  public saveGridSettings(grid: GridComponent): void {
    const columns = grid.columns;
    //add only the required column properties to save local storage space
    const gridConfig = {
      state: this.gridSettings.state,
      columnsConfig: this.gridSettings.columnsConfig,
    };

    this.persistingService.set('grid', gridConfig);
  }

  public mapGridSettings(gridSettings: GridSettings): GridSettings {
    const state = gridSettings.state;
    this.mapDateFilter(state?.filter);

    return {
      state,
      columnsConfig: gridSettings.columnsConfig?.sort(
        (a: any, b: any) => a.orderIndex - b.orderIndex
      ),
    };
  }

  private mapDateFilter = (descriptor: any) => {
    const filters = descriptor.filters || [];

    filters.forEach((filter: any) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === 'FirstOrderedOn' && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };

  public resetFilters() {
    this.useRange = false;
    this.gridSettings.state.filter = {
      logic: 'and',
      filters: [],
    };
    this.next();
  }

  public saveStateResize(e: Array<ColumnResizeArgs>, grid: GridComponent) {
    console.log(e, this.gridSettings.columnsConfig);

    for (let col of this.gridSettings.columnsConfig) {
      for (let ecol of e) {
        if (ecol.column['field'] == col.field) {
          col['_width'] = ecol.newWidth;
          console.log('updated', col);
        }
      }
    }
    console.log(e, this.gridSettings.columnsConfig);

    this.saveGridSettings(grid);
  }

  public saveState(e: ColumnReorderEvent, grid: GridComponent) {
    console.log(this.gridSettings.columnsConfig);

    this.gridSettings.columnsConfig = this.moveInArray(
      this.gridSettings.columnsConfig,
      e.oldIndex,
      e.newIndex
    );
    console.log(this.gridSettings.columnsConfig);

    this.saveGridSettings(grid);
  }
  public saveStateColumVisible(
    e: ColumnVisibilityChangeEvent,
    grid: GridComponent
  ) {
    console.log('columns to change', e, this.gridSettings.columnsConfig);

    for (let col of this.gridSettings.columnsConfig) {
      for (let ecol of e.columns) {
        if (ecol['field'] == col.field) {
          col.hidden = ecol.hidden;
        }
      }
    }
    console.log(this.gridSettings.columnsConfig);

    this.saveGridSettings(grid);
  }
  moveInArray(arr: any, from: number, to: number) {
    // Make sure a valid array is provided
    if (Object.prototype.toString.call(arr) !== '[object Array]') {
      throw new Error('Please provide a valid array');
    }

    // Delete the item from it's current position
    var item = arr.splice(from, 1);

    // Make sure there's an item to move
    if (!item.length) {
      throw new Error('There is no item in the array at index ' + from);
    }

    // Move the item to its new position
    arr.splice(to, 0, item[0]);
    return arr;
  }
  parseTime(x: any): number {
    if (!!x) {
      var d = new Date(x)
      return d.getTime()
    }
    return 0
  }

  public async suspend(any: any) {
    if (any.suspend === false) {
      if (confirm("Are you sure to suspend this export ?")) {
        await this.cdrApi.suspendExport(any.id, any.owner).subscribe(x => {
          any = x;
        });
      }
    } else {
      if (confirm("Are you sure to resume this export ?")) {
        await this.cdrApi.resumeExport(any.id, any.owner).subscribe(x => {
          any = x;
        });
      }
    }
    this.next();
  }
}
