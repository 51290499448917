<!-- sindgle value -->
<div class="singlewrap" *ngIf="data.values.length <= 1">
  <h1> {{ data?.values}} </h1>
</div>

<!-- multi value -->
<div class="multiwrap" *ngIf="data.values.length > 1">
  <div class="item" *ngFor="let item of data.values">
    <h3> {{ item.caption}} </h3>
    <h1> {{ item.value}} </h1>
  </div>
</div>
