<kendo-card style="width: 100%">
  <kendo-card-header>
    <h3>{{data?.action?.name || ""}}</h3>
  </kendo-card-header>
  <kendo-card-actions>
    <button kendoButton *ngFor="let item of headerButtons" [class]="item.class"
      (click)="openWindowOrPage( {}, item)">{{item.caption}}</button>

    <!-- <button kendoButton (click)="detailData = selectedIds; openModel(detailView)">Show Selected</button> -->
    <div class="multiselect" *ngIf="selectedIds.length > 0">

      <button kendoButton *ngFor="let item of multiselectButtons"
        (click)="openForm(item, structuredForm)">{{item['button-caption']}}</button>
      <!-- <span>Selected {{ selectedIds.length }} </span> -->
      <button kendoButton style="margin-left: 0.5rem;" (click)="selectedIds = []">Deselect
        ({{selectedIds.length}})</button>
    </div>

  </kendo-card-actions>

  <kendo-grid #grid [data]="gridData" [pageSize]="getPageSize" [skip]="getSkip" [sort]="gridSettings.state.sort || []"
    [filter]="gridSettings.state.filter || filterUnsused" [sortable]="true" [pageable]="config" [filterable]="true"
    [resizable]="true" [reorderable]="true" [columnMenu]="false" (dataStateChange)="dataStateChange($event)"
    (columnReorder)="saveState($event, grid)" (columnResize)="saveStateResize($event, grid)"
    (columnVisibilityChange)="saveStateColumVisible($event, grid)" (filterChange)="saveState($event, grid)"
    [scrollTop]="true" [loading]="loading" [selectable]="selectableSettings"
    (selectionChange)="changedSelection($event)" [kendoGridSelectBy]="mySelectionKey" [(selectedKeys)]="selectedIds">
    <!-- toolbar items -->
    <ng-template kendoGridToolbarTemplate>
      <kendo-textbox [style.width.%]="50" [(ngModel)]="searchField" (ngModelChange)="changedSearchField($event)"
        [placeholder]="'Search..'"></kendo-textbox>
      <button kendoButton (click)="next()"> Search </button>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-grid-column-chooser [scrollLeft]="true">
      </kendo-grid-column-chooser>
    </ng-template>

    <kendo-grid-checkbox-column *ngIf="multiselectButtons?.length > 0" showSelectAll="true" width="40"
      [resizable]="false">
    </kendo-grid-checkbox-column>

    <kendo-grid-column *ngFor="let col of gridSettings.columnsConfig" [field]="col.field" [title]="col.title || ''"
      [width]="col._width || 250" [filter]="col.filter || 'text'" [filterable]="col.filterable"
      [hidden]="col.hidden || false" [format]="col.format">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem[col.field]}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column" let-sort="sort">
        <kendo-grid-string-filter-cell *ngIf="col.filter == 'text'" [showOperators]="false" [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>

        <kendo-grid-boolean-filter-cell *ngIf="col.filter == 'boolean'" [column]="column" [filter]="filter">
        </kendo-grid-boolean-filter-cell>

        <kendo-grid-numeric-filter-cell *ngIf="col.filter == 'numeric'" [showOperators]="false" [column]="column"
          [filter]="filter">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="rowButtons?.length > 0" title="Actions" [sticky]="true" [width]="100" [filter]="false"
      [resizable]="false" [reorderable]="false">
      <ng-template kendoGridCellTemplate let-dataItem class="center">
        <kendo-dropdownbutton (itemClick)="openWindowOrPage(dataItem, $event)" [data]="rowButtons">
          Actions
          <ng-template kendoDropDownButtonItemTemplate let-dataItem>
            <span>{{ dataItem.caption }}</span>
          </ng-template>
        </kendo-dropdownbutton>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</kendo-card>

<ng-template #detailView>
  <ngx-json-viewer [json]="detailData"></ngx-json-viewer>
</ng-template>
<ng-template #debugView>
  <ngx-json-viewer [json]="gridData"></ngx-json-viewer>
</ng-template>


<ng-template #structuredForm>
  <kendo-card style="width: 100% !important;">
    <kendo-card-header>
      <span> Selected: {{ selectedIds.length }} rows</span>
    </kendo-card-header>
  </kendo-card>
  <app-form-wrapper [multiSelectPayload]="true" [multiSelectIds]="selectedIds" [(data)]="FormData"
    (done)="onMultiSelectSave($event)"></app-form-wrapper>
</ng-template>
