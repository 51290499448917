import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActionService } from 'src/app/services/action-service';
import { AssetApiService } from 'src/app/services/asset-api.service';

@Component({
  selector: 'ui-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent implements OnInit, OnDestroy {
  public routerSub: Subscription;
  public data: any;
  public downloaded$: BehaviorSubject<boolean>;
  public downloadUrl = '';
  public showButton = false;

  constructor(private router: Router, private ar: ActivatedRoute, private http: HttpClient, private action: ActionService) {
    this.data = this.ar.snapshot.data.data || undefined;
    console.log('TRACE', this.data.action.url);
    this.downloadUrl = this.data.action.url || '';

    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.data = this.ar.snapshot.data.data || undefined;
        console.log('TRACE', this.data);
        if (this.data.action.type === '.frontend.DirectDownloadFile') {
          this.downloadUrl = this.data.action.url || '';
        }

      }

    });
    this.downloaded$ = new BehaviorSubject<boolean>(false);

  }
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
    setTimeout(() => {
      this.showButton = true;
    }, 10000);
  }

  ngOnInit(): void {
    this.downloaded$.subscribe(arg => {
      console.log('RUN', arg);
      if (arg === true) {
        if (Array.isArray(this.data.action.onReady)) {
          this.data.action.onReady.forEach((element: any) => {
            this.action.handleCallbackAction(element);
          });
        } else {
          this.action.handleCallbackAction(this.data.action.onReady);
        }
      }
    });

    if (this.downloadUrl !== '') {
      this.download(this.downloadUrl);
    }

  }

  download(url: string) {
    console.log('download');
    this.http.get(url, {
      responseType: 'blob'
    }).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = this.data.action.downloadName;
      a.click();
      URL.revokeObjectURL(objectUrl);
      console.log('next');
      this.downloaded$.next(true);
    }, (error) => {
      this.showButton = true;
    });


  }

}
