import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from '../crm/base.component';
import { AuthGuard } from '../guards/keycloak';
import { DashboardsResolver, ViewDashboardResolver } from '../resolvers/dashboard-resolver';
import { DashboardModule } from './dashboard.module';
import { OverviewComponent } from './overview/overview.component';

const routes: Routes = [
  {
    path: '',
    component: OverviewComponent,
    canActivate: [AuthGuard],
    resolve: {
      dashboards: DashboardsResolver,
    },
},
  {
    path: ':key',
    component: OverviewComponent,
    canActivate: [AuthGuard],
    resolve: {
      dashboards: DashboardsResolver,
      dashboard: ViewDashboardResolver
    },
    runGuardsAndResolvers: 'always'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
