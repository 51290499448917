<!-- <div class="row">
  <kendo-textbox [(ngModel)]="search" placeholder="Search...."> </kendo-textbox>
  <button kendoButton (click)="searchNext($event)">Search</button>
</div> -->

<!-- TODO create user  -->

<kendo-grid *ngIf="gridData" [data]="gridData" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
  [filterable]="false" [height]="450" (pageChange)="pageChange($event)">
  <kendo-grid-column field="name" title="Name">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-textbox [(ngModel)]="searchName" (ngModelChange)="searchNext()"></kendo-textbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="email" title="User">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-textbox [(ngModel)]="searchEmail" (ngModelChange)="searchNext()"></kendo-textbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="internal" title="Internal" [filter]="false">
    <!-- <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.Discontinued" disabled />
    </ng-template> -->
  </kendo-grid-column>
  <kendo-grid-column title="Actions" [filter]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton (click)="clicked(dataItem)"> Select </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
