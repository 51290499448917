import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AssetApiService } from 'src/app/services/asset-api.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {

  private id = '';
  private action = '';

  public showModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public modalData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private api: AssetApiService) { }


  public showDynamicFormModal(id: string, action: string): void {
    // Load Screen details from backend
    this.api.getScreenDetails(id, action).subscribe({
      next: (data) => {
        this.modalData$.next(data);
        this.showModal$.next(true);
      }, error: (err) => {
        console.log(err);
      }
    });
    this.action = action;
    this.id = id;
  }

  public hideModal(): void {
    this.action = '';
    this.id = '';
    this.showModal$.next(false);
  }
}
