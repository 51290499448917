<kendo-combobox
#combo
[data]="data"
[filterable]="true"
textField="name"
valueField="id"
[valuePrimitive]="true"
placeholder="Type... to start a search"
[(ngModel)]="organisationID" (ngModelChange)="change($event)"
[disabled]="disabled"
>
</kendo-combobox>
