<div class="row">
  <kendo-textbox [(ngModel)]="search" placeholder="Search...."> </kendo-textbox>
  <button kendoButton (click)="searchNext($event)">Search</button>
</div>
<kendo-treelist *ngIf="data" [kendoTreeListFlatBinding]="data" [height]="410" idField="uuid"
  (cellClick)="clicked($event)">
  <kendo-treelist-column [expandable]="false" field="name" title="Name" [width]="250">
  </kendo-treelist-column>
  <kendo-treelist-column [expandable]="false" field="data.commercial-name" title="Name" [width]="250">
  </kendo-treelist-column>
</kendo-treelist>
