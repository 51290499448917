import { TableColumnDefinition } from '../models/table';
import { GridSettings } from '../shared/table';

export const CdrGridSettings: GridSettings = {
  state: {
    skip: 0,
    take: 5,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: '',
        dir: 'asc',
      },
    ],
  },
  // gridData: undefined,
  columnsConfig: [
    {
      field: 'cdr_id',
      title: 'ID',
      filterable: false,
      _width: 60,
      hidden: false,
    },
    {
      field: 'owner_uuid',
      title: 'Owner UUID',
      filterable: false,
      _width: 300,
      hidden: true,
    },
    {
      field: 'exported',
      title: 'Exported',
      filter: 'boolean',
      filterable: true,
      _width: 100,
      hidden: true,
    },
    {
      field: 'export_timestamp',
      title: 'Export Timestamp',
      filter: 'date',
      filterable: false,
      _width: 240,
      hidden: true,
    },
    {
      field: 'bill_timestamp',
      title: 'Bill TimeStamp',
      filter: 'date',
      filterable: false,
      _width: 240,
      hidden: true,
    },
    {
      field: 'start_timestamp',
      title: 'Start Date',
      filter: 'date',
      format: '{0:d}',
      _width: 240,
      filterable: false,
      hidden: false,
    },
    {
      field: 'type',
      title: 'Type',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'description',
      title: 'Description',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'a',
      title: 'A',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'b',
      title: 'B',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: false,
    },
    {
      field: 'c',
      title: 'C',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: true,
    },
    {
      field: 'd',
      title: 'D',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: true,
    },
    {
      field: 'wallet_uuid',
      title: 'Wallet',
      filter: 'text',
      _width: 180,
      filterable: true,
      hidden: true,
    },
    {
      field: 'volume',
      title: 'Volume',
      filter: 'numeric',
      _width: 120,
      filterable: true,
      hidden: false,
    },
    {
      field: 'unit',
      title: 'Unit',
      filter: 'numeric',
      _width: 120,
      filterable: true,
      hidden: false,
    },
    {
      field: 'unit_name',
      title: 'Unit Name',
      filter: 'text',
      _width: 200,
      filterable: true,
      hidden: false,
    },
    {
      field: 'billed',
      title: 'Billed',
      filter: 'boolean',
      _width: 120,
      filterable: true,
      hidden: false,
    },
    {
      field: 'unit',
      title: 'Unit',
      filter: 'numeric',
      _width: 120,
      filterable: true,
      hidden: false,
    },
    {
      field: 'cost',
      title: 'Cost',
      filter: 'numeric',
      _width: 120,
      filterable: true,
      hidden: false,
    },
    {
      field: 'creation_timestamp',
      title: 'Creation Date',
      filter: 'date',
      format: '{0:d}',
      _width: 240,
      filterable: false,
      hidden: true,
    },
  ],
};
