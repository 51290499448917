import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  TemplateRef,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  DialogCloseResult,
  DialogRef,
  DialogService,
} from '@progress/kendo-angular-dialog';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment.prod';
import { BehaviorSubject, debounceTime, Subject, Subscription } from 'rxjs';
import { AssetApiService } from 'src/app/services/asset-api.service';
import { RoutestateService } from 'src/app/services/routestate.service';
import { VersionService } from 'src/app/services/version.service';
import { PORTAL_MODE } from 'src/app/app-routing.module';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit {
  constructor(
    private zone: NgZone,
    public keycloakService: KeycloakService,
    private router: Router,
    private modalService: DialogService,
    private api: AssetApiService,
    private routerStateService: RoutestateService,
    private versionService: VersionService
  ) {
    this.currentColler = localStorage.getItem('color') || '';

    this.searchChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.next();
    });
  }
  public searchChanged = new Subject<string>();
  public searchField = '';

  public results: Array<any> = Array<any>();

  public dropDownButtonItems: Array<any> = [];

  private dialogRef: DialogRef;
  private dialogRefSub: Subscription;

  public userName = '';

  public currentColler = '';
  @ViewChild('anchor', { static: false })
  public margin = { horizontal: -100, vertical: 80 };
  public show = false;
  public version: any;
  @ViewChild('modalTemplate') modalTemplate: TemplateRef<any>;
  @ViewChild('version') versionTemplate: TemplateRef<any>;

  getMode(): string {
    return environment.portalMode;
  }

  changedSearchField(e: any): void {
    if (this.searchField === '') {
    }
    this.searchChanged.next('');
  }
  public next() {
    console.log('SEARCH');
    if (this.searchField !== '') {
      this.api.search(this.searchField).subscribe({
        next: (v) => {
          this.results = v.results || [];
        },
      });
      if (!this.dialogRef) {
        this.openModal(this.modalTemplate);
      }
    }
  }

  openModal(template: TemplateRef<any>): void {
    this.dialogRef = this.modalService.open({
      title: 'Search',
      content: template,
      height: '80vh',
      width: '60vw',
    });
    this.dialogRefSub = this.dialogRef.result.subscribe({
      next: (r) => {
        console.log(r);
        if (r instanceof DialogCloseResult) {
          this.dialogRef = undefined;
        }
      },
    });
  }
  navigate(dataItem: any): void {
    if (dataItem['url-parameters'] !== undefined) {
      const m: Map<string, string> = new Map<string, string>();
      if (Array.isArray(dataItem['url-parameters'])) {
        dataItem['url-parameters'].forEach((param) => {
          m.set(param['parameter-name'], param.value);
        });
      }
      this.router
        .navigate(['/crm', dataItem.uuid, 'action', dataItem['action-name']], {
          queryParams: Object.fromEntries(m),
        })
        .then((x) => {
          this.closeModal();
        });
    } else {
      this.router
        .navigate(['/crm', dataItem.uuid, 'action', dataItem['action-name']], {
          queryParams: { source: dataItem.source },
        })
        .then((x) => {
          this.closeModal();
        });
    }
  }

  closeModal(): void {
    if (!!this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
      if (!!this.dialogRefSub) {
        this.dialogRefSub.unsubscribe();
      }
    }
  }

  public onToggle(): void {
    this.show = !this.show;
  }
  ngOnInit(): void {
    this.keycloakService.loadUserProfile().then((data) => {
      this.userName = data.username || '';
    });
    // this.searchField = 'Oscar'
    // this.searchChanged.next('')
    this.dropDownButtonItems.push(
      {
        text: 'My Profile',
        action: 'profile',
      },
      {
        text: 'My admin-portal Log',
        action: 'admin-portal',
      },
      {
        text: 'Log Out',
        action: 'logout',
      },
      {
        text: 'Info',
        action: 'info',
      }
    );
    if (
      this.getMode() == 'ALL' ||
      this.getMode() === 'CRM_ONLY' ||
      this.getMode() === 'CRM_DASHBOARD_ONLY'
    ) {
      this.dropDownButtonItems.push({
        text: 'My Files',
        action: 'myfiles',
      });
    }
  }
  public logOut = (keycloakService: KeycloakService) => {
    keycloakService.logout();
  };

  public onThemeClick(theme: any): void {
    if (theme) {
      localStorage.setItem('color', theme.color);
      this.currentColler = theme.color;
      document
        .getElementById('theme')
        ?.setAttribute('href', `assets/themes/${theme.color}.css`);
    }
  }

  public onSplitButtonItemClick(dataItem: any): void {
    if (dataItem) {
      if (dataItem.action === 'logout') {
        if (confirm('Are you sure?')) {
          this.keycloakService
            .logout()
            .then(() => this.keycloakService.clearToken());
        }
      }
      if (dataItem.action === 'profile') {
        const currentUrl = 'http://localhost:4200';
        const url = `${environment.keycloak.config.url}/realms/${environment.keycloak.config.realm}/account?redirect_uri=${currentUrl}`;
        window.open(url);
      }
      if (dataItem.action === 'info') {
        this.versionService.getVersion().subscribe((data) => {
          this.version = data;
          // alert();
          this.modalService.open({
            title: 'Info',
            content: `Image: ${data} \n \n \n Portal Mode: ${this.getMode()}`,
          });
        });
      }
      if (dataItem.action === 'myfiles') {
        this.router.navigateByUrl('files/myfiles');
      }
      if (dataItem.action === 'admin-portal') {
        this.router.navigateByUrl('/admin-portal');
      }
    }
  }

  hasPrefix(prefix: string): boolean {
    return this.router.url.startsWith(prefix);
  }

  navigateTo(any: any, path: string) {
    if (this.router.url.startsWith(path)) {
      return;
    }
    console.log('NAV TO', any, path);
    // check for last path
    let pathToGo = '';
    if (path.startsWith('/crm')) {
      pathToGo = this.routerStateService.getLastRoute(
        this.routerStateService.availableKeys.CRM_LAST
      );
      console.log('NAV TO path to go', pathToGo);
    }
    if (path.startsWith('/dashboard')) {
      pathToGo = this.routerStateService.getLastRoute(
        this.routerStateService.availableKeys.DASHBOARD_LAST
      );
    }
    if (path.startsWith('/helpdesk')) {
      pathToGo = this.routerStateService.getLastRoute(
        this.routerStateService.availableKeys.HELPDESK_LAST
      );
    }
    console.log('NAV TO path to go', pathToGo);

    if (pathToGo === '' || pathToGo === null) {
      pathToGo = path;
    }
    this.router.navigateByUrl(pathToGo);
  }
}
