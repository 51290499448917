import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@progress/kendo-angular-inputs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { CanDeactivateGuard } from '../guards/candeactivate.guard';
import { AuthGuard } from '../guards/keycloak';
import { BreadCrumbResolver } from '../resolvers/breadcrumb.resolver';
import { SubMenuResolver } from '../resolvers/sub-menu.resolver';
import { ViewAssetResolver } from '../resolvers/view-asset.resolver';
import { BaseComponent } from './base.component';
import { EditComponent } from './pages/edit/edit.component';
import { ViewComponent } from './pages/view/view.component';
import { SubmenuComponent } from './view/submenu/submenu.component';


const routes: Routes = [
  {
    path: ':id',
    resolve: {
      breadCrumbData: BreadCrumbResolver
    },
    children: [
      {
        path: '',
        component: SubmenuComponent,
        canActivate: [AuthGuard],
        resolve: {
          subMenuData: SubMenuResolver,
        },
        outlet: 'left'
      },
    ],
  },
  {
    path: ':id/action/:action',
    resolve: {
      data: ViewAssetResolver,
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: SubmenuComponent,
        canActivate: [AuthGuard],
        outlet: 'left'
      },
      {
        path: '',
        component: ViewComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard], // TODO: FIX Candeactivate FLOW
        outlet: 'right'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrmRoutingModule { }
