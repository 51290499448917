import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AssetApiService } from '../services/asset-api.service';
import { QueryParamService } from '../services/param.service';

@Injectable({ providedIn: 'root' })
export class ViewAssetResolver implements Resolve<any> {
  constructor(private api: AssetApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any>|Promise<any>|any {
    const id = route.paramMap.get('id');
    const action = route.paramMap.get('action');
    console.log('API < RESOLVE', route.queryParams);

    const htp: HttpParams = new HttpParams({fromObject: route.queryParams});
    // TODO:
    const x: Map<string, any> = new Map<string, any>();
    Object.entries(route.queryParams).forEach(val => {
      console.log('RESOLVE WITH ', val);
      x.set(val[0], val[1]);
    });
    // var pagination = this.qp.InitPagination()
    // console.log("resolve with ", x)
    return this.api.getScreenDetails(id, action || '', undefined, undefined, x);
  }
}
