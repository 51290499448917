<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label [for]="ruleTypeAction" text="Type Action"></kendo-label>
    <!-- <kendo-textbox formControlName="ruleTypeAction" [clearButton]="true" #ruleTypeAction required></kendo-textbox> -->
    <kendo-dropdownlist formControlName="ruleTypeAction" #ruleTypeAction required textField="caption" [data]="ruleTypes"
      [defaultItem]="form.controls.ruleTypeAction.value" [valuePrimitive]="true" valueField="caption">
    </kendo-dropdownlist>
    <kendo-formerror>Error: ruleTypeAction is required</kendo-formerror>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="ruleDescription" text="Description"></kendo-label>
    <kendo-textbox formControlName="ruleDescription" [clearButton]="true" #ruleDescription required></kendo-textbox>
    <kendo-formerror>Error: ruleDescription is required</kendo-formerror>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [for]="actionDescription" text="Action Description"></kendo-label>
    <kendo-textbox formControlName="actionDescription" [clearButton]="true" #actionDescription required></kendo-textbox>
    <kendo-formerror>Error: actionDescription is required</kendo-formerror>
  </kendo-formfield>

  <button *ngIf="form.dirty && noUpdate && !isCreate" kendoButton [themeColor]="'success'" (click)="update()">
    Update
  </button>
  <button *ngIf="form.dirty && isCreate" kendoButton [themeColor]="'success'" (click)="create()">
    Create
  </button>
</form>
