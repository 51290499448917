import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, PageChangeEvent, PagerSettings } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';
import { CdrApiService } from 'src/app/services/cdr-api.service';

@Component({
  selector: 'ui-cdr',
  templateUrl: './cdr.component.html',
  styleUrls: ['./cdr.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CdrBrowserComponent implements OnInit, OnDestroy {
  public loading = false;
  public Subj$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public config: PagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [20, 50, 100, 500],
    previousNext: true,
    position: 'bottom'
  }
  private now: Date = new Date()
  private lastMonth: Date = new Date()
  public useRange = false;
  public range = { start: this.lastMonth, end: this.now };

  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  }

  public multiple = true;
  public allowUnsort = true;
  public sort: SortDescriptor[] = [
    {
      field: "type",
      dir: "asc",
    },
  ];
  public filters: Map<string, string> = new Map<string, string>();


  constructor(private cdrApi: CdrApiService, private router: Router, private ar: ActivatedRoute, public dialogService: DialogService) {
    this.lastMonth.setMonth(0)
    this.now.setMonth(1)

    this.Subj$.subscribe(x => {
      var ff: Map<string, string> = new Map<string, string>();
      ff.set("owner_uuid", this.ar.snapshot.data.data.action.id || "")
      this.filter.filters.forEach((f: any) => {
        console.log(f)
        ff.set(f.field, `${f.operator}#${f.value}`)
      })

      ff.set("sortArray", JSON.stringify(this.sort))
      if (this.useRange){
        ff.set("period", JSON.stringify(this.range))
      }

      this.cdrApi.listCdrs((this.skip / this.pageSize) + 1, this.pageSize, ff).subscribe(data => {
        this.gridView = {
          data: data.records,
          total: data.total_records,
        };
        this.loading = false;
      }, error => {
        this.loading = false;

      });

    });

  }

  ngOnDestroy(): void {
    this.Subj$.unsubscribe();
  }

  changedFilter(event: any) {
    console.log(event)
    this.filter = event;
    console.log(this.filter)
    this.next()
  }

  public gridView: GridDataResult = {
    data: [],
    total: 10,

  };
  public pageSize = 100;
  public skip = 0;

  private items: any[] = [];

  ngOnInit(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.Subj$.next(true);
      }
    });

  }

  public next() {
    console.log("UPDATE PAGE SIZE", this.pageSize)
    this.loading = true;
    this.Subj$.next(true);
  }

  public pageChange(event: PageChangeEvent): void {
    this.loading = true;
    this.skip = event.skip;
    this.pageSize = event.take
    console.log('PAGE CHANGED', event);
    this.Subj$.next(true);
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    console.log(sort)
    this.Subj$.next(true);
  }
  public detailData: any

  public openDialogWindow(event: any, template: TemplateRef<any>) {
    console.log(event)
    this.cdrApi.getCdr(event.dataItem.cdr_id, event.dataItem.owner_uuid).subscribe(data => {
      this.detailData = data;
    })


    this.dialogService.open({
      title: "Detail view",
      content: template,
      height: '80vh',
      width: "50vh",
      actions: [{ text: "Cancel" },],

    })

  }

  public columnState(event: any) {
    console.log("GRID",event)
  }
  public resetFilters() {
    this.useRange = false;
    this.filter = {
      logic: "and",
      filters: [],
    }
  }
}
