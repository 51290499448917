<div class="actions">
  <button kendoButton [size]="'medium'" fillMode="solid" themeColor="success" [disabled]="false" (click)="
      dialogSerive.openDialog('Compose Reply', replyTicket, replyActions)
    ">
  <i class="fa fa-reply" aria-hidden="true"></i>
    Reply
  </button>
  <button kendoButton [size]="'medium'" fillMode="solid" themeColor="primary" [disabled]="false"
    (click)="dialogSerive.openDialog('Add Participants', addParticipant, null)">
    <i class="fa fa-user-plus" aria-hidden="true"></i>
    Add Participant
  </button>
  <button *ngIf="ticket.is_escalated_since !== null" kendoButton [size]="'medium'" fillMode="solid" themeColor="success"
    [disabled]="false" (click)="dialogSerive.openDialog('Ticket Deescalate', deEscalate, deEscalateActions, '200px')">
    <i class="fa fa-volume-xmark" aria-hidden="true"></i>
    Deescalate
  </button>

  <button *ngIf="ticket.is_escalated_since === null" kendoButton [size]="'medium'" fillMode="solid" themeColor="warning"
  [disabled]="false" (click)="dialogSerive.openDialog('Ticket Escalation', escalateText, escalateActions, '200px')">
        <i class="fa fa-megaphone" aria-hidden="true"></i>
        Escalate
      </button>
      <button kendoButton (click)="viewAttachments()">
        <i class="fa fa-file-archive-o" aria-hidden="true"></i>
        View Attachments </button>

</div>

<ng-template #replyTicket>
  <kendo-editor [(ngModel)]="replyMessage" [resizable]="false"></kendo-editor>
</ng-template>

<ng-template #replyActions>
  <div class="buttons">
    <form>
      <kendo-formfield>
        <kendo-label for="notifySubscribers">Notify Subscribers</kendo-label>
        <kendo-switch [(ngModel)]="notifySubscribers" [name]="notifySubscribers"></kendo-switch>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label for="internal">Internal (Note)</kendo-label>
        <kendo-switch [(ngModel)]="internal" [name]="internal"></kendo-switch>
      </kendo-formfield>
    </form>
  </div>
  <button kendoButton [size]="'medium'" fillMode="solid" themeColor="info" [disabled]="false"
    (click)="draft = true; submitReply()">
    <i class="fa fa-floppy-disk" aria-hidden="true"></i>

    Save as draft
  </button>
  <button kendoButton [size]="'medium'" fillMode="solid" themeColor="success" [disabled]="false"
    (click)="draft=false;submitReply()">
    <i class="fa fa-paper-plane-top" aria-hidden="true"></i>
    Send Message
  </button>


</ng-template>

<ng-template #addParticipant>
  <ui-user-form-browser (selected)="setSelectedUserToAdd($event.id); dialogSerive.closeDialog()"></ui-user-form-browser>
</ng-template>
<ng-template #addParticipantActions>
  <button kendoButton [size]="'medium'" fillMode="solid" themeColor="success" [disabled]="false">
    Add
  </button>
</ng-template>

<ng-template #deEscalate>
  <p>Are you sure? </p>
</ng-template>
<ng-template #escalateText>
  <p>Are you sure? </p>
</ng-template>

<ng-template #deEscalateActions>
  <button kendoButton [size]="'medium'" fillMode="solid" themeColor="success" [disabled]="false" (click)="deescalate(); dialogSerive.closeDialog()">
    Deescalate
  </button>
</ng-template>

<ng-template #escalateActions>
  <button kendoButton [size]="'medium'" fillMode="solid" themeColor="success" [disabled]="false" (click)="escalate(); dialogSerive.closeDialog()">
    Escalate
  </button>
</ng-template>
