import { NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ModalService } from 'src/app/services/modal.service';
import { TicketApiService } from 'src/app/services/ticket-api.service';
import { getChanges } from 'src/app/shared/changes';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'ui-ticket-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class TicketFormComponent implements OnInit, AfterViewInit {
  @ViewChild('combo') combo: ComboBoxComponent;
  public data: Array<any>;

  public chooseAsset = false;

  public statuses = [];
  public levels = [];
  public types = [];

  public form: FormGroup = new FormGroup({});

  private dialogRef: DialogRef;
  @Output() submitted = new EventEmitter<boolean>();

  @Input() formData: any;
  @Input() createAction = false;

  @Input() meta: any;

  @Input() chooseAssetAllow = true;

  constructor(
    public api: TicketApiService,
    private dialogService: DialogService,
    private router: Router,
    private ar: ActivatedRoute
  ) {}

  getMode(mode: string): boolean {
    return environment.portalMode === mode;
  }

  ngAfterViewInit(): void {
    if (!this.getMode('HELPDESK_ONLY')) {
      return;
    }
    const contains = (value) => (s) =>
    s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1;

    this.combo.filterChange
    .asObservable()
      .subscribe((x) => {
      this.combo.loading = true;
      const search: Map<string, string> = new Map();
      search.set('name', 'contains#' + x);
      console.log(x);
      this.api.listOrganisations(1, 20, search).subscribe({next: (n) => {
        this.data = n.records;
      }});
      this.combo.loading = false;
    });
  }

  ngOnInit(): void {
    console.log(this.formData);
    if (!this.formData) {
      console.log('create empty form');
      this.createAction = true;
      this.formData = {
        id: '',
        title: '',
        reference: '',
        message: '',
        belongs_to_asset_id: '',
        organisation_id: '',
        asset_name: '',
        ticket_type: 'inquiry',
        status: 'created',
        level: 'normal',
        solver: '',
        solver_email: '',
        solver_name: '',
      };
    }

    this.form = new FormGroup({
      id: new FormControl(this.formData.id),
      title: new FormControl(this.formData.title, [ Validators.minLength(3) ]),
      reference: new FormControl(this.formData.reference),
      message: new FormControl(this.formData.message, [ Validators.minLength(10) ]),
      // belongs_to_asset_id: new FormControl(this.formData.belongs_to_asset_id, [ Validators.minLength(3) ]),
      // asset_name: new FormControl(this.formData.asset_name),
      ticket_type: new FormControl(this.formData.ticket_type),
      status: new FormControl(this.formData.status),
      level: new FormControl(this.formData.level),
      solver: new FormControl(this.formData.solver),
      solver_email: new FormControl(this.formData.solver_email),
      solver_name: new FormControl(this.formData.solver_name),
    });
    if (this.getMode('HELPDESK_ONLY')) {
      this.form.addControl('organisation_id', new FormControl(this.formData.organisation_id, [Validators.required]));
    } else {
      this.form.addControl('belongs_to_asset_id',  new FormControl(this.formData.belongs_to_asset_id, [ Validators.minLength(3) ]));
      this.form.addControl('asset_name',  new FormControl(this.formData.asset_name));
      this.form.controls.asset_name.disable({ onlySelf: true });
    }

    this.form.controls.solver_email.disable({ onlySelf: true });
    this.form.controls.solver_name.disable({ onlySelf: true });
    this.composeLevelList();
    this.composeStatusList();
    this.composeTypesList();

    if (!this.meta) {
      this.api.getDefaultTemplate().subscribe({
        next: (template) => {
          this.meta = template;
          console.log(template);
        }
      });
    }
  }

  public get isCreate(): boolean {
    return this.createAction;
  }

  openDialog(
    title: string,
    contentRef: TemplateRef<any>,
    actionsRef: TemplateRef<any>
  ) {
    this.dialogRef = this.dialogService.open({
      title,
      actions: actionsRef,
      content: contentRef,
      width: '80vw'
    });
  }

  setSolver(e: any) {
    this.dialogRef.close();
    this.form.patchValue(
      { solver: e.id, solver_email: e.email, solver_name: e.name },
      { emitEvent: true }
    );
    // this.form.controls.solver.enable();
    this.form.controls.solver.markAsDirty();
    this.form.controls.solver.markAsTouched();

    console.log(e);
  }
  setAsset(e: any) {
    this.dialogRef.close();
    this.form.patchValue(
      { belongs_to_asset_id: e.uuid, asset_name: e.name },
      { emitEvent: true }
    );
    this.form.controls.belongs_to_asset_id.enable();
    this.form.controls.belongs_to_asset_id.markAsDirty();
    this.form.controls.belongs_to_asset_id.markAsTouched();

    console.log(e);
  }
  create() {
    console.log(this.form.getRawValue());

    if (this.getMode('ALL')) {
      if (this.form.controls.belongs_to_asset_id.value !== ''){
        this.form.controls.belongs_to_asset_id.enable();
        this.form.controls.belongs_to_asset_id.markAsDirty();
        this.form.controls.belongs_to_asset_id.markAsTouched();
      }
    }
    const changes = getChanges(this.form);

    switch (this.createAction) {
      case true:
        if (this.form.invalid) {
          return;
        }
        this.form.controls.ticket_type.markAsDirty();
        this.form.controls.status.markAsDirty();
        this.form.controls.level.markAsDirty();
        this.form.controls.ticket_type.markAsTouched();
        this.form.controls.status.markAsTouched();
        this.form.controls.level.markAsTouched();
        console.log(changes);
        this.api.createTicket(this.form.getRawValue(), this.meta).subscribe({
          next: (n) => {
            console.log('CALLED');
            this.submitted.emit(true);
            this.router.navigate([], {
              relativeTo: this.ar,
              queryParams: { updated: n.last_action },
              queryParamsHandling: 'merge'
            });
          },
        });
        // this.dialogService.closeWindow();
        break;

      default:
        console.log(changes, 'not implemented');
        this.api.updateTicketPartiel(this.form.controls.id.value , changes).subscribe({
          next: (n) => {
            this.submitted.emit(true);
            this.router.navigate([], {
              relativeTo: this.ar,
              queryParams: { updated: n.id },
              queryParamsHandling: 'merge',
            });
          },
        });
        // this.dialogService.closeWindow();
        break;
    }
  }

  composeStatusList(): void {
    this.statuses = [];
    const s = this.api.listStatuses().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.statuses.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }
  composeTypesList(): void {
    this.types = [];
    const s = this.api.listTypes().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.types.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }

  composeLevelList(): void {
    this.levels = [];
    const s = this.api.listLevels().subscribe({
      next: (v) => {
        v.forEach((stat) => {
          this.levels.push(stat.name);
        });
      },
      complete: () => {
        s.unsubscribe();
      },
    });
  }
}
