import { APP_ID, Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@progress/kendo-data-query';
import { GridSettings } from '../shared/table';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: object | null) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return value;
      }
      seen.add(value);
    }
    return value;
  };
};

@Injectable()
export class StatePersistingService {
  constructor(private ar: ActivatedRoute) {}

  public get<T>(token: string): T {
    var action =
      this.ar.snapshot?.firstChild?.children[0]?.params?.action || '';
    console.log('[ get state for action ]', action);
    const settings = localStorage.getItem(`${action}-${token}`);
    return settings ? JSON.parse(settings) : settings;
  }

  public set<T>(token: string, gridConfig: any): void {
    var action = this.ar.snapshot.firstChild?.children[0].params.action || '';
    console.log('[ store state for action ] ', action);
    localStorage.setItem(
      `${action}-${token}`,
      JSON.stringify(gridConfig, getCircularReplacer())
    );
  }

  public getView<T>(token: string): T {
    console.log('[ get state for view ]', token);
    const settings = localStorage.getItem(`${token}`);
    return settings ? JSON.parse(settings) : settings;
  }

  public setView<T>(token: string, sts: any): void {
    console.log('[ store state for view ] ', token);
    localStorage.setItem(
      `${token}`,
      JSON.stringify(sts, getCircularReplacer())
    );
  }
}
