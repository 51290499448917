<kendo-chart>
  <kendo-chart-title [text]="data?.caption"></kendo-chart-title>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item [categories]="data?.categories"></kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-series>
    <kendo-chart-series-item *ngFor="let item of data?.values" type="line" field="field" [name]="item.name"
      categoryField="categorie" [data]="item?.values">
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
