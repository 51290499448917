import { TableColumnDefinition } from '../models/table';
import { GridSettings } from '../shared/table';

export const TableGridSettings: GridSettings = {
  state: {
    skip: 0,
    take: 5,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  },
  columnsConfig: []
};
