<kendo-treelist #treelist [kendoTreeListFlatBinding]="logicPlan" #directive="kendoTreeListFlatBinding"
  #editingDirective="kendoTreeListReactiveEditing" [height]="410" kendoTreeListExpandable idField="id"
  parentIdField="parentId" (expand)="onToggle()" (close)="onToggle()" [(expandedKeys)]="expandedKeys"
  [sortable]="{ mode: 'multiple' }" [sort]="sort" (save)="saveNew($event,directive)"
  [kendoTreeListReactiveEditing]="createFormGroup" [resizable]="true" [initiallyExpanded]="true">
  <ng-template kendoTreeListToolbarTemplate *ngIf="!readOnly && edit">
    <button kendoTreeListAddCommand type="button">Add new</button>

    <!-- <button kendoButton (click)="editRule({}, editRuleTemplate)"> Create </button> -->
  </ng-template>
  <kendo-treelist-command-column *ngIf="!readOnly && edit" [width]="200" [minResizableWidth]="200" [sticky]="true">
    <ng-template kendoTreeListCellTemplate let-isNew="isNew" let-cellContext="cellContext">
      <!-- "Add Child" command directive, will not be visible in edit mode -->
      <button [kendoTreeListAddCommand]="cellContext" icon="filter-add-expression" title="Add Child"></button>

      <button kendoButton *ngIf="!isNew" (click)="remove(cellContext.viewItem.data); rebind(directive)"
        [icon]="'delete'" class="sm"></button>

      <button [kendoTreeListSaveCommand]="cellContext" [disabled]="formGroup?.invalid" [themeColor]="'success'"
        icon="save" *ngIf="allowSave" title="{{ isNew ? 'Add' : 'Update' }}"></button>
      <!-- "Cancel" command directive, will be visible only in edit mode -->
      <button [kendoTreeListCancelCommand]="cellContext" icon="cancel"
        title="{{ isNew ? 'Discard changes' : 'Cancel' }}"></button>

      <button kendoButton [primary]="true" *ngIf="!isNew"
        (click)="log(cellContext); isCreate = false; editRule(cellContext, editRuleTemplate)"
        title="{{ isNew ? 'Create' : 'Update' }}">
        Configure </button>
      <button kendoButton [primary]="true" *ngIf="isNew"
        (click)="log(cellContext); isCreate = true; editRule(cellContext, editRuleTemplate, true)">
        Configure </button>


    </ng-template>
  </kendo-treelist-command-column>



  <kendo-treelist-column [expandable]="true" field="ruleTypeAction" title="Rule Type Action" [width]="250"
    [editable]="true" [sortable]="false">
    <ng-template kendoTreeListCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span [class]="dataItem['ruleTypeIcon']" style="margin-right: 0.5rem;"></span>

      <strong>{{ dataItem.ruleTypeAction }}</strong>
    </ng-template>
    <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-cellContext>
      {{ cellContext.controls?.ruleTypeAction.value }}
    </ng-template>
  </kendo-treelist-column>

  <kendo-treelist-column field="ruleDescription" title="Rule Description" [width]="180" [sortable]="false">
    <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-cellContext>
      {{ cellContext.controls?.ruleDescription.value }}
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column field="actionDescription" title="Action Description" [width]="180" [sortable]="false">
    <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-cellContext>
      {{ cellContext.controls?.actionDescription.value }}
    </ng-template>
  </kendo-treelist-column>

  <kendo-treelist-column field="priority" title="Priority" *ngIf="!readOnly && edit" [width]="120"
    [minResizableWidth]="120" [sticky]="true" [sortable]="false">
    <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-cellContext>
    </ng-template>
    <ng-template kendoTreeListCellTemplate let-dataItem="dataItem" let-cellContext let-rowIndex="rowIndex">
      <!-- {{ dataItem.priority }} -->
      <button kendoButton (click)="moveUp(dataItem); rebind(directive)" [disabled]="isFirst(dataItem)"
        [icon]="'sort-asc-sm'" class="sm" [themeColor]="'success'" style="margin-right: 0.5rem;"></button>
      <button kendoButton (click)="moveDown(dataItem); rebind(directive)" [disabled]="isLast(dataItem)"
        [icon]="'sort-desc-sm'" class="sm" style="margin-right: 0.5rem;"></button>

    </ng-template>
  </kendo-treelist-column>

</kendo-treelist>

<ng-template #editRuleTemplate>
  <app-logic-plan-rule-form [rule]="ruleToEdit" [isCreate]="isCreate"
    (submitted)="submittedUpdate($event); rebind(directive)" (created)="created(editingDirective,$event)">
  </app-logic-plan-rule-form>
</ng-template>
