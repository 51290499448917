import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NgControl,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import {
  AddEvent,
  CellClickEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { consoleSandbox } from '@sentry/utils';
import { Subscription } from 'rxjs';
import { Identity, AllowedEntity } from 'src/app/models/identities';
import { ActionService } from 'src/app/services/action-service';

const formGroup = (dataItem) =>

  new FormGroup({
    identityUUID: new FormControl(dataItem.identityUUID),
    identityName: new FormControl(dataItem.identityName, Validators.required),
    identityValue: new FormControl(dataItem.identityValue, Validators.required),
  });

@Component({
  selector: 'ui-identities',
  templateUrl: './identities.component.html',
  styleUrls: ['./identities.component.css'],
})
export class UiIdentitiesComponent
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, OnDestroy
{
  @Input() disabled: boolean;
  @Input() isCreate: boolean;

  // Should be the value of the controll.
  @ViewChild('identity') identity: any;

  public identitieValue: Array<Identity>;

  @ViewChild('identity') grid: GridComponent;

  @Input() allowedidentities: Array<AllowedEntity> = [];

  public allowedToAdd: Array<AllowedEntity> = [];

  public changes: any = {};
  public formGroup: FormGroup = new FormGroup({});
  public errorMessageOccurence = '';

  onChange: any = () => {};
  onTouched: any = () => {};
  private editedRowIndex: number;
  private isNew = false;

  private sub$: Subscription;

  public minOccurenceMessage = "";


  constructor(
    public actionService: ActionService,
    public modalService: DialogService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

  }
  ngOnDestroy(): void {
    if (!!this.sub$) {
      this.sub$.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.ngControl.control.setValidators([this.validate.bind(this)]);
    this.ngControl.control.updateValueAndValidity({emitEvent: true});
    // this.ngControl.control.markAsTouched();
    this.createMinOccurenceMessage();

  }
  createMinOccurenceMessage() {
    var l: Map<string, number> = new Map<string,number>();
    var count = 1;
    var s = ""
    this.allowedidentities.forEach(idt => {
      l[idt.identityName] = idt.minoccurences;
      if (idt.minoccurences > 0){
        if (count > 1 && count < this.allowedidentities.length){
          s = s + `${idt.identityName} ${idt.minoccurences} occurences, `;
        }
        if (count === 1 && this.allowedidentities.length !== 1) {
          s = s + `${idt.identityName} ${idt.minoccurences} occurences, `;
        }
        if (count !== 1 && this.allowedidentities.length === count) {
          s = s + `${idt.identityName} ${idt.minoccurences} occurences.`;
        }
        count++;
      }
    })

    this.minOccurenceMessage = `Expected: ${s}`;

  }

  ngAfterViewInit(): void {
    // console.log('TEST', this.identity);
    var x = this.identity;
    console.log(x);
    this.createAllowToAddList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.createAllowToAddList();
  }

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }


  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.disabled || this.editedRowIndex !== undefined) {
      return;
    }
    console.log('EDIT MODE FOR', dataItem);
    if (this.formGroup && !this.formGroup?.valid) {
      console.log('returned');
      return;
    }
    // this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;
    console.log(this.formGroup);
    this.setValidators();
    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  returnValidator(key: string): Array<ValidatorFn> {
    let x: Array<ValidatorFn> = new Array<ValidatorFn>();
    this.allowedidentities.forEach(v => {
      if (v.identityName === key) {
        if (Array.isArray(v.validation)) {
          v.validation?.forEach((val: any) => {
            console.log(v.validation);
            switch (val.type) {
              case 'min':
                x.push(Validators.min(val.min));
                break;
              case 'max':
                x.push(Validators.max(val.max));
                break;
              case 'required':
                x.push(Validators.required);
                break;
              case 'requiredTrue':
                x.push(Validators.requiredTrue);
                break;
              case 'email':
                x.push(Validators.email);
                break;
              case 'minLength':
                x.push(Validators.minLength(val.minLength));
                break;
              case 'maxLength':
                x.push(Validators.maxLength(val.maxLength));
                break;
              case 'pattern':
                x.push(Validators.min(val.pattern));
                break;
              case 'regex':
                x.push(Validators.pattern(val.regex));
                break;
              case 'nullValidator':
                x.push(Validators.nullValidator);
                break;
              default:
                break;
            }
          });
        }

      }
    });


    return x;
  }

  setValidators() {
    console.log("setValidators");
    var selectedControl = this.formGroup.controls.identityName.value;
    const validators = this.returnValidator(selectedControl);
    console.log("ADDED VALIDATORS", validators);
    this.formGroup.controls.identityValue.addValidators(validators);
    console.log(selectedControl);
  }

  public saveCurrent(): void {

    console.log("write value", this.identitieValue);
    if (this.formGroup && !this.formGroup.valid) {
      console.log("saveCurrent returned");
      return;
    }
    this.saveRow();
  }

  private closeEditor(
    grid: GridComponent,
    rowIndex: number = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
    this.onChange();
    this.onTouched();
  }

  private saveRow(): void {
    if (this.isInEditingMode) {
      this.identitieValue[this.editedRowIndex] = this.formGroup.value;
    }
    if (this.isNew) {
      this.identitieValue.push(this.formGroup.value);
    }
    this.closeEditor(this.grid);
    console.log("write value and set", this.identitieValue);
    this.ngControl.control.setValue(this.identitieValue);
  }

  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      identityName: '',
      identityValue: '',
    });

    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }
  public deleteHandler(): void {

    this.identitieValue = this.identitieValue.filter((el, idx) => idx !== this.editedRowIndex);
    this.closeEditor(this.grid, this.editedRowIndex);

  }

  addRow() {
    this.createAllowToAddList();
    this.identitieValue.push({
      identityUUID: '',
      identityName: '',
      identityValue: '',
    });
  }

  isInValue(idt: AllowedEntity): boolean {
    return (
      this.identitieValue.filter(
        (value): boolean => value.identityName === idt.identityName
      ).length > 0
    );
  }

  createNeededFields(): void {
    this.allowedidentities.forEach((idt) => {
      if (idt.minoccurences > 0) {
        for (let index = 1; index < idt.minoccurences; index++) {
          this.identitieValue.push({
            identityUUID: '',
            identityName: idt.identityName,
            identityValue: '',
          });
        }
      }
    });
  }

  createAllowToAddList(): void {
    console.log('CREATE ADD ALLOWED LIST');
    // this.allowedToAdd = [];
    // this.allowedidentities.forEach(val => {
    //   console.log("checking", val)
    //   if (!this.isInValue(val)) {
    //     this.allowedToAdd.push(val);
    //   }
    // })
    this.allowedToAdd = this.allowedidentities;
  }

  // CrontrolValueAccessor

  writeValue(value: any): void {
    console.log('write value func', value);
    if (value !== undefined) {
      this.identitieValue = value;
    } else {
      this.identitieValue = new Array<any>();
    }

    console.log('write value', this.ngControl.value);

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    console.log('change', fn);
    this.createAllowToAddList();
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    console.log(this.disabled);
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }

  countOccurences(key: string): number {
    var x = 0;
    this.identitieValue.forEach(val => {
      if (val.identityName === key) {
        x++;
      }
    });
    console.log(key, x)
    return x;
  }


  public get validOccurence() : boolean {
        return this.valid();
  }

  // return a true if valid
  valid (): boolean {
    this.errorMessageOccurence = '';
    let valid = true
    this.allowedidentities.forEach(idt => {
      if (this.countOccurences(idt.identityName) > idt.maxoccurences) {
        this.errorMessageOccurence = `${idt.identityName} is exceeding the max occurrences
        .`;
        valid = false;
      }
    });

    return valid;
  }

  validate({ value }: FormControl) {
    const isNotValid = !this.validOccurence;
    console.log("valid occ", isNotValid)
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }
  public onStateChange(state: State): void {
    console.log("write value", state)
    // this.valid();
  }
}
