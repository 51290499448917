<div class="fit">
  <kendo-card>
    <kendo-card-header>
      <h3>
        {{ data.action.name || ""}}
      </h3>
    </kendo-card-header>
    <kendo-card-actions>

    </kendo-card-actions>
    <kendo-card-body>
      <kendo-expansionpanel [title]="title" [expanded]="true">
        <div class="content" *ngIf="data">
          <ngx-json-viewer [json]="data.action.asset.data"></ngx-json-viewer>

        </div>
      </kendo-expansionpanel>
    </kendo-card-body>
  </kendo-card>
</div>
