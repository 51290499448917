import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CrudService } from 'src/app/services/crud-service';
import { DynamicFormService } from './forms.service';

@Component({
  selector: 'modal-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit, OnDestroy {
  @ViewChild('windowContent') public windowContent?: TemplateRef<any>;
  @ViewChild('actionsContent') public actionsContent?: TemplateRef<any>;

  public form: any = {};
  public save$: BehaviorSubject<boolean>;
  public windowData: any;
  private dialog$?: DialogRef;
  public isFormValid = false;
  public formValid$: BehaviorSubject<boolean>;
  public data$: Observable<any>;
  public editMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(private dialogService: DialogService, private formService: DynamicFormService, private crudService: CrudService) {
    this.data$ = this.formService.modalData$;
    this.save$ = new BehaviorSubject<boolean>(false);
    this.formValid$ = new BehaviorSubject<boolean>(false);
  }

  ngOnDestroy(): void {
    if (!!this.formService.showModal$){
      this.formService.showModal$.unsubscribe();
    }
    if (!!this.formService.modalData$){
      this.formService.modalData$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.data$.subscribe(data => {
      this.windowData = data;
    });

    this.formService.showModal$.subscribe(data => {
      // console.log("WINDOW DATA",this.windowData)
      if (data === true) {
        this.dialog$ = this.dialogService.open({ title: this.windowData?.action?.name || "", content: this.windowContent,  width: '50%',
        height: '80%', actions: this.actionsContent});
      }
    }, (error) => {
      console.error(error)
    });

  }

  isValid(any: boolean) {
    this.isFormValid = any
  }

  FormValid = () => {
    return this.isFormValid;
  };


  dataFormat(any?: any) {
    // console.log("[ trace ] DATAFORM FORM TRIGGER", any)
    if (any) {
      this.form = Object.assign(this.form, any);
    }
    Object.keys(this.form).forEach(key => this.form[key] === undefined ? delete this.form[key] : {});
  }

  handleButton(any: any, type: string){
    // console.log('HANDLED BUTTON', any);
    if (type === 'cancel') {
      this.dialog$?.close();
      this.windowData = null;
      return;
    }
    this.save$.next(true);
    this.dialog$?.close();
    this.crudService.handleCrud(this.windowData.action.crud, this.form, this.windowData.action['on-success'], this.windowData.action['on-error']);
    this.windowData = null;
  }

  saveTest(any: any){
    this.save$.next(true);
  }

}
