import { AfterViewInit, Component, NgZone, OnInit, Optional, Renderer2, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NgControl } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { ModalService } from 'src/app/services/modal.service';
import { TicketApiService } from 'src/app/services/ticket-api.service';

@Component({
  selector: 'app-organisation-selector',
  templateUrl: './organisation-selector.component.html',
  styleUrls: ['./organisation-selector.component.scss']
})
export class OrganisationSelectorComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild('combo') combo: ComboBoxComponent;
  public data: Array<any>;

  public organisationID = '';
  public disabled = false;

  onChange: any = () => { };
  onTouched: any = () => { };


  constructor(private renderer: Renderer2,
    public dialog: ModalService,
    public api: TicketApiService,
    @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.api.listOrganisations(1, 1000).subscribe({
      next: (n) => {
        this.data = n.records;
      }
    });
  }
  change(e: any): void {
    this.ngControl.control.markAsTouched();
    this.ngControl.control.setValue(e);
    this.ngControl.control.markAsDirty();
  }
  writeValue(value: string): void {
    console.log('VALUE ', value);
    if (value !== undefined) {
      this.organisationID = value;
    } else {
      this.organisationID = '';
    }
    console.log(value);
  }
  ngAfterViewInit(): void {

    const contains = (value) => (s) =>
      s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1;

    this.combo.filterChange
      .asObservable()
      .subscribe((x) => {
        this.combo.loading = true;
        const search: Map<string, string> = new Map();
        search.set('name', 'contains#' + x);
        console.log(x);
        this.api.listOrganisations(1, 20, search).subscribe({
          next: (n) => {
            this.data = n.records;
          }
        });
        this.combo.loading = false;
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
