<div class="fit">
  <kendo-card *ngIf="title">
    <kendo-card-header>
      <h3 *ngIf="title">{{title.caption}}</h3>

    </kendo-card-header>
    <kendo-card-actions style="padding-left: 1rem;">
      <p> Asset repository: {{ title['asset-repository-name'] }}</p>
    </kendo-card-actions>
  </kendo-card>
</div>



<kendo-card class="card-scroll">
  <kendo-card-body>
    <kendo-treeview [nodes]="datax" textField="caption" kendoTreeViewHierarchyBinding childrenField="children"
      kendoTreeViewExpandable [kendoTreeViewCheckable]="false" (nodeClick)="onNodeClick($event)"
      [isExpanded]="isExpanded" (collapse)="handleCollapse($event)" (expand)="handleExpand($event)" [style]=""
      [filterable]="true">
      <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
        <span [class]="dataItem.iconcss"></span>
        <span [class]="dataItem.captioncss" [ngClass]="{'k-text-primary': dataItem.uuid === id}"> {{ dataItem.caption
          }}</span>
      </ng-template>
    </kendo-treeview>
    <kendo-contextmenu #treemenu [items]="items" (select)="onSelect($event)">
      <ng-template kendoMenuItemTemplate let-item="item">
        <!-- <span [ngClass]="iconClass(item)"></span> -->
        {{ item.caption }}
      </ng-template>
    </kendo-contextmenu>


  </kendo-card-body>
</kendo-card>
