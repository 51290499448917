<div class="dynamic-form" *ngIf="data">
  <form class="k-form" [(formGroup)]="myForm">
    <div *ngFor="let item of data">
      <!-- Text -->
      <kendo-formfield *ngIf="item.type === 'text'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-textbox [required]="item.required" [formControlName]="item.name">
        </kendo-textbox>
      </kendo-formfield>

      <!-- Text Area -->
      <kendo-formfield *ngIf="item.type === 'textarea'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-textarea [required]="item.required" [formControlName]="item.name" resizable="auto">
        </kendo-textarea>
      </kendo-formfield>

      <!-- Number -->
      <kendo-formfield *ngIf="item.type === 'number'" showErrors="initial">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-numerictextbox [required]="item.required" [formControlName]="item.name" format="n" [autoCorrect]="false">
        </kendo-numerictextbox>
      </kendo-formfield>

      <!-- Checkbox -->
      <kendo-formfield *ngIf="item.type === 'checkbox'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <input type="checkbox" [required]="item.required" [formControlName]="item.name" />
      </kendo-formfield>

      <!-- Switch -->
      <kendo-formfield *ngIf="item.type === 'switch'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-switch [required]="item.required" [formControlName]="item.name"></kendo-switch>
      </kendo-formfield>

      <!-- Slider -->
      <kendo-formfield *ngIf="item.type === 'slider'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-slider [required]="item.required" [formControlName]="item.name" [vertical]="false"></kendo-slider>
      </kendo-formfield>

      <!-- Picture from Base64 string -->
      <div *ngIf="item.type === 'base64image'">
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <img [src]="item.value" alt="image not loaded..." />
      </div>

      <!-- Picture from URL  -->
      <div *ngIf="item.type === 'urlimage'">
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <img [src]="item.value" alt="image not loaded..." />
      </div>

      <!-- Date -->
      <kendo-formfield *ngIf="item.type === 'date'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-datepicker [required]="item.required" [formControlName]="item.name"></kendo-datepicker>
        <!-- {{ myForm.controls[item.name].value}} -->
      </kendo-formfield>

      <!-- DateTime -->
      <kendo-formfield *ngIf="item.type === 'datetime'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-datetimepicker [required]="item.required" [formControlName]="item.name"></kendo-datetimepicker>
        <!-- {{ myForm.controls[item.name].value}} -->

      </kendo-formfield>

      <!-- Time -->
      <kendo-formfield *ngIf="item.type === 'time'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-timepicker [required]="item.required" [formControlName]="item.name"></kendo-timepicker>
      </kendo-formfield>

      <!-- Chip -->
      <kendo-formfield *ngIf="item.type === 'chip'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-multiselect [required]="item.required" [formControlName]="item.name" [allowCustom]="true">
        </kendo-multiselect>
      </kendo-formfield>

      <kendo-formfield *ngIf="item.type === 'inline-table'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <!-- <ui-table [row]="item.value" [columns]="item.columns" [readOnly]="readOnly" [style]="item.style || {}" [editable]="editMode"
          [rowActions]="item.rowActions">
        </ui-table> -->
        <ui-table formControlName="{{item.name}}" [columns]="item.columns" [style]="item.style || {}" [editable]="editMode"
          [rowActions]="item.rowActions">
        </ui-table>
      </kendo-formfield>

      <kendo-formfield *ngIf="item.type === 'listbox'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <kendo-dropdownlist [(ngModel)]="item.value" [data]="item.options" [textField]="item.binding.textField"
          [valueField]="item.binding.valueField" [formControlName]="item.name"
          [valuePrimitive]="item.binding.valuePrimitive" [required]="item.required">
        </kendo-dropdownlist>
      </kendo-formfield>

      <div *ngIf="item.type === 'json'">
        <ui-json-view [data]="item.value"></ui-json-view>
      </div>

      <kendo-formfield *ngIf="item.type === 'logicplan-table'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}"></kendo-label>
        <app-logic-plan [readOnly]="item?.readOnly || false" [editMode]="editMode" [saveAction]="saveAction"
          (outputData)="logicPlan($event)" [formControlName]="item.name">
        </app-logic-plan>
      </kendo-formfield>

      <kendo-formfield *ngIf="item.type === 'identity'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="'identities'" text="{{ item.caption | titlecase }}" [disabled]="true"></kendo-label>
        <ui-identities [required]="item.required" [formControlName]="item.name" [isCreate]="true"
          [allowedidentities]="item.allowedidentities"></ui-identities>
        <!-- <ngx-json-viewer [json]="myForm.controls.identities.value"></ngx-json-viewer> -->
      </kendo-formfield>

      <kendo-formfield *ngIf="item.type === 'assetselector'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="'identities'" text="{{ item.caption | titlecase }}" [disabled]="true"></kendo-label>
        <ui-assetselector [required]="item.required" [formControlName]="item.name" [isCreate]="true"
          [options]="item.options"></ui-assetselector>
      </kendo-formfield>


      <kendo-formfield *ngIf="item.type === 'orderedmultiselect'">
        <kendo-formerror *ngIf="myForm.controls[item.name]; errors">
          {{
          errorWrap(myForm.controls[item.name].errors, item.validation)
          }}</kendo-formerror>
        <kendo-label [for]="item.name" text="{{ item.caption | titlecase }}" [disabled]="true"></kendo-label>
        <ui-ordered-multiselect [required]="item.required" [formControlName]="item.name" [isCreate]="true"
          [options]="item.options"></ui-ordered-multiselect>
      </kendo-formfield>

      <kendo-expansionpanel [title]="item.caption" [expanded]="true" *ngIf="item.type === 'expansionpanel'"
        style="margin-top: 1rem" [ngClass]="{
          'border-info': item?.styles?.borderClass === 'info',
          'border-warning': item?.styles?.borderClass === 'warning',
          'border-danger': item?.styles?.borderClass === 'danger',

          'bg-info': item?.styles?.backgroundClass === 'info',
          'bg-warning': item?.styles?.backgroundClass === 'warning',
          'bg-danger': item?.styles?.backgroundClass === 'danger'

        }">
        <div class="content">
          <app-form [readOnly]="readOnly" [editMode]="editMode" (subform)="subFormValidHandler($event)"
            [(data)]="item.components" [title]="item.caption" (disableDeactivate)="onSubEmit($event)"
            (outputData)="appendIndend($event)" [saveAction]="saveAction"></app-form>
        </div>
      </kendo-expansionpanel>

      <div [title]="item.caption" [expanded]="true" *ngIf="item.type === 'horizontal'" style="margin-top: 0rem">
        <div class="collumns">
          <app-form class="colitem" [readOnly]="readOnly" [editMode]="editMode" (subform)="subFormValidHandler($event)"
            [(data)]="item.components" [title]="item.caption" (disableDeactivate)="onSubEmit($event)"
            (outputData)="appendIndend($event)" [saveAction]="saveAction"></app-form>
        </div>
      </div>

      <div class="label" *ngIf="item.type === 'label'" [ngClass]="{
        'border-info': item?.styles?.borderClass === 'info',
        'border-warning': item?.styles?.borderClass === 'warning',
        'border-danger': item?.styles?.borderClass === 'danger',

        'bg-info': item?.styles?.backgroundClass === 'info',
        'bg-warning': item?.styles?.backgroundClass === 'warning',
        'bg-danger': item?.styles?.backgroundClass === 'danger',

        'font-h1': item?.styles?.fontSize === 'h1',
        'font-h2': item?.styles?.fontSize === 'h2',
        'font-p': item?.styles?.fontSize === 'p'
      }">

        {{ item?.value }}
      </div>
    </div>
  </form>
</div>
